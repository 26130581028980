import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, FilterIcon, MenuDots, SearchIcon } from "../../components/SvgIcons"
import MaterialTable from "material-table"
import { DeleteProduct, GetProducts } from "../../services/products.service";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import { GetCategories } from "../../services/categories.service";
import { GetSubCategories } from "../../services/sub_categories.service";
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { GetAuthUser, GetUserRole } from "../../services/auth/auth.service";

function VideoList() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [filterCategory, setFilterCategory] = useState('');
    const [filterSubCategory, setFilterSubCategory] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [keyword, setKeyword] = useState('');

    // useEffect(async () => {
    //     await getProductFunction();
    // }, [keyword, sub-category, subCategory, page, perPage])

    // useEffect(async () => {
    //     await getCategories();
    // }, [])

    // const getProductFunction = async () => {
    //     setLoading(true)
    //     let user = GetAuthUser();
    //     let role = GetUserRole();
    //     await GetProducts(keyword, sub-category, subCategory, page, perPage, user, role).then((result) => {
    //         if (result.status) {
    //             if (result.data) {
    //                 const rowData = [];
    //                 setPage(1)
    //                 setTotalRows(result.data.length);
    //                 result.data.map((dt) => {
    //                     rowData.push({
    //                         id: dt.id,
    //                         imgUrl: dt.attachments[0]?.smallImage || constants.IMAGENOTFOUND,
    //                         sid: dt?.store?.name,
    //                         productName: dt?.name,
    //                         sub-category: dt?.sub-category?.name,
    //                         subCategory: dt?.sub_category?.name,
    //                     });
    //                 })
    //                 setRows(rowData);
    //                 setLoading(false);
    //             }
    //         } else {
    //             setLoading(false);
    //             toast.error(result.message);
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         toast.error(error.response.data.message.replace(/_/g, ' '));
    //     })
    // }




    const onEdit = (id) => {
        history.push('/edit-video/' + id);
    }
    const onView = (id) => {
        history.push('/add-new-video/' + id);
    }
    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this video?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            // if (willShip) {
            //     await DeleteProduct(id).then((result) => {
            //         if (result.status) {
            //             swal(result.message, {
            //                 icon: "success",
            //             });
            //             getProductFunction(keyword, sub-category, subCategory, page);
            //         } else {
            //             toast.error(result.message);
            //         }
            //     }).catch((error) => {
            //         toast.error(error.response.data.message.replace(/_/g, ' '));
            //     })
            // }
        });
    }

    const productActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'imgUrl',
            name: 'Video Cover',
            selector: rowData => rowData.oid,
            format: rowData => <img src={rowData.imgUrl}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.IMAGENOTFOUND;
                }}
                className={"img-table img-thumbnail"} alt={"Image"} />
        },
        {
            id: 'videoTitle',
            name: 'Video Title',
            maxWidth: '160px',
            selector: rowData => rowData.videoTitle,
        },
        {
            id: 'gender',
            name: 'Gender',
            selector: rowData => rowData.gender,
        },
        {
            id: 'type',
            name: 'Type',
            selector: rowData => rowData.type,
        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => productActions(rowData.id),
        },
    ];

    const data = [{
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    },
    {
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    },
    {
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    },
    {
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    },
    {
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    },
    {
        imgUrl: "https://picsum.photos/300/300",
        videoTitle: "Dancing in rain",
        type: "Yoage",
        gender: "Male",
    }


    ]

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Products</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon /></InputGroup.Text>
                                    <Form.Select
                                        // onChange={e => {
                                        //     setCategory(e.target.value);
                                        //     getSubCategories(e.target.value);
                                        // }}
                                        aria-label="Default Filters">
                                        <option value="">Types</option>
                                        {
                                            filterCategory ?
                                                filterCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                }) : ''
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon /></InputGroup.Text>
                                    <Form.Select
                                        // onChange={e => {
                                        //     setSubCategory(e.target.value);
                                        // }}
                                        aria-label="Default Filters">
                                        <option value="">Gender</option>
                                        {
                                            filterSubCategory ?
                                                filterSubCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                })
                                                : ''
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/add-new-video"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#12726C'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default VideoList
