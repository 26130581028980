import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetAptVideos = async (params) => {
    const response = await GET(apiUrl.apt_education_videos, params);
    return response;
}

export const CreateAptVideos = async (data) => {
    const response = await POST(apiUrl.apt_education_videos, data);
    return response;
}

export const UpdateAptVideos = async (id, data) => {
    const response = await PUT(apiUrl.apt_education_videos, id, data);
    return response;
}

export const DeleteAptVideos = async (id) => {
    const response = await DELETE(apiUrl.apt_education_videos, id);
    return response;
}