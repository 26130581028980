import {apiUrl} from '../utils/constants';
import {GET} from './api.service.wrapper';

export const GetStatusCheckIn = async ( keyword= '',page = '', perPage = '', isPaginate = 1, user_id= '', program_id= '',start_dateTime='',end_dateTime='',program_days ='') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(user_id){
        params['user_id'] = user_id;
    }

    if(program_id){
        params['program_id'] = program_id;
    }

    if(program_days){
        params['program_days'] = program_days;
    }

    if (start_dateTime && JSON.stringify(start_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['start_dateTime'] = start_dateTime;
    }

    if (end_dateTime && JSON.stringify(end_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['end_dateTime'] = end_dateTime;
    }


    params['is_paginate'] = isPaginate;
    const response = await GET(apiUrl.userStatusCheckIn, params);
    return response;
}

export const GetTestFeedback = async ( keyword= '',page = '', perPage = '', isPaginate = 1, user_id= '', program_id= '',start_dateTime='',end_dateTime='',program_days ='') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(user_id){
        params['user_id'] = user_id;
    }

    if(program_id){
        params['program_id'] = program_id;
    }

    if(program_days){
        params['program_days'] = program_days;
    }

    if (start_dateTime && JSON.stringify(start_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['start_dateTime'] = start_dateTime;
    }

    if (end_dateTime && JSON.stringify(end_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['end_dateTime'] = end_dateTime;
    }

    params['is_paginate'] = isPaginate;
    const response = await GET(apiUrl.userProgramDayTest, params);
    return response;
}

export const GetTestSkip = async ( keyword= '',page = '', perPage = '', isPaginate = 1, user_id= '', program_id= '',start_dateTime='',end_dateTime='',program_days ='') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(user_id){
        params['user_id'] = user_id;
    }

    if(program_id){
        params['program_id'] = program_id;
    }

    if(program_days){
        params['program_days'] = program_days;
    }

    if (start_dateTime && JSON.stringify(start_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['start_dateTime'] = start_dateTime;
    }

    if (end_dateTime && JSON.stringify(end_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['end_dateTime'] = end_dateTime;
    }

    params['is_paginate'] = isPaginate;
    const response = await GET(apiUrl.userSkipTests, params);
    return response;
}

export const GetExerciseSkip = async ( keyword= '',page = '', perPage = '', isPaginate = 1, user_id= '', program_id= '',start_dateTime='',end_dateTime='',program_days ='') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(user_id){
        params['user_id'] = user_id;
    }

    if(program_id){
        params['program_id'] = program_id;
    }

    if(program_days){
        params['program_days'] = program_days;
    }

    if (start_dateTime && JSON.stringify(start_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['start_dateTime'] = start_dateTime;
    }

    if (end_dateTime && JSON.stringify(end_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['end_dateTime'] = end_dateTime;
    }

    params['is_paginate'] = isPaginate;
    const response = await GET(apiUrl.userSkipExercise, params);
    return response;
}

export const GetStatusCheckOut = async ( keyword= '',page = '', perPage = '', isPaginate = 1, user_id= '', program_id= '',start_dateTime='',end_dateTime='',program_days ='') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(user_id){
        params['user_id'] = user_id;
    }

    if(program_id){
        params['program_id'] = program_id;
    }

    if(program_days){
        params['program_days'] = program_days;
    }

    if (start_dateTime && JSON.stringify(start_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['start_dateTime'] = start_dateTime;
    }

    if (end_dateTime && JSON.stringify(end_dateTime) !== JSON.stringify({"format":"mm/dd/yyyy"})) {
        params['end_dateTime'] = end_dateTime;
    }

    params['is_paginate'] = isPaginate;
    const response = await GET(apiUrl.userStatusCheckout, params);
    return response;
}