import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap'
import { genders } from "../../utils/constants";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { GetBodyTypes } from '../../services/body_types.service';
import { GetCategories } from '../../services/categories.service';
import { GetInjuries } from '../../services/injurys.service';
import { CreateProgram } from '../../services/programs.service';
import { GetTests } from "../../services/tests.service";
import VideoInput from '../exercise/VideoInput';
import MultipleVideosInput from '../exercise/MultipleVideosInput';

function AddNewProgramForm(props) {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const [subCategories, setSubCategories] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [injuryPrograms, setInjuryPrograms] = useState([]);
    const [resetFormViewer, setResetFormViewer] = useState(false)
    const [bodyTypeVideo, setBodyTypeVideo] = useState(null)
    const [bodyTypeVideos, setBodyTypeVideos] = useState([]);
    const [welcomeVideo, setWelcomeVideo] = useState(null)
    const [selectedTests, setSelectedTests] = useState([]);
    const [tests, setTests] = useState([]);

    const [injuryProgram, setInjuryProgram] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [bodyType, setBodyType] = useState('');

    useEffect(() => {
        const getSubCategories = async () => {
            await GetCategories('', '', '', 0, '', '', 0, 'asc', 'name').then((result) => {
                if (result.status && result.data) {
                    setSubCategories(result.data);
                }
            })
        }

        const getBodyTypes = async () => {
            await GetBodyTypes('', '', '', 0, 'asc', 'name').then((result) => {
                if (result.status && result.data) {
                    setBodyTypes(result.data);
                }
            })
        }

        const getInjuryPrograms = async () => {
            await GetInjuries('', '', '', 0, 10, 'asc', 'name').then((result) => {
                if (result.status && result.data) {
                    setInjuryPrograms(result.data);
                }
            })
        }

        const getTests = async () => {
            await GetTests('', '', '', '', 0, 'asc', 'title').then((result) => {
                if (result.status && result.data) {
                    setTests(result.data);
                }
            })
        }

        getBodyTypes()
        getSubCategories()
        getInjuryPrograms()
        getTests()
    }, [])

    const onSubmit = async data => {
        setDisableButton(true)
        setLoader(true)
        await CreateProgram({
            name: data.name,
            body_type_id: data.body_type_id ? data.body_type_id : null,
            category_id: data.category_id ? data.category_id : null,
            days_per_week: data.days_per_week ? data.days_per_week : null,
            gender: data.gender ? data.gender : null,
            injury_id: data.injury_id ? data.injury_id : null,
            welcome_video: welcomeVideo ? welcomeVideo : '',
            body_type_video: bodyTypeVideo ? bodyTypeVideo : '',
            body_type_videos: bodyTypeVideos ? bodyTypeVideos : [],
            program_tests: selectedTests ? selectedTests : null
        }).then(async (data) => {
            if (data.status) {
                history.push({
                    pathname: 'manage-program',
                    state: {
                        program: data.data,
                    },
                });
            } else {
                setDisableButton(false)
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                setDisableButton(false)
                setLoader(false)
                return toast.error(error.response.data.message);
            }
        })
    };

    const setWelcomeVideoFunc = (data) => {
        setWelcomeVideo(data)
    }

    const setBodyTypeVideoFunc = (data) => {
        setBodyTypeVideo(data)
    }

    const selectedTestsOpt = (selectedValue) => {
        let data = [];
        selectedValue.map((item) => {
            data.push(item.value)
        });
        setSelectedTests(data);
    }

    const setBodyTypeFunc = (selectedValue) => {
        setValue('body_type_id', selectedValue ? selectedValue.value : '')
        setBodyType(selectedValue ? selectedValue.value : '')
    }

    const setInjuryProgramFunc = (selectedValue) => {
        setValue('injury_id', selectedValue ? selectedValue.value : '')
        setInjuryProgram(selectedValue ? selectedValue.value : '')
    }

    const setSubCategoryFunc = (selectedValue) => {
        setValue('category_id', selectedValue ? selectedValue.value : '')
        setSubCategory(selectedValue ? selectedValue.value : '')
    }

    const resetWelcomeVideoFunc = (data) => {
        setWelcomeVideo(null)
    }

    const resetBodyTypeVideoFunc = (data) => {
        setBodyTypeVideo(null)
    }

    return (
        <div className="AddNewProgramForm">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Create New Program</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={7}>
                    <Form className="formAddProgram" id="formAddProgram" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor={"name"} >Program Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Program Name"
                                        id={"name"}
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Program name is required"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 80,
                                                message: "Max length is 80"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Body Type</Form.Label>
                                    <Select
                                        id={"body_type_id"}
                                        options={bodyTypes && bodyTypes.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={setBodyTypeFunc}
                                    // ref={ref => {
                                    //     let selectRefs = selectRef
                                    //     selectRefs.push(ref)
                                    //     setSelectRef(selectRefs)
                                    // }}
                                    />
                                    {errors.body_type_id && <Form.Text
                                        className="text-muted validationText hasError">{errors.body_type_id.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Sub Category</Form.Label>
                                    <Select
                                        id={"category_id"}
                                        options={subCategories && subCategories.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={setSubCategoryFunc}
                                    // ref={ref => {
                                    //     let selectRefs = selectRef
                                    //     selectRefs.push(ref)
                                    //     setSelectRef(selectRefs)
                                    // }}
                                    />
                                    {errors.category_id && <Form.Text
                                        className="text-muted validationText hasError">{errors.category_id.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Injury Program</Form.Label>
                                    <Select
                                        id={"injury_id"}
                                        options={injuryPrograms && injuryPrograms.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={setInjuryProgramFunc}
                                    // ref={ref => {
                                    //     let selectRefs = selectRef
                                    //     selectRefs.push(ref)
                                    //     setSelectRef(selectRefs)
                                    // }}
                                    />
                                    {errors.injury_id && <Form.Text
                                        className="text-muted validationText hasError">{errors.injury_id.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select
                                        placeholder="Select Gender"
                                        id={'gender'}
                                        {...register("gender", {
                                            // required: {
                                            //     value: "required",
                                            //     message: "gender is required"
                                            // }
                                        }
                                        )}
                                    >
                                        <option value={''} selected={true} disabled={true}>Select Gender</option>
                                        <option value={genders.FEMALE}>Female</option>
                                        <option value={genders.MALE}>Male</option>
                                    </Form.Select>
                                    {errors.gender && <Form.Text
                                        className="text-muted validationText hasError">{errors.gender.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Days Per Week</Form.Label>
                                    <Form.Select
                                        id={'days_per_week'}
                                        placeholder="Select Days Per Week"
                                        {...register("days_per_week", {
                                            // required: {
                                            //     value: "required",
                                            //     message: "Days per week is required"
                                            // }
                                        }
                                        )}
                                    >
                                        <option value={''} selected={true} disabled={true}>Select Days Per Week</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                    </Form.Select>
                                    {errors.days_per_week && <Form.Text
                                        className="text-muted validationText hasError">{errors.days_per_week.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="bodyType">
                                    <Form.Label className='d-block'>Tests</Form.Label>
                                    <Select
                                        isMulti
                                        options={tests && tests.map(e => ({ label:`${e.title} (${e.gender == 20 ? "Female" : "Male"})`, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedTestsOpt}
                                    // required={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group>
                                    <Form.Label className='d-block mt-3'>Welcome Video</Form.Label>
                                    <VideoInput width={300} height={200} videoUrl={setWelcomeVideoFunc}
                                        resetImageViewer={resetFormViewer} onCloseDataIndex={1} onCloseRemoveData={resetWelcomeVideoFunc} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group>
                                    <Form.Label className='d-block mt-3'>About Video</Form.Label>
                                    <VideoInput width={300} height={200} videoUrl={setBodyTypeVideoFunc}
                                        resetImageViewer={resetFormViewer} onCloseDataIndex={1} onCloseRemoveData={resetBodyTypeVideoFunc} />
                                </Form.Group>
                            </Col>
                            
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group >
                                    <Form.Label className='d-block mt-3'>Body Type Videos</Form.Label>
                                    <MultipleVideosInput
                                        width={300} 
                                        height={200} 
                                        videosLimit={6}
                                        setBodyTypeVideos={setBodyTypeVideos}
                                        bodyTypeVideos={bodyTypeVideos}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ?
                                    <div className="spin-loader">
                                        <FadeLoader color={"#EC1246"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Next"} disabled={disableButton} />
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default AddNewProgramForm;