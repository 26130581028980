import React from 'react'
import {Container, Row, Col, Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'

function ErrorComponent(props) {

    const history = useHistory();

    return (
        <div className={"ErrorPage"}>
            <Container>
                <Row>
                    <Col>
                        <h1>Error Page {props.type}</h1>
                        <p>{props.message} <Button onClick={history.goBack} className="btn-back" variant="link">Go Back</Button></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ErrorComponent