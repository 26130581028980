import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap'
import {constants, cross_origin} from "../../utils/constants";
import { useHistory, useLocation } from 'react-router-dom';
import { GetExerciseById, GetExerciseSummary, UpdateExercise } from '../../services/exercises.service';
import { toast } from 'react-toastify';
import { EmptyLocalStorage } from '../../services/auth/auth.service';
import { Interweave } from 'interweave';

const SummaryBox = (props) => {
    const maleData = props.maleData;
    const femaleData = props.femaleData;
    const maxLength = 40;

    const ReadMoreP = ({ text, maxLength, className }) => {
        const [expanded, setExpanded] = useState(false);
        const truncatedText = text?.slice(0, maxLength);
        return (
            <div>
                <div className={`titles-label ${className}`} style={{ wordWrap: 'break-word' }}>
                    <Interweave content={expanded ? text : truncatedText} />
                    {/*{expanded*/}
                    {/*    ? text*/}
                    {/*    : text?.length > maxLength*/}
                    {/*        ? `${text.slice(0, maxLength)}...`*/}
                    {/*        : text}*/}
                    <button className={'btn-expand'} onClick={() => setExpanded(!expanded)}>
                        {expanded ? 'See less' : 'See more'}
                    </button>
                </div>

            </div>
        );
    }

    return (
        <div className='mt-5 summary-box'>
            <div className={"summary-box-head"}>
                <h2>{maleData.equipment_exercise_type === 0 ? 'Level ' + maleData.level_id : 'Equipment ' + maleData.equipment_exercise_type}</h2>
            </div>
            <div className={"summary-box-body"}>
                {/* <div>
                    <span className='titles-label'>Title:</span>
                    <span className='datas'>{maleData.exercise.name}</span>
                </div> */}
                <Row className={"mb-2 g-1"}>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <div className='titles-box titles-label'>{maleData?.file_name}</div>
                        <div className='SummaryCoverImg'>
                            <img className='' crossOrigin={cross_origin ? "anonymous" : null} src={maleData?.video_cover}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.IMAGENOTFOUNDREC;
                                }} alt='Exercise Level Cover' />
                        </div>
                        <div className='titles-box'>
                            <ReadMoreP text={maleData?.starting_position} maxLength={maxLength} />
                        </div>
                        <div className='titles-box'>
                            <ReadMoreP text={maleData?.movements} maxLength={maxLength} />

                        </div>
                        <div className='titles-box'>
                            <ReadMoreP text={maleData?.internal_notes} maxLength={maxLength} className={'text-danger'} />
                        </div>

                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <div className='titles-box titles-label'>{femaleData?.file_name}</div>
                        <div className='SummaryCoverImg'>
                            <img className='' crossOrigin={cross_origin ? "anonymous" : null} src={femaleData?.video_cover} onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = constants.IMAGENOTFOUNDREC;
                            }} alt='Exercise Level Cover' />
                        </div>
                        <div className='titles-box'>
                            <ReadMoreP text={femaleData?.starting_position} maxLength={maxLength} />

                        </div>
                        <div className='titles-box'>
                            <ReadMoreP text={femaleData?.movements} maxLength={maxLength} />
                        </div>
                        <div className='titles-box '>
                            <ReadMoreP text={femaleData?.internal_notes} maxLength={maxLength} className={'text-danger'} />
                        </div>
                    </Col>
                </Row>

                {/* <div>
                    <span className='titles-label'>Filename:</span>
                    <span className='datas'>{maleData.file_name}</span>
                </div> */}
                {/* <div>
                    <span className='titles-label d-block'>Movement Instructions:</span>
                    <span className='datas'>
                        <ReadMore textLength={40}>
                            {maleData.movements}
                        </ReadMore>
                    </span>
                </div> */}
                {/* <div>
                    <span className='titles-label d-block'>Internal Notes:</span>
                    <span className='datas'>
                        <ReadMore textLength={40}>
                            {maleData.internal_notes}
                        </ReadMore>
                    </span>
                </div> */}
            </div>
        </div>
    )
}

function Summary(props) {
    let history = useHistory();
    let location = useLocation();
    const [exercise, setExercise] = useState();
    const [maleData, setMaleData] = useState();
    const [femaleData, setFemaleData] = useState();
    const [disableButton, setDisableButton] = useState(false);
    const [exerciseId, setExerciseId] = useState(props.exerciseId);
    const [level, setLevel] = useState('');

    const getExerciseById = async (id) => {
        await GetExerciseById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setExercise(result.data);
                }
                else {
                    history.push('/add-exercise')
                }
            }
        })
    }

    if (location.state !== undefined && exercise === undefined) {
        setExercise(location.state.exercise)
    }

    if (location.state === undefined && exercise === undefined) {
        if (exerciseId !== undefined && exerciseId !== null) {
            getExerciseById(exerciseId);
        } else {
            history.push('/add-exercise')
        }
    }

    useEffect(() => {
        const getExerciseSummary = async (exercise_id) => {
            await GetExerciseSummary(exercise_id, level).then((result) => {
                if (result.status && result.data) {
                    setMaleData(result.data['male'])
                    setFemaleData(result.data['female'])
                    props.changed(false)
                }
            })
        }
        if (exercise !== null && exercise !== undefined) {
            getExerciseSummary(exercise.id)
        } else {
            getExerciseSummary(exerciseId)
        }
        props.changed(false)
    }, [exercise, exerciseId, level])

    const updateCompleted = async () => {
        let dt = {
            name: exercise.name,
            is_complete: 1
        }
        await UpdateExercise(exercise.id, dt).then(async (data) => {
            setDisableButton(true)
            if (data.status) {
                toast.success(data.message);
                setDisableButton(false)
                history.push('/exercises')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    return (
        <div className="Summary">
            {/* Level one details */}
            <Row>
                <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Form.Label className="d-block mt-sm-2">Levels</Form.Label>
                    <Form.Select
                        className="formselect program-dropdown"
                        aria-label="Weight"
                        onChange={(e) => setLevel(e.target.value)}
                    >
                        <option value="" selected={true}>All Levels</option>
                        <option value={1}>Level 1</option>
                        <option value={2}>Level 2</option>
                        <option value={3}>Level 3</option>
                    </Form.Select>
                </Col>
            </Row>
            <Button className='finish-btn' onClick={updateCompleted} disable={disableButton}>
                Finish
            </Button>
            {
                maleData && Object.keys(maleData).length > 0 ?
                    <Row>
                        {
                            maleData.map((anObjectMapped, index) => {
                                return (
                                    <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={3}>
                                        <SummaryBox maleData={anObjectMapped} femaleData={femaleData ? femaleData[index] : ''} key={index} />
                                    </Col>
                                );
                            })
                        }
                    </Row>
                    :
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>No Summary Available</Col>
                    </Row>
            }

        </div>
    )
}

export default Summary