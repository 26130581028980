import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { genders } from "../../utils/constants";
import { TbFilterOff } from "react-icons/tb";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoThumbnail from "./VideoThumbnail";
import { SearchIcon } from "../../components/SvgIcons";
import { FadeLoader } from "react-spinners";
import { GetBodyTypes } from '../../services/body_types.service';
import { GetProgramDayDetails, UpdateProgramDays } from '../../services/programs.service';
import { GetExerciseTypes } from '../../services/exercise_types.service';
import { GetMuscles } from '../../services/muscles.service';
import { GetEquipments } from "../../services/equipments.service";
import { GetProgramExercises } from "../../services/exercises.service";
import DayWiseExercise from './DayWiseExercise';


function ProgramDetails(props) {
  let history = useHistory();
  let location = useLocation();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onBlur",
  });
  const [isSaving, setIsSaving] = useState(props.isChanging);
  const [programPreData, setProgramPreData] = useState([null, null, null, null, null, null, null, null, null,null,null,null]);
  const [programNewData, setProgramNewData] = useState([null, null, null, null, null, null, null, null, null,null,null,null]);
  const [program, setProgram] = useState();
  const [keyword, setKeyword] = useState('');
  const [gender, setGender] = useState('');
  const [bodyType, setBodyType] = useState('');
  const [bodyTypePre, setBodyTypePre] = useState([]);

  const [equipment, setEquipment] = useState('');
  const [exerciseType, setExerciseType] = useState('');
  const [muscle, setMuscle] = useState('');

  const [loader, setLoader] = useState(false)
  const [disableButton, setDisableButton] = useState(false);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [dragged, setDragged] = useState(null);
  const [swapDraggedArray, setSwapDraggedArray] = useState(null);
  const [selectRef, setSelectRef] = useState([])
  const videoContainerRef = useRef();
  const videoComponentRef = useRef();
  const dayWiseContainerRef = useRef();
  const [removeExercises, setRemoveExercises] = useState([]);
  const [isRestComponent, setIsRestComponent] = useState(false);

  const [dayId, setDayId] = useState('');
  const typingTimeoutRef = useRef(null);

  const handleKeyUp = (e) => {
    clearTimeout(typingTimeoutRef.current);
    const value = e.target.value;
    const sanitizedInput = value.replace(/[^A-Za-z\s]+/g, "");
    if (sanitizedInput.length > 2) {
        typingTimeoutRef.current = setTimeout(() => {
          setKeyword(sanitizedInput);
        }, 500); // Set keyword after 500 milliseconds of inactivity
    } else {
      setKeyword(null);
    }
  };

  if (location.state !== undefined && program === undefined) {
    setProgram(location.state.program)
    if (location.state.redirectToSummary) {
      location.state.redirectToSummary = false;
      props.handleStep(12)
    }
  }

  if (location.state === undefined && program === undefined) {
    history.push('/add-new-program')
  }

  const resetCurrentComponent = async () => {
    setIsRestComponent(true)
    setDayId('')
    setDragged(null)
    setExercises([]);
    await props.changed(false)
    await props.setNextStepFunc(null)
    setProgramPreData([null, null, null, null, null, null, null, null, null, null, null, null])
    setProgramNewData([null, null, null, null, null, null, null, null, null, null, null, null])

    document.getElementById("formFilterProgram")?.reset();
    document.getElementById("formProgramExercise")?.reset();
  }
  useEffect(() => {


    const getProgramDayDetails = async (program_id, day_id) => {
      setDisableButton(true)
      setLoader(true)
      await GetProgramDayDetails(program_id, day_id).then((result) => {
        if (result.status && result.data) {
          setDayId(result.data.id)
          setValue('internal_notes', result.data.internal_notes)
          if (result.data.programDayExercises.length > 0) {
            handlePreDataArray(result.data.programDayExercises)
          }
        } else {
          setProgramPreData([null, null, null, null, null, null, null, null, null,null,null,null])
        }
        setDisableButton(false)
        setLoader(false)
      })
    }

    resetCurrentComponent()
    getProgramDayDetails(program?.id, props.dayId);
  }, [program, props.activeStep, props.dayId, props.day, props.activeStep])

  useEffect(() => {
    setProgramNewData(programPreData)
  }, [programPreData])

  useEffect(() => {
    const getBodyTypes = async () => {
      await GetBodyTypes('', '', '', 0, 'asc', 'name').then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
          if (bodyType !== '' && bodyType !== null) {
            let editBodyTypePre = result.data.filter((item, index) => item.id == bodyType)
            setBodyTypePre({ value: editBodyTypePre[0] ? editBodyTypePre[0].id : '', label: editBodyTypePre[0] ? editBodyTypePre[0].name : '' })
          } else {
            setBodyTypePre(null)
          }
        }
      })
    }

    const getExerciseTypes = async () => {
      await GetExerciseTypes('', '', '', 0, 'asc', 'name').then((result) => {
        if (result.status && result.data) {
          setExerciseTypes(result.data);
        }
      })
    }

    const getMuscles = async () => {
      await GetMuscles('', '', '', 0, 'asc', 'name').then((result) => {
        if (result.status && result.data) {
          setMuscles(result.data);
        }
      })
    }

    const getEquipmentList = async () => {
      await GetEquipments('', '', '', 0, 'asc', 'title').then((result) => {
        if (result.status && result.data) {
          setEquipments(result.data);
        }
      })
    }

    getBodyTypes();
    getExerciseTypes();
    getMuscles();
    getEquipmentList()
  }, [])

  const removeExerciseVideo = (targetId) => {
    const newPreData = [...programPreData];
    let removeExercisesArray = removeExercises
    if ((newPreData[targetId] !== undefined && newPreData[targetId] !== null) && (newPreData[targetId].id !== undefined && newPreData[targetId].id !== null)) {
      removeExercisesArray.push(newPreData[targetId].id)
      setRemoveExercises(removeExercisesArray)
    }
    newPreData[targetId] = null
    setProgramPreData(newPreData)

    const newNewData = [...programNewData];
    newNewData[targetId] = null
    setProgramNewData(newNewData)
  }

  const onSubmit = async (data) => {
    if (programNewData.length !== 0 && programNewData !== undefined) {
      setDisableButton(true)
      setLoader(true)
      await UpdateProgramDays(props.dayId, {
        internal_notes: data.internal_notes,
        program_id: program.id,
        week: 1,
        day: props.dayId,
        circuit: 1,
        name: props.day,
        day_id: dayId ? dayId : '',
        programDayExercises: programNewData,
        removeExercises: removeExercises
      }).then(async (data) => {
        setIsRestComponent(true)
       await resetCurrentComponent()
        setDayId('')
        if (data.status) {
          setDisableButton(false)
          setLoader(false)
          toast.success(data.message);
          props.changed(false)
          if (props.nextStep != null) {
            await props.handleStep(props.nextStep)
          } else {
            await props.handleNext()
          }
        } else {
          toast.error(data.message.replace(/_/g, ' '));
          setDisableButton(false)
          setLoader(false)
        }
      }).catch((error) => {
        setDisableButton(false)
        setLoader(false)
        toast.error('Something went wrong. Please try again.');
        if (error.response.status == 401) {
          EmptyLocalStorage()
          history.push('/');
        }

      })
    } else {
      toast.error('Please select exercise videos');
    }
  };

  const getExercises = async () => {
    setExercises([])
    await GetProgramExercises(keyword, bodyType, exerciseType, equipment, muscle, gender, 'asc', 'name').then((result) => {
      if (result.status) {
        const exerciseData = result.data
        setExercises(exerciseData)
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    getExercises()

    // if (videoContainerRef.current) {
    //   videoContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    //
    // if (dayWiseContainerRef.current) {
    //   dayWiseContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
  }, [keyword, bodyType, exerciseType, equipment, muscle, gender, program, props.activeStep, props.dayId, props.day])

  const clearFilter = () => {
    setKeyword('')
    setBodyType('')
    setExerciseType('')
    setEquipment('')
    setMuscle('')
    setGender('')
    setBodyTypePre([])
    resetSelectInstances()
    document.getElementById("formFilterProgram").reset();
  }

  const setDraggedFunc = (data) => {
    setDragged(data)
    setSwapDraggedArray(null)
  }

  const onDragOverFunc = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onDragEnterFunc = (e) => {
    e.stopPropagation();
  }

  const onDropFunc = (e) => {
    e.preventDefault();
    props.changed(true)
    const targetId = e.target.id.split('-')[1]
    if (targetId && swapDraggedArray && !dragged) {
      let oldIndex = parseInt(swapDraggedArray.exercise_no) - 1
      let newIndex = parseInt(targetId) - 1
      let tempData = swapDraggedArray
      tempData.exercise_no = targetId
      const newPreData = [...programPreData];
      if (oldIndex != null && oldIndex != -1 && newIndex != null && newIndex != -1) {
        newPreData[oldIndex] = null
        newPreData[newIndex] = tempData
      }
      setProgramPreData(newPreData)

      const newNewData = [...programNewData];
      if (oldIndex != null && oldIndex != -1 && newIndex != null && newIndex != -1) {
        newNewData[oldIndex] = null
        newNewData[newIndex] = tempData
      }
      setProgramNewData(newNewData)
      setSwapDraggedArray(null)
      setDragged(null)
    } else if (e.target.className === "dropzone" && dragged) {
      const videoId = dragged.dataset.video_id
      const levelId = dragged.dataset.level_id
      setProgramDayNewDataFunc(videoId, targetId, levelId)
      setDragged(null)
      setSwapDraggedArray(null)
    }
  }



  const setSwapDraggedFunc = (data) => {
    props.changed(true)
    setDragged(null)
    setSwapDraggedArray(data)
  }

  const setProgramDayNewDataFunc = (exerciseId, targetId, levelId) => {
    if (exerciseId !== null && targetId !== null) {
      const exerciseData = exercises.filter(elem => elem.id == exerciseId)
      let preData = {
        'exercise_id': exerciseId,
        'exercise_no': targetId,
        'position': 0,
        'exercise_level_id': levelId,
        'exerciseLevel': exerciseData[0]['exerciseLevels'][0] ? exerciseData[0]['exerciseLevels'][0] : null,
        'feel_it_position': 'TL',
        'feel_it_show': true,
        'text_position': 'TR',
        'text_show': true,
        'exerciseLevels': exerciseData[0]['exerciseLevels'] ? exerciseData[0]['exerciseLevels'] : null,
      }

      let newData = {
        'exercise_id': exerciseId,
        'exercise_no': targetId,
        'position': 0,
        'exercise_level_id': levelId,
        'exerciseLevel': exerciseData[0]['exerciseLevels'][0] ? exerciseData[0]['exerciseLevels'][0] : null,
        'feel_it_position': 'TL',
        'feel_it_show': true,
        'text_position': 'TR',
        'text_show': true,
        'exerciseLevels': exerciseData[0]['exerciseLevels'] ? exerciseData[0]['exerciseLevels'] : null,
      }

      handleNewDataArray(preData, newData, targetId)
      return true
    }
    return false
  }

  const setWeightFunc = (e) => {
    props.changed(true)
    const targetId = e.target.id.split('-')[1]
    const position = e.target.value
    if (position !== null && targetId !== null) {
      const newNewData = [...programNewData];
      if (targetId - 1 !== -1) {
        newNewData[targetId - 1].position = position
      } else {
        newNewData[targetId].position = position
      }
      setProgramNewData(newNewData)
    }
  }

  const setFeelItOverlayFunc = (data) => {
    props.changed(true)
    const targetId = data.index
    const position = data.position

    if (position !== null && targetId !== null) {
      let tempArray = programNewData
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_position = position
        tempArray[targetId - 1].feel_it_show = data.show
      } else {
        tempArray[targetId].feel_it_position = position
        tempArray[targetId].feel_it_show = data.show
      }
      setProgramNewData(programNewData => tempArray)
    }
  }

  const setFeelImageShowFunc = (data) => {
    props.changed(true)
    const targetId = data.index
    if (targetId !== null) {
      let tempArray = programNewData
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_show = data.show
      } else {
        tempArray[targetId].feel_it_show = data.show
      }
      setProgramNewData(tempArray)


      let tempPreArray = programPreData
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].feel_it_show = data.show
      } else {
        tempPreArray[targetId].feel_it_show = data.show
      }
      setProgramPreData(tempPreArray)
    }
  }

  const setTextOverlayFunc = (data) => {
    props.changed(true)
    const targetId = data.index
    const position = data.position
    if (position !== null && targetId !== null) {
      let tempArray = programNewData
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_position = position
        tempArray[targetId - 1].text_show = data.show
      } else {
        tempArray[targetId].text_position = position
        tempArray[targetId].text_show = data.show
      }
      setProgramNewData(programNewData => tempArray)
    }
  }

  const setTextOverlayShowFunc = (data) => {
    props.changed(true)
    const targetId = data.index
    if (targetId !== null) {
      let tempArray = programNewData
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_show = data.show
      } else {
        tempArray[targetId].text_show = data.show
      }
      setProgramNewData(tempArray)

      let tempPreArray = programPreData
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].text_show = data.show
      } else {
        tempPreArray[targetId].text_show = data.show
      }
      setProgramPreData(tempPreArray)
    }
  }

  const handlePreDataArray = (array) => {

    // const newPreData = [...programPreData];
    const newPreData = [];
    array.map((item) => {
      newPreData[item.exercise_no - 1] = item
    })
    setProgramPreData(newPreData)
  };

  const handleNewDataArray = (preData, newData, targetId) => {
    const newPreData = [...programPreData];
    if (targetId - 1 !== -1) {
      newPreData[targetId - 1] = preData
    } else {
      newPreData[targetId] = preData
    }
    setProgramPreData(newPreData)

    const newNewData = [...programNewData];
    if (targetId - 1 !== -1) {
      newNewData[targetId - 1] = newData
    } else {
      newNewData[targetId] = newData
    }
    setProgramNewData(newNewData)
  };

  const swapArrayFunc = (data) => {
    const newPreData = [...programPreData];
    if (data.indexSource != null && data.indexSource != -1 && data.indexDestination != null && data.indexDestination != -1) {
      newPreData[data.indexDestination] = data.tempSource
      newPreData[data.indexSource] = data.tempDestination
    }
    setProgramPreData(newPreData)

    const newNewData = [...programNewData];
    if (data.indexSource != null && data.indexSource != -1 && data.indexDestination != null && data.indexDestination != -1) {
      newNewData[data.indexDestination] = data.tempSource
      newNewData[data.indexSource] = data.tempDestination
    }
    setProgramNewData(newNewData)
  }

  const setBodyTypeFunc = (selectedValue) => {
    setBodyType(selectedValue ? selectedValue.value : '')
  }

  const setEquipmentFunc = (selectedValue) => {
    setEquipment(selectedValue ? selectedValue.value : '')
  }

  const setExerciseTypeFunc = (selectedValue) => {
    setExerciseType(selectedValue ? selectedValue.value : '')
  }

  const setMuscleFunc = (selectedValue) => {
    setMuscle(selectedValue ? selectedValue.value : '')
  }

  const resetSelectInstances = () => {
    selectRef.map((item) => {
      if (item !== null) {
        item.clearValue()
      }

    })
    setSelectRef([null])
  }

  const setIsRestComponentFunc = (data) => {
    setIsRestComponent(false)
  }

  return (
    <Row>
      {/* form starts */}
      <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
      <Form id="formFilterProgram" autoComplete="off">
        <Row>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Gender</Form.Label>
            <Form.Select
              placeholder="Select Gender"
              id={'gender'}
              className="formselect program-dropdown"
              aria-label="gender"
              onChange={(e) => setGender(e.target.value)}
            >
              <option value={''} selected={true}>Select Gender</option>
              <option value={genders.FEMALE}>Female</option>
              <option value={genders.MALE}>Male</option>
            </Form.Select>
          </Col>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Body Type</Form.Label>
            <Select
              id={"body_type_id"}
              options={bodyTypes && bodyTypes.map(e => ({ label: e.name, value: e.id }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setBodyTypeFunc}
              ref={ref => {
                let selectRefs = selectRef
                selectRefs.push(ref)
                setSelectRef(selectRefs)
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Exercise Type</Form.Label>
            <Select
              id={"exercise_type"}
              options={exerciseTypes && exerciseTypes.map(e => ({ label: e.name, value: e.id }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setExerciseTypeFunc}
              ref={ref => {
                let selectRefs = selectRef
                selectRefs.push(ref)
                setSelectRef(selectRefs)
              }}
            />
            {/* <Form.Select
              placeholder="Select Type"
              id={"exercise_type"}
              className="formselect program-dropdown"
              aria-label="exercise_type"
              onChange={(e) => setExerciseType(e.target.value)}
            >
              <option value={''} selected={true}>Select Exercise Type</option>
              {
                exerciseTypes &&
                exerciseTypes.map((type, index) => (
                  <option key={index} value={type.id}>{type.name}</option>
                ))
              }
            </Form.Select> */}
          </Col>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Muscle</Form.Label>
            <Select
              id={"muscle"}
              options={muscles && muscles.map(e => ({ label: e.name, value: e.id }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setMuscleFunc}
              ref={ref => {
                let selectRefs = selectRef
                selectRefs.push(ref)
                setSelectRef(selectRefs)
              }}
            />
            {/* <Form.Select
              placeholder="Select Muscle"
              id={"muscle"}
              className="formselect program-dropdown"
              aria-label="muscle"
              onChange={(e) => setMuscle(e.target.value)}
            >
              <option value={''} selected={true}>Select Muscle</option>
              {
                muscles &&
                muscles.map((muscle, index) => (
                  <option key={index} value={muscle.id}>{muscle.name}</option>
                ))
              }
            </Form.Select> */}
          </Col>
          <Col xs={12} sm={12} md={4} lg={2} xl={2}>
            <Form.Label className="d-block mt-sm-2">Equipment</Form.Label>
            <Select
              id={"equipment"}
              options={equipments && equipments.map(e => ({ label: e.title, value: e.id }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setEquipmentFunc}
              ref={ref => {
                let selectRefs = selectRef
                selectRefs.push(ref)
                setSelectRef(selectRefs)
              }}
            />
            {/* <Form.Select
              placeholder="Select Equipment"
              id={"equipment"}
              className="formselect program-dropdown"
              aria-label="equipment"
              onChange={(e) => setEquipment(e.target.value)}
            >
              <option value={''} selected={true}>Select Equipment</option>
              {
                equipments &&
                equipments.map((equipment, index) => (
                  <option key={index} value={equipment.id}>{equipment.title}</option>
                ))
              }
            </Form.Select> */}
          </Col>
          <Col xs={12} sm={12} md={4} lg={2} xl={2} className={"h-100 mt-auto"}>
            <div className="text-end ">
              <ul className="list-inline">
                {/*<li className="list-inline-item">*/}
                {/*  <span style={{cursor: 'pointer', fontSize: '20px'}} title={"Apply Filters"}>*/}
                {/*    <TbFilter /> Apply*/}
                {/*  </span>*/}
                {/*</li>*/}
                {/*<li className="list-inline-item opacity-25">|</li>*/}
                <li className="list-inline-item" onClick={() => clearFilter()}>
                  <span style={{ cursor: 'pointer', fontSize: '14px' }} title={"Clear All Filters"}>
                    <TbFilterOff /> Clear All Filters
                  </span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} >
            <div className="">
              <InputGroup className="mb-3 search-group search-styling">
                <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="Search"
                  maxLength={"255"}
                  onChange={(e) => handleKeyUp(e)}
                />
              </InputGroup>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            {/*/!*<Form.Label className="d-block mt-sm-2">Program Name</Form.Label>*!/*/}
            {/*<Form.Control*/}
            {/*    type={"text"}*/}
            {/*    placeholder={"Enter Program Name"}*/}
            {/*    className={"formselect program-dropdown"}*/}
            {/*/>*/}
          </Col>
        </Row>
      </Form>
      </Col>
      {/* form end */}
      <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
      <Row>
        {/* Thumbnail gallery starts*/}
        <Col md={12} sm={12} lg={12} xl={7} xxl={7}>
          <div className="thumbnail-gallery">
            <Row className="g-1" ref={videoContainerRef} id={'thumbnail-gallery-row'}>
              {
                exercises.length > 0 &&
                    exercises?.map((item, index) => {
                     return (
                    <VideoThumbnail
                      exercise={item}
                      ref={videoComponentRef}
                      index={index}
                      videoUrl={item && item.exerciseLevels[0] ? item.exerciseLevels[0].video ? item.exerciseLevels[0].video : '' : ''}
                      videoPoster={item && item.exerciseLevels[0] ? item.exerciseLevels[0].video_cover ? item.exerciseLevels[0].video_cover : '' : ''}
                      videoTitle={item && item.name ? item.name : ''}
                      addedBy={''}
                      link={item && item.exerciseLevels[0] ? item.exerciseLevels[0].video ? item.exerciseLevels[0].video : '' : ''}
                      key={item.id} id={item.id}
                      level={item && item.exerciseLevels[0] ? item.exerciseLevels[0].id ? item.exerciseLevels[0].id : '' : ''}
                      dragged={setDraggedFunc}
                      flipVideo={item && item.exerciseLevels[0] ? item.exerciseLevels[0].video_flip ? item.exerciseLevels[0].video_flip : null : null}
                    />
                  )
                })
              }
            </Row>
          </div>
        </Col>
        {/* Thumbnail gallery ends*/}
        <Col md={12} sm={12} lg={12} xl={5} xxl={5}>
          {/* Right Side of Page */}

          {/*<Row>*/}
          {/*  <h1 className="program-days">{props.day}</h1>*/}
          {/*</Row>*/}
          <div className="thumbnail-gallery"  >
            <DayWiseExercise
              dragged={setDraggedFunc}
              onDragEnterFunc={onDragEnterFunc}
              onDragOverFunc={onDragOverFunc}
              onDropFunc={onDropFunc}
              setWeightFunc={setWeightFunc}
              removeExerciseVideo={removeExerciseVideo}
              dayExerciseData={programPreData}
              setFeelItOverlayFunc={setFeelItOverlayFunc}
              setFeelImageShowFunc={setFeelImageShowFunc}
              setTextOverlayPosFunc={setTextOverlayFunc}
              setTextOverlayShowFunc={setTextOverlayShowFunc}
              swapArray={swapArrayFunc}
              swapDragged={setSwapDraggedFunc}
              isRestComponent={isRestComponent}
              setIsRestComponent={setIsRestComponentFunc}
            />
          </div>

        </Col>

        <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
          <Form id="formProgramExercise" className="formProgramExercise mt-5" autoComplete="off" role="presentation"
            onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12} sm={12} lg={12} xl={12} xxl={12}>
              <Form.Group className="mb-3">
                <Form.Label>Internal Notes </Form.Label>
                <Form.Control
                  className="exercise-input"
                  placeholder="Internal Notes"
                  id={"internal_notes"}
                  {...register("internal_notes", {
                    minLength: {
                      value: 3,
                      message: "Min length is 3",
                    },
                    maxLength: {
                      value: 500,
                      message: "Max length is 500",
                    },
                    onChange: (e) => props.changed(true)
                  })}
                  type="text"
                />
                {errors.internal_notes && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.internal_notes.message}
                  </Form.Text>
                )}
              </Form.Group>
              </Col>
            </Row>
            {loader ?
              <Row className='mt-4'>
                <Col md={{ span: 2, offset: 5 }} className={'text-start'}>
                  <div className="spin-loader-screen">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                </Col>
              </Row>
              :
              <Row className='mt-4'>
                <Col md={6} className={'text-start'}>
                  <Button className='back-btn' color="inherit" type={"button"} onClick={() => props.handleBack()}
                    sx={{ mr: 1 }}>
                    Back
                  </Button>
                </Col>
                <Col md={{ span: 2, offset: 4 }} className={'text-end'}>
                  <Button id={'formProgramExerciseSubmitBtn'} className='next-btn' type={"submit"} disabled={disableButton}>
                    Next
                  </Button>
                </Col>
              </Row>
            }
          </Form>
        </Col>
      </Row>
      </Col>
    </Row>
  );
}

export default ProgramDetails;
