import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { constants, customStyles, STATUS } from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { CreateSport, DeleteSport, GetSportById, GetSports, UpdateSport } from "../../services/sports.service";


function Sports() {
    let history = useHistory();
    const [sportId, setSportId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [row, setRow] = useState();
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');

    const [formData, setFormData] = useState({});

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const { register, reset, handleSubmit, setError, setValue, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });


    useEffect(async () => {
        await getSportsFunction();
        setSportId(null)
    }, [keyword, status])

    const getSportsFunction = async () => {
        setLoading(true)
        await GetSports(keyword, page, perPage, 0, status).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            name: dt?.name,
                            status: STATUS[dt?.status]

                        });
                    })
                    setRows(rowData);
                }
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }

    const onEdit = (id) => {
        clearErrors('name');
        setShowEditModal(true)
        setSportId(id)
        GetSportById(id).then((result) => {
            if (result.status) {
                if (result.data) {

                    setRow(result.data)
                    setFormData(result.data)
                    setValue("name", result.data.name)
                    setValue("status", result.data.status)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteSport(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getSportsFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const contactActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '50px',
            grow: false,
            sortable: true,
            selector: rowData => rowData.id
        },
        {
            id: 'sport',
            name: 'Sport',
            sortable: true,
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.name,
        },
        {
            field: 'status',
            sortable: true,
            name: 'Status',
            selector: rowData => rowData.status,
        },
        {
            field: 'action',
            name: 'Action',
            width: '80px',
            center: true,
            cell: rowData => contactActions(rowData.id),
        },
    ];

    const onSubmit = async data => {
        setLoader(true)
        await CreateSport(data).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                setShowAddModal(false)
                setShowEditModal(false)
                getSportsFunction();
                reset({
                    name: '',
                    status: '',
                })

            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const onEditSubmit = async data => {
        setLoader(true)
        if (sportId != 0 && sportId != null) {
            await UpdateSport(sportId, data).then(async (data) => {
                setLoader(false)
                if (data.status) {
                    toast.success(data.message);
                    setShowAddModal(false)
                    setShowEditModal(false)
                    getSportsFunction();
                } else {
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        }
    };

    const EditModal = (props) => {
        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onEditSubmit)}>
                    <Form.Group className={"mb-3"} controlId="bodyType">
                        <Form.Label>Sport Name*</Form.Label>
                        <Form.Control {...register('name', {
                            required: {
                                value: "required",
                                message: "Sport Name is required"
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9_ ]*$/,
                                message: "This field must contain only letters"
                            },

                        })} type={"text"} placeholder={"Sport Name"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">{errors.name.message}</Form.Text>}
                    </Form.Group>
                    <Form.Group className={"mb-3"} controlId="status">
                        <Form.Label className='d-block'>Select Status</Form.Label>
                        <Form.Select className='formselect' aria-label="status" {...register('status', {
                            required: {
                                value: "required",
                                message: "Status is required"
                            }
                        })}>
                            <option value="10">Active</option>
                            <option value="20">Inactive</option>
                        </Form.Select>
                        {errors.status && <Form.Text
                            className=" validationText hasError">{errors.status.message}</Form.Text>}
                    </Form.Group>
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"submit"} />}
                </Form>
            </div>
        )
    };

    const AddModal = () => {
        setSportId(null)
        return (
            <div className={"ViewModalContact"}>
                <Form autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className={"mb-3"} controlId="bodyType">
                        <Form.Label>Sport Name*</Form.Label>
                        <Form.Control {...register('name', {
                            required: {
                                value: "required",
                                message: "Sport Name is required"
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9_ ]*$/,
                                message: "This field must contain only letters"
                            },

                        })} type={"text"} placeholder={"Sport Name"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">{errors.name.message}</Form.Text>}
                    </Form.Group>
                    <Form.Group className={"mb-3"} controlId="status">
                        <Form.Label className='d-block'>Select Status</Form.Label>
                        <Form.Select className='formselect' aria-label="status" {...register('status', {
                            required: {
                                value: "required",
                                message: "Status is required"
                            }
                        })}>
                            <option value="10">Active</option>
                            <option value="20">Inactive</option>
                        </Form.Select>
                        {errors.status && <Form.Text
                            className=" validationText hasError">{errors.weight.message}</Form.Text>}
                    </Form.Group>
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"Add New Sport"} />}
                </Form>
            </div>
        )
    };

    const AddModalOpen = () => {
        setShowAddModal(true)
        reset({
            name: '',
            status: '10',
        })
    }

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Sports</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Form.Group className={"mb-3"} controlId="status">
                                    <Form.Select className='formselect filter' aria-label="status" onChange={e => setStatus(e.target.value)}>
                                        <option key='blankChoice' hidden value>Sports</option>
                                        <option value=''>All</option>
                                        <option value="10">Active</option>
                                        <option value="20">Inactive</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>

                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => AddModalOpen()}>
                                    <AddIconOutline /> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    // data={rows}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />

                <ThemeModal title={"Add New Sport"} show={showAddModal} onHide={() => setShowAddModal(false)} size={"md"} content={<AddModal />} />
                <ThemeModal title={"Edit Sport"} show={showEditModal} onHide={() => setShowEditModal(false)} size={"md"} content={<EditModal />} />
            </div>
        </div>
    )
}

export default Sports
