import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetCategories } from "../../services/categories.service";
import { GetBodyTypes } from "../../services/body_types.service";
import { FadeLoader } from "react-spinners";
import { CreateExercise } from "../../services/exercises.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

function AddNewExercise() {
    let history = useHistory();
    const [muscles, setMuscles] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [exerciseType, setExerciseType] = useState([]);
    const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [selectedExerciseTypes, setSelectedExerciseTypes] = useState([]);
    const [selectedMuscles, setSelectedMuscles] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [loader, setLoader] = useState(false);
    const { register, watch, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        await bodyTypesList();
        await getSubCategories();
        await getExerciseTypes();
        await getMuscles();
    }, [])

    const getExerciseTypes = async () => {
        await GetExerciseTypes('', '', '', 0, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setExerciseType(result.data);
            }
        })
    }

    const getMuscles = async () => {
        await GetMuscles('', '', '', 0, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setMuscles(result.data);
            }
        })
    }

    const getSubCategories = async () => {
        await GetCategories('', '', '', 0, '', '', 0, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setSubCategories(result.data);
            }
        })
    }

    const bodyTypesList = async () => {
        await GetBodyTypes('', '', '', 0, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setBodyTypes(result.data);
            }
        })
    }

    const selectedBodyTypesOpt = (selectedValue) => {
        let data = [];
        selectedValue.map((item) => {
            data.push(item.value)
        });
        setSelectedBodyTypes(data);
    }

    const selectedSubCategoryOpt = (selectedValue) => {
        let data = [];
        selectedValue.map((item) => {
            data.push(item.value)
        });
        setSelectedSubCategories(data);
    }

    const selectedExerciseTypeOpt = (selectedValue) => {
        let data = [];
        selectedValue.map((item) => {
            data.push(item.value)
        });
        setSelectedExerciseTypes(data);
    }

    const selectedMusclesOpt = (selectedValue) => {
        let data = [];
        selectedValue.map((item) => {
            data.push(item.value)
        });
        setSelectedMuscles(data);
    }


    const onSubmit = async data => {
        setDisableButton(true)
        setLoader(true)
        await CreateExercise({
            name: data.name,
            body_types: selectedBodyTypes,
            exercise_categories: selectedSubCategories,
            exercise_types: selectedExerciseTypes,
            exercise_muscles: selectedMuscles,
        }).then(async (data) => {
            if (data.status) {
                // await createUser(data.data.user).then(() => {
                history.push({
                    pathname: 'add-exercise-steps',
                    state: {
                        exercise: data.data,
                    },
                });
                // })
            } else {
                setDisableButton(false)
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                setDisableButton(false)
                setLoader(false)
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    return (
        <div className="AddNewExerciseForm">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Create Exercise</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={7}>
                    <Form className="formAddUser" id="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="exercise_name">
                                    <Form.Label id={"formExerciseName"}>Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Name"
                                        id={"formExerciseName"}
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "name is required"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 80,
                                                message: "Max length is 80"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="bodyType">
                                    <Form.Label className='d-block'>Body Types</Form.Label>
                                    <Select
                                        isMulti
                                        options={bodyTypes && bodyTypes.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedBodyTypesOpt}
                                        required={true}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="subcategory">
                                    <Form.Label>Sub Category</Form.Label>
                                    <Select
                                        isMulti
                                        options={subCategories && subCategories.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedSubCategoryOpt}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="exerciseType">
                                    <Form.Label>Exercise Type</Form.Label>
                                    <Select
                                        isMulti
                                        options={exerciseType && exerciseType.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedExerciseTypeOpt}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="muscle">
                                    <Form.Label>Muscles</Form.Label>
                                    <Select
                                        isMulti
                                        options={muscles && muscles.map(e => ({ label: e.name, value: e.id }))}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedMusclesOpt}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {/*<input form="formAddUser" type="submit" className={"btn btn-green-theme w-100 mt-3"}*/}
                                {/*    // disabled={disableButton}*/}
                                {/*       value={"Next"}/>*/}
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <button type={"submit"} className={"btn btn-green-theme w-100 mt-3"}>Next</button>}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default AddNewExercise;