import React from 'react'
import {Breadcrumb} from "react-bootstrap";

function BreadcrumbsComponent() {
    return(
        <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/manage-users">
                Manage Users
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
                Add User
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BreadcrumbsComponent
