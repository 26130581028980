import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadImageToS3, uploadToS3 } from "../../utils/helpers";
import { GetEquipmentById, UpdateEquipment } from "../../services/equipments.service";
import { GetPainPointsById, UpdatePainPoints } from "../../services/pain_point_library.service";
import { useHistory } from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {cross_origin} from "../../utils/constants";

function EditPainPoint(props) {
    const id = props.match.params.id;
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [row, setRow] = useState(null);
    const [description, setDescription] = useState(null)
    const [isUpload, setIsUpload] = useState(false)
    const [image, setImage] = useState('')
    const [file, setFile] = useState(null)
    const { register, watch, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        GetPainPointsById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setRow(result.data)
                    setFormData(result.data)
                    setValue("name", result.data.name)
                    setValue("title", result.data.title)
                    setValue("image", result.data.image)
                    setValue("description", result.data.description)
                    setDescription(result.data.description)
                    setImage(result.data.image)
                    // setFile(result.data.image)
                    setIsUpload(true)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }, [])
    const onSubmit = async data => {
        setLoader(true)
        if (!file && !data.image) {
            setError('image', { message: 'Image is required' });
            setLoader(false)
            return false;
        } else if (file) {
            clearErrors('image')
            let s3Data = await uploadToS3(file.type, file.name, file)
            data.image = s3Data
            if (!s3Data) {
                toast.error("Uploading failed");
                setLoader(false)
                return false;
            }
        }
        data.description = description
        await UpdatePainPoints(id, data).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/pain-point-library')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
    }

    const validateDescription = () => {
        if (description === null || description.length === 0) {
            return 'Description is required';
        }
        return true; // Validation passed
    };

    const validateMedia = (fileList) => {
        const Imagefile = fileList[0];
        if(file){
            if (Imagefile && !Imagefile.type.startsWith('image/')) {
                return 'Invalid file type';
            }
        }

        return true; // Validation passed
    };

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList']
    };
    return (
        <div className="AddNewPainPoints">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Pain Point</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Image*</Form.Label>
                                    <input
                                        {...register('image',{
                                            validate: validateMedia // Use the custom validation function
                                        })}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        // className='btn btn-user-theme'
                                        id='image'

                                    />
                                    {isUpload ?
                                        <img src={image} className={"img-table img-big mt-1"}
                                             crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                    <br/>
                                    {errors.image && <Form.Text
                                        className="text-muted validationText hasError">{errors.image.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col  xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 80,
                                                message: "Max length is 80"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Description*</Form.Label>
                                    <div className={"ckeditor-container"}>
                                        <CKEditor
                                            id={"description"}
                                            {...register('description', {
                                                validate: validateDescription // Use the custom validation function
                                            })}
                                            editor={ClassicEditor}
                                            config={editorConfiguration}
                                            onChange={(event, editor) => {
                                                try {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                    if(!data){
                                                        setError( 'description',{message:'Description is required'});
                                                    }else if(data.length < 10){
                                                        setError( 'description',{message:'Min length is 3'});
                                                    }else if(data.length > 1008){
                                                        setError( 'description',{message:'Max length is 1000'});
                                                    }else{
                                                        clearErrors('description')
                                                    }

                                                } catch (error) {
                                                    console.error("Error in CKEditor onChange:", error);
                                                }
                                            }}
                                            data={description}
                                        />
                                    </div>
                                    {errors.description && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.description.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Update Pain Point"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    );
}

export default EditPainPoint;
