import AWS from 'aws-sdk';
import {
    ChonkyActions,
    FileBrowser,
    FileList,
    FileNavbar,
    FileToolbar,
    setChonkyDefaults,
} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { baseName, useStoryLinks } from '../utils/helpers';
import { constants, S3_BASE_URL } from "../utils/constants";
import {FadeLoader} from "react-spinners";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// The AWS credentials below only have read-only access to the Chonky demo bucket.
// You will need to create custom credentials for your bucket.
const BUCKET_NAME = constants.S3CREDENTIAL.bucketName;
const BUCKET_REGION = constants.S3CREDENTIAL.region;
const ACCESS_KEY_ID = constants.S3CREDENTIAL.accessKeyId;
const SECRET_ACCESS_KEY = constants.S3CREDENTIAL.secretAccessKey;

AWS.config.update({
    region: BUCKET_REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3();

const fetchS3BucketContents = (bucket, prefix, fileType = null) => {
    return s3
        .listObjectsV2({
            Bucket: bucket,
            Delimiter: '/',
            Prefix: prefix !== '/' ? prefix : '',
        })
        .promise()
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents;
            const s3Prefixes = response.CommonPrefixes;
            if (s3Objects && fileType) {
                chonkyFiles.push(
                    ...s3Objects.filter(object => {
                        if (fileType) {
                            return fileType === 'images'
                                ? object.Key.endsWith('.jpg') || object.Key.endsWith('.png') || object.Key.endsWith('.jpeg')
                                : object.Key.endsWith('.mp4') || object.Key.endsWith('.webm') || object.Key.endsWith('.mov') || object.Key.endsWith('.avi') || object.Key.endsWith('.mkv') || object.Key.endsWith('.m4v') || object.Key.endsWith('.flv');
                        }
                    }).map(
                        (object) => {
                            return ({
                                id: object.Key,
                                name: baseName(object.Key),
                                modDate: object.LastModified,
                                size: object.Size,
                            });
                        }
                    )
                );
            } else {
                chonkyFiles.push(
                    ...s3Objects.map(
                        (object) => {
                            return ({
                                id: object.Key,
                                name: baseName(object.Key),
                                modDate: object.LastModified,
                                size: object.Size,
                            });
                        }
                    )
                );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map(
                        (prefix) => ({
                            id: prefix.Prefix,
                            name: baseName(prefix.Prefix),
                            isDir: true,
                        })
                    )
                );
            }

            return chonkyFiles;
        });
};

const storyName = 'AWS S3 Browser';
export const S3Browser = ({ selectedMedia, fileName, fileType }) => {
    const [error, setError] = useState(null);
    const [folderPrefix, setKeyPrefix] = useState('/');
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true); // State for controlling the loader visibility


    useEffect(() => {
        setLoading(true); // Show the loader when fetching S3 content starts

        fetchS3BucketContents(BUCKET_NAME, folderPrefix, fileType)
            .then((data) => {
                setFiles(data);
                setLoading(false); // Hide the loader when S3 content is loaded
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false); // Hide the loader in case of an error
            });
    }, [folderPrefix, fileType]);

    const folderChain = React.useMemo(() => {
        let folderChain;
        if (folderPrefix === '/') {
            folderChain = [];
        } else {
            let currentPrefix = '';
            folderChain = folderPrefix
                .replace(/\/*$/, '')
                .split('/')
                .map(
                    (prefixPart) => {
                        currentPrefix = currentPrefix
                            ? currentPrefix + "/" + prefixPart
                            : prefixPart;
                        return {
                            id: currentPrefix,
                            name: prefixPart,
                            isDir: true,
                        };
                    }
                );
        }
        folderChain.unshift({
            id: '/',
            name: BUCKET_NAME,
            isDir: true,
        });
        return folderChain;
    }, [folderPrefix]);

    const handleFileAction = useCallback(
        (data) => {
            if (data.id == "mouse_click_file" && !data.payload.file.isDir) {
                selectedMedia(fileName, S3_BASE_URL + data.payload.file.id)
            }
            if (data.id === ChonkyActions.OpenFiles.id) {
                if (data.payload.files && data.payload.files.length !== 1) return;
                if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
                const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
                setKeyPrefix(newPrefix);
            }
        },
        [setKeyPrefix]
    );

    return (
        <div className="story-wrapper">
            <div className="story-description">
                {error && (
                    <div className="story-error">
                        An error has occurred while loading bucket:{' '}
                        <strong>{error}</strong>
                    </div>
                )}
            </div>

            <div style={{ height: '75vh' }}>
                <FileBrowser
                    instanceId={storyName}
                    files={files}
                    folderChain={folderChain}
                    onFileAction={handleFileAction}
                    defaultFileViewActionId={ChonkyActions.EnableListView.id}
                >
                    <FileNavbar />
                    <FileToolbar />
                    {loading ? ( // Show the loader when `loading` is true
                        <div className="spin-loader mt-auto mb-auto">
                            <FadeLoader color={"#EC1246"} height={10} />
                        </div>
                    ) : (
                    <FileList />
                    )}
                </FileBrowser>
            </div>
        </div>
    );
};
export default S3Browser