import React, {useEffect, useRef, useState} from 'react';
import { BiPause, BiPlay } from 'react-icons/bi';
import {cross_origin} from "../utils/constants";

function PosterVideoPlayer({ videoUrl, posterUrl, className, flip, autoPlay, id, width }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);
    const [repeatLoop, setRepeatLoop] = useState(false);
    const [showPoster, setShowPoster] = useState(true);
    const [transitionClass, setTransitionClass] = useState('');

    const handlePlayClick = () => {

        if (videoRef.current.paused) {
            videoRef.current.play();
            setShowPoster(false)
        } else {
            videoRef.current.pause();
        }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
        setDuration(videoRef.current.duration);
    };

    const handleProgressBarClick = (event) => {
        const progress = event.nativeEvent.offsetX / event.target.clientWidth;
        const currentTime = progress * duration;
        videoRef.current.currentTime = currentTime;
        setCurrentTime(currentTime);
    };

    // const handleVideoEnded = () => {
    //     if (!repeatLoop) {
    //         if (showExercise.exerciseLevel.video_flip || showExercise.position === 2) {
    //             videoRef.current.classList.remove("flip-video");
    //         } else {
    //             videoRef.current.classList.add("flip-video");
    //         }
    //         setRepeatLoop(true);
    //     } else {
    //         if (showExercise.exerciseLevel.video_flip || showExercise.position === 2) {
    //             videoRef.current.classList.add("flip-video");
    //         } else {
    //             videoRef.current.classList.remove("flip-video");
    //         }
    //         setRepeatLoop(false);
    //     }
    // };

    useEffect(() => {
        if (!showPoster) {
            setTransitionClass('transition-active');
        }
    }, [showPoster]);

    const handlePosterImageError = () => {
        setShowPoster(false);
    };
    return (
        <div className="poster-video-player">
            <div className={`poster-video-container ${transitionClass}`}>
                {showPoster && (
                    <img
                        crossOrigin={cross_origin ? "anonymous" : null}
                        onClick={handlePlayClick}
                        src={posterUrl}
                        alt="Video Poster"
                        className={`${flip ? 'poster-image flip-video' : 'poster-image'}`}
                        onError={handlePosterImageError}
                    />
                )}
                <video
                    id={id}
                    className={`${className} ${isPlaying ? 'video-visible' : ''}`}
                    ref={videoRef}
                    src={videoUrl}
                    onTimeUpdate={handleTimeUpdate}
                    onClick={handlePlayClick}
                    // onEnded={handleVideoEnded}
                    crossOrigin={cross_origin ? "anonymous" : null}
                    autoPlay={autoPlay ? autoPlay : false}
                />
            </div>
            <div className="controls">
                <button className="play-pause-button" onClick={handlePlayClick}>
                    {showPoster ? <BiPlay /> :(videoRef.current?.paused ? <BiPlay /> : <BiPause />)}
                </button>
                <div className="progress-bar" onClick={handleProgressBarClick}>
                    <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }} />
                </div>
            </div>
        </div>
    );
}

export default PosterVideoPlayer;
