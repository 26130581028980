import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { GetPageById, UpdatePage } from '../../services/pages.service';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { EmptyLocalStorage } from '../../services/auth/auth.service';
import { FadeLoader } from 'react-spinners';
import { Editor } from 'react-draft-wysiwyg';
import { uploadToS3 } from '../../utils/helpers';
import {
    EditorState,
    Entity,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';

function PageEdit(props) {
    let history = useHistory();
    const id = props.match.params.id;
    const [editorState, setEditorState] = useState(EditorState.createEmpty(''));
    const [formData, setFormData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [description, setDescription] = useState();
    const [showCodeEditor, setShowCodeEditor] = useState(false); // State for toggling code editor
    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList'],
    };
    const { register, handleSubmit, setValue,setError,clearErrors, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const getPagesById = async (id) => {
        await GetPageById(id)
            .then((result) => {
                if (result.status) {
                    if (result.data) {
                        setValue('name', result.data.name);
                        setFormData(result.data);
                        if (result.data.content) {
                            const content= ContentState.createFromBlockArray(htmlToDraft(result.data?.content));

                            const newEditorState = EditorState.createWithContent(content);
                            setEditorState(newEditorState);
                            // setDescription(result.data?.content)
                        }
                    }
                } else {
                    return toast.error(result.message);
                }
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    EmptyLocalStorage();
                    history.push('/');
                } else {
                    return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
                }
            });
    };

    useEffect(async () => {
        await getPagesById(id);
    }, [id]);


    const onSubmit = async (data) => {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if(content?.length <= 8){
            setError('content',{message:"Description is required"})
            return  false
        }else{
            clearErrors('content')
        }
        setLoader(true);
        await UpdatePage(id, {
            name: data.name,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        })
            .then((data) => {
                if (data.status) {
                    setLoader(false);
                    toast.success(data.message);
                    history.push('/page-list');
                } else {
                    setLoader(false);
                    toast.error(data.message.replace(/_/g, ' '));
                }
            })
            .catch((error) => {
                setLoader(false);
                if (error.response.status == 401) {
                    EmptyLocalStorage();
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            });
    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const updated = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        setDescription(updated);
        if(updated?.length <= 8){
            setError('content',{message:"Description is required"})
        }else{
            clearErrors('content')
        }
    };

    const handleFileUpload = async (file) => {
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        };
        let uploadImage = await uploadToS3(file.type, file.name, file, 'article');

        if (!uploadImage) {
            setLoader(false);
            toast.error("Can't Upload Image");
            return false;
        }
        return new Promise((resolve, reject) => {
            resolve({ data: { link: uploadImage } });
        });
    };

    const toggleCodeEditor = async () => {
        if (showCodeEditor) {
            // Switching to the rich text editor
            const blocksFromHTML = convertFromHTML(description);
            const contentState = ContentState.createFromBlockArray(blocksFromHTML);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        } else {
            // Switching to the textarea (code editor)
            const updatedDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setDescription(updatedDescription);
        }
        setShowCodeEditor(!showCodeEditor);
    };

    const handleChange = (event) => {
        const htmlString = event.target.value;
        setDescription(htmlString);
    };

    const validateHTMLSyntax = (htmlString) => {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            if (doc.documentElement.nodeName === 'parsererror') {
                setError('content',{message:'Invalid HTML syntax'});
            }

            // Clear the validation message if HTML is valid
            clearErrors('content');
        } catch (error) {
            // Handle parsing errors and set the validation message
            setError('content',{message:'Invalid HTML syntax'});
        }
    };


    return (
        <div className={'PageEdit'}>
        <h3 className="page-heading">Page Management</h3>
    <hr />
    <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={'PageEditForm mt-3'}>
        <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <Form.Group className="mb-3" controlId="name">
        <Form.Label>Page Title*</Form.Label>
    <Form.Control
    placeholder="Page Title"
    {...register('name')}
    type="text"
    disabled
    />
    {errors.name && (
            <Form.Text className="text-muted validationText hasError">
        {errors.name.message}
        </Form.Text>
)}
</Form.Group>
    </Col>
    </Row>
    <Row>
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Form.Group className="mb-3" controlId="content">
        <Form.Label>Description*</Form.Label>
        <div className={'ckeditor-container'}>
        {showCodeEditor ? (
                <textarea
                className={'codeEditor'} // Add a CSS class for the code editor
    value={description}
    onChange={(e) => handleChange(e)}
    />
) : (
    <Editor
    editorState={editorState}
    toolbarClassName="toolbarClassName"
    wrapperClassName="wrapperClassName"
    editorClassName="editorClassName"
    onEditorStateChange={onEditorStateChange}
    toolbar={{
        image: {
            uploadCallback: handleFileUpload,
                previewImage: true,
                crossOrigin: 'anonymous',
        },
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
    }}
    />
)}
</div>
    {errors.content && (
    <Form.Text className="text-muted validationText hasError">
        {errors.content.message}
        </Form.Text>
    )}
            {showCodeEditor && (
                <Form.Text className="text-muted">
                    Note: To save your content switch to rich text editor mode to see how your final content will appear. Rich text editor mode allows you to review your work, check formatting, and ensure everything looks just the way you want it before saving.
                </Form.Text>
            )}
</Form.Group>
    </Col>
        {!showCodeEditor &&
    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
        {loader ? (
                <div className="spin-loader">
                <FadeLoader color={'#EC1246'} height={10} />
    </div>
) : (

    <button type={'submit'} className={'btn btn-green-theme w-100 mt-3'}>
        Save Changes
    </button>
)}
</Col>}
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <button
                type={'button'}
                className={'btn btn-black-theme w-100 mt-3'}
                onClick={toggleCodeEditor}
            >
                {showCodeEditor ? 'Hide Code Editor' : 'Show Code Editor'}
            </button>
        </Col>

        </Row>
        </div>
        </Form>
        </div>
);
}

export default PageEdit;
