import React, { useLayoutEffect, useRef, useState } from 'react'
import { Form, Label, Button, InputGroup, FormControl } from 'react-bootstrap'
import logoIcon from '../../assets/images/logo-full.svg'
import { useForm } from "react-hook-form"
import { useHistory } from "react-router";
import { EmptyLocalStorage, ResetPasswordApi } from "../../services/auth/auth.service"
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FadeLoader } from "react-spinners"

function ResetPassword({ location }) {
    const history = useHistory();
    const [pass, TogglePass] = useState(true);
    const [confirmPass, ToggleConfirmPass] = useState(true);
    const [loader, setLoader] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useLayoutEffect(() => {
        if (!location.state?.email && !location.state?.verificationCode) {
            history.push('/reset-password');
        }
    }, [])

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        let email = location.state.email
        let otp_code = data.verificationCode
        let password = data.password
        let password_confirmation = data.Cpassword
        setLoader(true)
        await ResetPasswordApi(email, otp_code, password, password_confirmation).then((data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    return (
        <div className={"text-center authLayout"}>
            <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                    <img className="img-fluid mb-4" src={logoIcon} alt="Imprint Logo" title="Imprint Logo"
                        style={{ padding: "10px 25px" }} />
                </a>
                <h1 className="h5 mb-3 font-weight-normal">Reset Password</h1>
                <Form.Group className="mb-3">
                    <Form.Control
                        placeholder="Enter Verification Code"
                        {...register('verificationCode', {
                            required: {
                                value: "required",
                                message: "Verification code is required"
                            },
                            minLength: {
                                value: 4,
                                message: "Min length is 4"
                            },
                            maxLength: {
                                value: 4,
                                message: "Max length is 4"
                            },
                        })}
                        type="text"
                    />
                    {errors.verificationCode &&
                        <span className={"validation-error pr-5"} role="alert">{errors.verificationCode.message}</span>}
                    <div className={"pass-field"}>
                        <InputGroup controlId="passowrd">
                            <FormControl
                                placeholder="Enter New Password"
                                autoComplete={"off"}
                                {...register('password', {
                                    minLength: {
                                        value: 8,
                                        message: "Min length is 8"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    }
                                })}
                                type={pass ? "password" : "text"} />
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                {
                                    pass ? <FaEye /> : <FaEyeSlash />
                                }
                            </Button>
                        </InputGroup>
                        {errors.password &&
                            <span className={"validation-error pr-5"} role="alert">{errors.password.message}</span>}
                    </div>
                    <div className={"pass-field"}>
                        <InputGroup controlId="Cpassowrd">
                            <FormControl
                                placeholder="Confirm Password"
                                autoComplete={"off"}
                                {...register('Cpassword', {
                                    required: {
                                        value: "required",
                                        message: "Confirm password is required"
                                    },
                                    validate: value => value === password.current || "The passwords do not match",
                                    minLength: {
                                        value: 8,
                                        message: "Min length is 8"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    },
                                })}
                                type={confirmPass ? "password" : "text"} />
                            <Button variant="outline-secondary" id="button-addon2"
                                onClick={() => ToggleConfirmPass(!confirmPass)}>
                                {
                                    confirmPass ? <FaEye /> : <FaEyeSlash />
                                }
                            </Button>
                        </InputGroup>
                        {errors.Cpassword &&
                            <span className={"validation-error pr-5"} role="alert">{errors.Cpassword.message}</span>}
                    </div>
                </Form.Group>


                <div className="d-grid gap-2 loader_custom">
                    {loader ? <FadeLoader color={"#FFF"} height={10} /> :
                        <Button variant="primary" size="lg" type="submit" className={"btn-green-theme"}>
                            Reset Password
                        </Button>}
                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by Tekrevol 2022</p>
            </Form>
        </div>
    )
}

export default ResetPassword
