import React, { useState } from 'react'
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CreatePage } from "../../services/pages.service";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from 'react-spinners';
import {uploadToS3} from "../../utils/helpers";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, Entity ,convertToRaw, CompositeDecorator } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const ImageDecorator = new CompositeDecorator([
    {
        strategy: (contentBlock, callback) => {
            contentBlock.findEntityRanges(
                character => {
                    const entityKey = character.getEntity();
                    return (
                        entityKey !== null &&
                        Entity.get(entityKey).getType() === 'IMAGE'
                    );
                },
                callback
            );
        },
        component: props => {
            const { src } = Entity.get(props.entityKey).getData();
            return <img src={src} crossOrigin="anonymous" alt="Draft.js Image" />;
        },
    },
]);

function PageAdd() {
    let history = useHistory();

    const [editorState, setEditorState] = useState(EditorState.createEmpty(ImageDecorator));
    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList']
    };
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [loader, setLoader] = useState(false);

    const onModalSubmit = async (data) => {
        setLoader(true)
        await CreatePage({
            name: data.name,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        }).then((data) => {
            if (data.status) {
                setLoader(false)
                toast.success(data.message);
                history.push('/page-list');
            }
            else {
                setLoader(false)
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
        // const url = apiUrl[props['moduleName']]
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();

        // setValue(url == "faqs" ? "answer" : 'description', plainText );

    }

    const handleFileUpload = async (file) => {

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        let uploadImage = await uploadToS3(file.type,file.name,file,'article');
        console.log("[uploadImage]", uploadImage)

        if (!uploadImage) {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: uploadImage } });
            }
        );

    };


    return (
        <div className={"PageAdd"}>
            <h3 className="page-heading">Add New Page</h3>
            <hr />
            <Form onSubmit={handleSubmit(onModalSubmit)}>
                <div className={"PageAddForm mt-3"}>
                    <div className="">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Page Title*</Form.Label>
                                    <Form.Control
                                        placeholder="Page Title"
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Page Title is required"
                                            },
                                            minLength: {
                                                value: 5,
                                                message: "max length is 5"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className={""}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="content">
                                    <Form.Label>Description*</Form.Label>
                                    <Editor
                                        {...register('content')}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            image: {
                                                uploadCallback: handleFileUpload,
                                                previewImage: true,
                                                crossOrigin:'anonymous',
                                                // alt: { present: true, mandatory: false },
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                            },
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                {loader ?
                                    <div className="spin-loader">
                                        <FadeLoader color={"#EC1246"} height={10} />
                                    </div> :
                                    <button type={"submit"} className={"btn btn-green-theme w-100 mt-3"}>Save Changes</button>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}
export default PageAdd
