import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { CreateEquipment } from "../../services/equipments.service";
import { FadeLoader } from "react-spinners"
import { UploadImageToS3, uploadToS3 } from "../../utils/helpers";
import {cross_origin} from "../../utils/constants";

function AddNewEquipment(props) {
    let history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [weightKg, setWeightKg] = useState(0);
    const [weightLbs, setWeightLbs] = useState(0);
    const [refererDomain, setRefererDomain] = useState('');
    const [isUpload, setIsUpload] = useState(false)
    const [referalLinkImage, setReferalLinkImage] = useState('');

    //State handling storing of the video
    const [image, setImage] = useState('')
    const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const selectedMedia = (fileName, file) => {
        // console.log(file, 'file Name')
        clearErrors(fileName);
        setFormData({ ...formData, ...{ [fileName]: file } })
        setModalShow(false)
    }
    const onSubmit = async data => {
        setLoader(true)
        // if (!formData.image) {
        //     setError('image', { message: 'Image is required' });
        //     setLoader(false)
        //     return false;
        // }

        data.image = formData.image
        if (typeof referalLinkImage === "object") {
            let s3Data = await uploadToS3(referalLinkImage.type, referalLinkImage.name, referalLinkImage, "equipment")
            if (!s3Data) {
                toast.error("Uploading failed");
                setLoader(false)
                return false;
            }
            data.referral_link_image = s3Data
        } else {
            data.referral_link_image = ''
        }

        data.weight_kg = weightKg
        data.weight_lb = weightLbs
        await CreateEquipment(data).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/equipments')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const getBrand = (url) => {
        let domain = (new URL(url))
        let host = domain.hostname.replace('www.', '');
        // setRefererDomain();
        // console.log('hostname:', host);

        if (host.includes("amazon")) {
            setRefererDomain('amazon')
        } else if (host.includes("ebay")) {
            setRefererDomain('ebay')
        }
    }

    const handleWeightKg = event => {
        const kg = event.target.value;
        const lbs = kg * 2.20462262185;
        setWeightKg(kg);
        setWeightLbs(lbs.toFixed(2));

    };
    const handleWeightLbs = event => {
        const lbs = event.target.value;
        const kg = lbs / 2.20462262185;
        setWeightLbs(lbs);
        setWeightKg(kg.toFixed(2));

    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setReferalLinkImage(fileInput[0])
        setImage(fileUrl)
        setIsUpload(true)
    }

    return (
        <div className="AddNewEquipment">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Equipment</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Equipment Image</Form.Label>
                                    <button type='button'
                                        className='btn btn-user-theme'
                                        onClick={() => {
                                            setFileName("image")
                                            setModalShow(true)
                                        }}
                                    >
                                        Upload Equipment Image
                                    </button>
                                    {errors.image && <Form.Text
                                        className="text-muted validationText hasError">{errors.image.message}</Form.Text>}
                                    {formData.image ?
                                        <img src={formData.image} className={"img-table img-thumbnail"}
                                            crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="euipment_name">
                                    <Form.Label>Equipment Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Equipment Name"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Equipment Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="weightKgF">
                                    <Form.Label className='d-block'>Weight (kg)</Form.Label>
                                    <Form.Control
                                        placeholder="Weight (kg)"
                                        value={weightKg}
                                        {...register('weight_kg', {
                                            required: false,
                                            onChange: (e) => handleWeightKg(e),
                                        })} type="number"
                                    />
                                    {/*{errors.weight_kg && <Form.Text*/}
                                    {/*    className="text-muted validationText hasError">{errors.weight_kg.message}</Form.Text>}*/}

                                </Form.Group>
                                {/*<Form.Group className="mb-3" controlId="weightKgF">*/}
                                {/*    <Form.Label className='d-block'>Weight (kg)</Form.Label>*/}
                                {/*    <Form.Select className='formselect exercise-dropdown' aria-label="Weight kg" >*/}
                                {/*        <option value="0">None</option>*/}
                                {/*        <option value="1">2 Kg</option>*/}
                                {/*        <option value="2">4 Kg</option>*/}
                                {/*        <option value="3">6 Kg</option>*/}
                                {/*        <option value="4">8 Kg</option>*/}
                                {/*        <option value="5">10 Kg</option>*/}
                                {/*    </Form.Select>*/}
                                {/*</Form.Group>*/}
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="weightLbsF">
                                    <Form.Label className='d-block'>Weight (lbs)</Form.Label>
                                    <Form.Control
                                        placeholder="Weight (lbs)"
                                        value={weightLbs}
                                        {...register('weight_lb', {
                                            required: false,
                                            onChange: (e) => handleWeightLbs(e),
                                        })} type="number"
                                    />
                                    {/*{errors.weight_lb && <Form.Text*/}
                                    {/*    className="text-muted validationText hasError">{errors.weight_lb.message}</Form.Text>}*/}

                                </Form.Group>
                                {/*<Form.Group className="mb-3" controlId="weightLbsF">*/}
                                {/*    <Form.Label className='d-block'>Weight (lbs)</Form.Label>*/}
                                {/*    <Form.Select className='formselect exercise-dropdown' aria-label="Weight lbs">*/}
                                {/*        <option value="0">None</option>*/}
                                {/*        <option value="1">2 lbs</option>*/}
                                {/*        <option value="2">4 lbs</option>*/}
                                {/*        <option value="3">6 lbs</option>*/}
                                {/*        <option value="4">8 lbs</option>*/}
                                {/*        <option value="5">10 lbs</option>*/}
                                {/*    </Form.Select>*/}
                                {/*</Form.Group>*/}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="referalLink">
                                    <Form.Label className='d-block'>Referral
                                        Link <b>({refererDomain ? refererDomain : ''})</b></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Referral Link"

                                        {...register('referral_link', {
                                            onChange: (e) => {
                                                getBrand(e.target.value)
                                            },
                                        })} type="text"
                                    />
                                    {errors.referalLink && <Form.Text
                                        className="text-muted validationText hasError">{errors.referalLink.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Referral Link Image</Form.Label>
                                    <input
                                        {...register('referral_link_image')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        // className='btn btn-user-theme'
                                        id='referral_link_image'

                                    />
                                    {errors.referral_link_image && <Form.Text
                                        className="text-muted validationText hasError">{errors.referral_link_image.message}</Form.Text>}
                                    {isUpload ?
                                        <img src={image} className={"img-table img-thumbnail"}
                                            crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                </Form.Group>
                            </Col>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="equipmentdescription">
                                    <Form.Label>Equipment Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} {...register('description')} />
                                </Form.Group>
                            </Col>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="comments">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control className={"angelas-comments"} placeholder={"Angela’s comments"}
                                        as="textarea" rows={3} {...register('note')} />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Create Equipment"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} fileType={"images"} />}
                size={"xl"}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }} />
        </div>
    )
}

export default AddNewEquipment
