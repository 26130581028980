import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/imprint-styles.css';
import './assets/css/custom.css';
//import './assets/css/bodysolutions.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import './views/program/video/editor2.css'


export const muiCache = createCache({
    'key': 'mui',
    'prepend': true
})

ReactDOM.render(
    <React.StrictMode>
        <CacheProvider value={muiCache}>
            <App />
        </CacheProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
