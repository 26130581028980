import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import { constants, customStyles, GENDERS } from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { DeleteTests, GetTests } from "../../services/tests.service";
import { genders } from "../../utils/constants";
import ThemeModal from "../../components/ThemeModal";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { CreateSport, DeleteSport, GetSportById, GetSports, UpdateSport } from "../../services/sports.service";


function Tests(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState({ keyword: '', gender: '' });
    const [orderByValue, setOrderByValue] = useState(constants.ORDER_BY_VALUE);
    const [orderByColumn, setOrderByColumn] = useState(constants.ORDER_BY_COLUMN);

    useEffect(async () => {
        await getTestsFunction();
    }, [keyword.keyword, keyword.gender])

    // useEffect(async () => {
    //     await getEquipmentsFunction();
    // }, [])

    const getTestsFunction = async () => {
        setLoading(true)
        await GetTests(keyword.keyword, keyword.gender, page, perPage, 0).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];

                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            title: dt?.title,
                            gender: GENDERS[dt?.gender],
                            note: dt?.note,
                        });
                    })
                    setRows(rowData);
                    setLoading(false);

                }
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }


    const onEdit = (id) => {
        history.push('/edit-tests/' + id);
        // history.push('/tests');

    }
    const onView = (id) => {
        history.push('/view-test/' + id);
        // history.push('/view-test');

    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteTests(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getTestsFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const testsActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.title.toLowerCase();
        const b = rowB.title.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '50px',
            grow: false,
            sortable: true,
            selector: rowData => rowData.id
        },
        {
            id: 'title',
            name: 'Title',
            maxWidth: '240px',
            sortable: true,
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.title,
        },
        {
            id: 'gender',
            name: 'Gender',
            maxWidth: '240px',
            center: true,
            sortable: true,
            selector: rowData => rowData.gender,
        },
        {
            id: 'note',
            name: 'Note',
            // sortable: true,
            maxWidth: '240px',
            selector: rowData => rowData.note,
        },
        {
            field: 'action',
            name: 'Action',
            center: true,
            cell: rowData => testsActions(rowData.id),
        },
    ];

    const handleSort = async (column, sortDirection) => {
        setOrderByValue(sortDirection)
        setOrderByColumn(column.sortField)
    };

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Tests</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    if (e.target.value.length > 1) {
                                        setKeyword({ ...keyword, ['keyword']: e.target.value });
                                    } else {
                                        setKeyword({ ...keyword, ['keyword']: '' });
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Group className="mb-3" controlId="gender">
                            <Form.Select
                                placeholder="Select Gender"
                                onChange={(e) => {
                                    setKeyword({ ...keyword, ['gender']: e.target.value });
                                }}
                            >
                                <option key='blankChoice' hidden value>Gender</option>
                                <option value={0}>All</option>
                                <option value={genders.MALE}>Male</option>
                                <option value={genders.FEMALE}>Female</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Link to={"/add-new-tests"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                // onSort={handleSort}
                />
            </div>
        </div>
    )
}

export default Tests;