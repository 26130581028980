import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, FilterIcon, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import { constants, customStyles, genders } from "../../utils/constants";
import { GetCategories } from "../../services/categories.service";
import { GetSubCategories } from "../../services/sub_categories.service";
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { GetAuthUser, GetUserRole } from "../../services/auth/auth.service";
import { FaCheck, FaTimes } from 'react-icons/fa'
import { GetPrograms, DeleteProgram, DuplicateProgram } from '../../services/programs.service'


function ProgramLibrary(props) {
    let history = useHistory();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [filterCategory, setFilterCategory] = useState('');
    const [filterSubCategory, setFilterSubCategory] = useState('');
    const [bodyType, setBodyType] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [keyword, setKeyword] = useState('');
    const [orderByValue, setOrderByValue] = useState('asc');
    const [orderByColumn, setOrderByColumn] = useState('name');

    useEffect(async () => {
        await getPrograms();
    }, [keyword, bodyType, subCategory])

    const getPrograms = async () => {
        setLoading(true)
        await GetPrograms(keyword, '', page, perPage, 0, orderByValue, orderByColumn).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt.id,
                            name: dt.name,
                            injury: dt.injury ? dt.injury.name : '-',
                            bodyType: dt.bodyType ? dt.bodyType.name : '-',
                            category: dt.category ? dt.category.name : '-',
                            // isComplete: dt.is_complete ? isCompletedCheck(dt.is_complete) : <FaTimes />,
                            isDraft: dt.is_draft ? dt.is_draft == 1 ? 'Complete' : 'Incomplete' : 'Incomplete',
                            gender: dt.gender ? dt.gender === genders.MALE ? 'Male' : 'Female' : '',
                            daysPerWeek: dt.days_per_week ? dt.days_per_week : ''
                        });
                    })
                    setRows(rowData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                // toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(error.response.data.message);
        })
    }

    const onEdit = (data) => {
        data ?
            history.push('/edit-program/' + data.id)
            : toast.error('Something wrong. Please refresh page.');
    }

    const onDelete = (data) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Program?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeleteProgram(data.id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPrograms()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }
    
    const onDuplicate = (program) => {
        swal({
            title: 'Are you sure?',
            text: 'You want to duplicate this program?',
            icon: 'warning',
            content: {
              element: 'input',
              attributes: {
                placeholder: 'Enter program name',
                className: 'form-control',
                required: true,
              },
            },
            buttons: {
                close: {
                  text: 'Cancel',
                  value: {button:'cancel'},
                },
                confirm: {
                  text: 'Confirm'
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false
        }).then(async (willShip) => {
            if (willShip?.button !== 'cancel') {
                if(willShip){
                    const data = {
                        name: willShip
                    }
                    await DuplicateProgram(program.id, data).then((result) => {
                        if (result.status) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getPrograms()
                        } else {
                            toast.error(result.message);
                        }
                    }).catch((error) => {
                        toast.error(error.response.data.message.replace(/_/g, ' '));
                    })
                }
                else{
                    swal('Warning', 'Program name is required!', 'warning');
                }
            }
            
        });
    }

    const redirectToSummary = (data) => {
        history.push({
            pathname: '/manage-program/',
            state: {
                program: data,
                redirectToSummary: true
            },
        });
    }

    const programActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => redirectToSummary(data)}>Summary</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDuplicate(data)}>Duplicate</Dropdown.Item>
            </DropdownButton>
        )
    }

    const isDraftCheck = ({ isDraft }) => {
        if (isDraft === 1) {
            return 'Complete'
        } else {
            return 'Incomplete'
        }
    }

    const isCompletedCheck = ({ isCompleted }) => {
        if (isCompleted === 1) {
            return <FaCheck />
        } else {
            return <FaTimes />
        }
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveBodyTypeSort = (rowA, rowB) => {
        const a = rowA.bodyType.toLowerCase();
        const b = rowB.bodyType.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveCategorySort = (rowA, rowB) => {
        const a = rowA.category.toLowerCase();
        const b = rowB.category.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveInjurySort = (rowA, rowB) => {
        const a = rowA.injury.toLowerCase();
        const b = rowB.injury.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'name',
            name: 'Program Name',
            minWidth: '280px',
            style: {
                'div:first-child': {
                    whiteSpace: 'normal',
                }
            },
            sortable: true,
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.name,
        },
        {
            id: 'bodyType',
            name: 'Body Type',
            sortable: true,
            sortFunction: caseInsensitiveBodyTypeSort,
            selector: rowData => rowData.bodyType,
        },
        {
            id: 'category',
            name: 'Sub Category',
            sortable: true,
            sortFunction: caseInsensitiveCategorySort,
            selector: rowData => rowData.category,
        },
        {
            id: 'injury',
            name: 'Injury Program',
            sortable: true,
            sortFunction: caseInsensitiveInjurySort,
            selector: rowData => rowData.injury,
        },
        {
            id: 'gender',
            name: 'Gender',
            sortable: true,
            selector: rowData => rowData.gender,
        },
        {
            id: 'daysperweek',
            name: 'Days Per Week',
            sortable: true,
            selector: rowData => rowData.daysPerWeek,
        },
        {
            id: 'isDraft',
            name: 'Draft',
            sortable: true,
            selector: rowData => rowData.isDraft,
        },
        {
            field: 'action',
            name: 'Action',
            center: true,
            cell: rowData => programActions(rowData),
        },
    ];

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Program Management Library</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                {/* <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon/></InputGroup.Text>
                                    <Form.Select
                                        // onChange={e => {
                                        //     setCategory(e.target.value);
                                        //     getSubCategories(e.target.value);
                                        // }}
                                        aria-label="Default Filters">
                                        <option value="">Types</option>
                                        {
                                            filterCategory ?
                                                filterCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                }) : ''
                                        }
                                    </Form.Select>
                                </InputGroup> */}
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                {/* <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon/></InputGroup.Text>
                                    <Form.Select
                                        // onChange={e => {
                                        //     setSubCategory(e.target.value);
                                        // }}
                                        aria-label="Default Filters">
                                        <option value="">Gender</option>
                                        {
                                            filterSubCategory ?
                                                filterSubCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                })
                                                : ''
                                        }
                                    </Form.Select>
                                </InputGroup> */}
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/add-new-program"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    // paginationServer
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100, 150, 200, 250, 300]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default ProgramLibrary
