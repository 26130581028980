import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetInstructions = async (params) => {
    const response = await GET(apiUrl.instructions, params);
    return response;
}

export const CreateInstructions = async (data) => {
    const response = await POST(apiUrl.instructions, data);
    return response;
}

export const UpdateInstructions = async (id, data) => {
    const response = await PUT(apiUrl.instructions, id, data);
    return response;
}

export const DeleteInstructions = async (id) => {
    const response = await DELETE(apiUrl.instructions, id);
    return response;
}