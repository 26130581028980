import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CreateEquipment } from "../../services/equipments.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadImageToS3, uploadToS3 } from "../../utils/helpers";
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { CreateTests } from "../../services/tests.service";
import { genders } from "../../utils/constants";
import VideoInput from "../exercise/VideoInput";

function AddTest(props) {

    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [isUpload, setIsUpload] = useState(false)
    const [image, setImage] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [eductionalVideoUrl, setEducationalVideoUrl] = useState([])
    const [file, setFIle] = useState(null)
    const [eduFormValues, setEduFormValues] = useState([{ eductionalNote: "", eductionalNewVideo: "", isShow: true }])
    const [resetCoverViewer, setResetCoverViewer] = useState([false])

    /** Option function */
    const [optionFormValues, setOptionFormValues] = useState([{ option: "" }]);

    let handleOptionChange = (i, e) => {
        let newOptionFormValues = [...optionFormValues];
        newOptionFormValues[i]['option'] = e.target.value;
        setOptionFormValues(newOptionFormValues);
        console.log("[e.target.value]", e.target.value)
    }

    let addOptionFormFields = () => {
        setOptionFormValues([...optionFormValues, { option: "" }])
    }

    let removeOptionFormFields = (index) => {
        let rows = [...optionFormValues];
        rows.splice(index, 1);
        setTimeout(() =>
            setOptionFormValues(rows)
            , 1000);

        console.log("[rows]", rows)
        console.log("[optionFormValues]", optionFormValues)
    }

    /** Education video function */
    let handleEduNoteChange = (i, e) => {
        let newFormValues = [...eduFormValues];
        newFormValues[i][e.target.name] = e.target.value;
        setEduFormValues(newFormValues);
    }

    let handleEduVideoChange = (i, e) => {
        let newFormValues = [...eduFormValues];
        newFormValues[i]['eductionalNewVideo'] = e;
        setEduFormValues(newFormValues);

        let resetNewCoverViewer = [...resetCoverViewer];
        resetNewCoverViewer[i] = false;
        setResetCoverViewer(resetNewCoverViewer)
    }

    let addEduFormFields = () => {
        setEduFormValues([...eduFormValues, { eductionalNote: "", eductionalNewVideo: "", isShow: true }])
        setResetCoverViewer([...resetCoverViewer, 1])
    }

    let removeEduFormFields = (i) => {
        let newFormValues = [...eduFormValues];
        newFormValues[i]['eductionalNote'] = "";
        newFormValues[i]['eductionalNewVideo'] = "";
        newFormValues[i]['isShow'] = false;
        setEduFormValues(newFormValues)

        let resetNewCoverViewer = [...resetCoverViewer];
        resetNewCoverViewer[i] = true;
        setResetCoverViewer(resetNewCoverViewer)
    }

    const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const [fields, setFields] = useState([]);
    const [educationFields, setEducationFields] = useState([]);
    function handleAddField() {
        setFields(prevFields => [...prevFields, { option: "" }]);
    }

    function handleRemoveField(index) {
        setFields(prevFields => prevFields.filter((field, i) => i !== index));
    }
    function handleAddEducationalField() {
        setEducationFields(prevFields => [...prevFields, { education: "" }]);
    }

    function handleRemoveEducationalField(index) {
        setEducationFields(prevFields => prevFields.filter((field, i) => i !== index));
    }

    function handleFieldChange(index, event) {
        const newFields = [...fields];
        newFields[index][event.target.name] = event.target.value;
        setFields(newFields);
    }

    const onSubmit = async data => {
        setLoader(true)
        // console.log(data,"data")
        console.log(eduFormValues, "eduFormValues")
        console.log(optionFormValues, "optionFormValues")
        console.log(videoUrl, "videoUrl")

        let eduValuesArrObj = []
        eduFormValues.forEach((val) => {
            if (val.isShow === true) {
                eduValuesArrObj.push({
                    'educational_video': val.eductionalNewVideo,
                    'note': val.eductionalNote
                })
            }
        });

        let mainData = {
            'educational_videos': eduValuesArrObj,
            'feedback_options': optionFormValues,
            'gender': data.gender,
            'note': data.note,
            'title': data.title,
            'video': videoUrl
        };

        await CreateTests(mainData).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/tests')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const setRemoveVideoFunc = data => {
        setVideoUrl(null)
    }

    const setRemoveEdVideoFunc = (i, e) => {
        let newFormValues = [...eduFormValues];
        newFormValues[i]['eductionalNewVideo'] = null;
        setEduFormValues(newFormValues);
    }

    return (
        <div className="AddNewTest">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Test</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-divider">
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group controlId="video">
                                        <Form.Label className='d-block'>Video</Form.Label>
                                        <VideoInput width={300} height={200} videoUrl={setVideoUrl} onCloseRemoveData={setRemoveVideoFunc}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group className="mb-3" controlId="title">
                                        <Form.Label>Title*</Form.Label>
                                        <Form.Control
                                            placeholder="Enter Title"
                                            {...register('title', {
                                                required: {
                                                    value: "required",
                                                    message: "Title is required"
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Min length is 3"
                                                },
                                                maxLength: {
                                                    value: 80,
                                                    message: "Max length is 80"
                                                },
                                            })} type="text"
                                        />
                                        {errors.title && <Form.Text
                                            className="text-muted validationText hasError">{errors.title.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="gender">
                                        <Form.Label>Gender*</Form.Label>
                                        <Form.Select
                                            placeholder="Select Gender"
                                            {...register("gender", {
                                                required: {
                                                    value: "required",
                                                    message: "gender is required"
                                                }
                                            }
                                            )}
                                        >
                                            <option selected disabled value={"Select Gender"}>Select Gender</option>
                                            <option value={genders.FEMALE}>Female</option>
                                            <option value={genders.MALE}>Male</option>
                                        </Form.Select>
                                        {errors.gender && <Form.Text
                                            className="text-muted validationText hasError">{errors.gender.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                                <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group controlId="note">
                                        <Form.Label>Note*</Form.Label>
                                        <Form.Control as="textarea" rows={4} {...register('note', {
                                            required: {
                                                value: "required",
                                                message: "Note is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 450,
                                                message: "Max length is 450"
                                            },
                                        })} />
                                        {errors.note && <Form.Text
                                            className="text-muted validationText hasError">{errors.note.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <div className="card-divider">
                            <h3 className="test-heading">Educational Videos</h3>
                            {eduFormValues.map((eduElement, eduindex) => (
                                eduElement.isShow &&
                                <div key={eduindex}>
                                    <hr className="border-up" />
                                    <Row className={"mt-3"}>

                                        <Col className="mt-3" xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Form.Group controlId="note">
                                                <Form.Label>Note</Form.Label>
                                                <Form.Control as="textarea"
                                                    onChange={e => handleEduNoteChange(eduindex, e)}
                                                    rows={4}
                                                    name="eductionalNote"
                                                    value={eduElement.eductionalNote}
                                                />

                                            </Form.Group>
                                        </Col>

                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            {
                                                eduindex > 0 ?
                                                    <button className={"btn-cross"} onClick={() => removeEduFormFields(eduindex)} type="button">X</button>
                                                    : null
                                            }
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                            <Form.Group controlId="video">
                                                <Form.Label className='d-block'>Educational Video</Form.Label>
                                                <VideoInput
                                                    width={300}
                                                    height={200}
                                                    register={register}
                                                    videoUrl={(e) => handleEduVideoChange(eduindex, e)}
                                                    oldcover={eduElement.eductionalNewVideo ? eduElement.eductionalNewVideo : ''}
                                                    resetImageViewer={resetCoverViewer[eduindex]}
                                                    onCloseRemoveData={(e) => setRemoveEdVideoFunc(eduindex, e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Row>
                                <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <button onClick={() => addEduFormFields()} className={"btn btn-green-theme mt-3 mb-3"} type="button">Add More</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-divider">
                            <h3 className="test-heading">Feedback Questions</h3>
                            <hr />

                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    {optionFormValues.map((field, optionIndex) => (
                                        <div key={optionIndex}>
                                            <Form.Group className="mb-3" controlId="option" >
                                                <div className="wrapup">
                                                    <Form.Label>Option</Form.Label>
                                                    {optionIndex > 0 ?
                                                        <button className={"btn-cross"} onClick={() => removeOptionFormFields(optionIndex)} type="button">X</button>
                                                        : null
                                                    }
                                                </div>
                                                <Form.Control
                                                    value={field.option}
                                                    placeholder="Enter Option"
                                                    name='option'
                                                    type="text"
                                                    minLength={3}
                                                    maxLength={80}
                                                    onChange={
                                                        (e) => handleOptionChange(optionIndex, e)
                                                    }
                                                    required={true}
                                                />
                                                {errors.option && errors.option.type === "required" && (
                                                    <p>This field is required</p>
                                                )}
                                                {errors.option && <Form.Text
                                                    className="text-muted validationText hasError">{errors.option.message}</Form.Text>}

                                            </Form.Group>


                                        </div>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <button onClick={() => addOptionFormFields()} className={"btn btn-green-theme mt-3 mb-3"} type="button">Add More</button>
                                </Col>
                            </Row>

                        </div>
                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Create Test"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddTest;