import React, { useEffect, useState } from "react";
import '../../assets/css/imprint-styles.css'
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import VideoContainer from "./VideoContainer";
import ReactDOM from "react-dom";

function MultipleVideosInput({ width, height, setBodyTypeVideos, bodyTypeVideos, videosLimit }) {
  // const { width, height } = props;

  const inputRef = React.useRef();
  const [modalShow, setModalShow] = useState(false);
  const [buttonShow, setButtonShow] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [source, setSource] = React.useState();


  useEffect(() => {
    setButtonShow(bodyTypeVideos?.length < videosLimit)
    addBodyTypeVideo()
  }, [bodyTypeVideos]);

  const removeVideoFunc = async (videoKey) => {
    bodyTypeVideos.splice(videoKey, 1);
    await setBodyTypeVideos(bodyTypeVideos);
    addBodyTypeVideo();
    setButtonShow(bodyTypeVideos?.length < videosLimit);
  }

  const pushNewBodyTypeVideo = async (data) => {
    if (data !== undefined && data !== null && data !== '') {
      const newArray = [...bodyTypeVideos];
      
      const videoObj = {
        video: data
      }
      newArray.push(videoObj);
      await setBodyTypeVideos(newArray);
    }
  };

  const addBodyTypeVideo = async () => {
    let vidInnerSec = document.getElementById('vidInnerSection')
    if(vidInnerSec)
      vidInnerSec.remove();

    let videoSection = document.getElementById('vidSection');
    let container = document.createElement('div');
    container.setAttribute('id', 'vidInnerSection');

    if (videoSection && container) {
      ReactDOM.render(<VideoContainer bodyTypeVideos={bodyTypeVideos} removeVideoFunc={removeVideoFunc} />, container);
      videoSection.append(container)
    }
    
  }


  const selectedMedia = (fileName, file) => {
    setFormData({ ...formData, ...{ [fileName]: file } })
    setSource(file)
    pushNewBodyTypeVideo(file)
    setModalShow(false)
  }

  return (
    <div className="MultipleVideosInput">

      <button type='button'
        style={{ display: buttonShow ? 'block' : 'none' }}
        className='btn btn-user-theme mb-2'
        onClick={() => {
          setFileName("image")
          setModalShow(true)
        }}
      >
        Upload Video
      </button>

      <div className={"vidSection"} id={"vidSection"}></div>

      <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} fileType={'videos'} />}
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }} />
    </div>
  );
}


export default MultipleVideosInput