import React, { useEffect, useState } from 'react'
import { Route, Redirect } from "react-router-dom"
import Header from '../components/Header'
import SideBar from '../components/SideBar'
import { Container, Row, Col } from 'react-bootstrap'
import { EmptyLocalStorage, GetAuthUser, GetToken } from '../services/auth/auth.service'
import UserContext from "../hooks/UserContext";
import { GetProfile } from "../services/users.service";
import { toast } from "react-toastify";
import { updateUser } from "../services/firebase.service";
import { useHistory } from 'react-router-dom';

function DashboardLayout({ children }) {
    const history = useHistory()
    const [user, setUser] = useState('Profile');
    const [changeUser, setChangeUser] = useState(true);

    useEffect(() => {
        //getProfile();
    }, [])

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    let dt = result.data?.profile;
                    if (dt) {
                        await setUser(dt.full_name)
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            return toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }

    return (
        <div>
            <div className="dashboard_layout_wrapper">
                <UserContext.Provider value={user}>
                    <Header />
                </UserContext.Provider>
                <Container fluid className={"p-0"}>
                    <Row>
                        <aside
                            className={"col-12 col-sm-12 col-md-3 col-lg-3  col-xl-3 col-xxl-2 d-none d-lg-block sidebar-nav  sidebar-padding"}>
                            <SideBar />
                        </aside>
                        <main className={"col-12 col-sm-12 col-md-12 col-lg-9  col-xl-9 col-xxl-10"}>
                            <div className={"main_content_wrapper"}>
                                {children}
                            </div>
                        </main>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const DashboardLayoutRoute = ({ isAuth, component: Component, ...rest }) => {
    isAuth = Boolean(GetToken());
    // isAuth = true;
    return (
        <>
            <Route
                {...rest}
                render={(matchProps) =>
                    isAuth ? (
                        <DashboardLayout>
                            <Component {...matchProps} />
                        </DashboardLayout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: matchProps.location },
                            }}
                        />
                    )
                }
            />
        </>
    );
};

export default DashboardLayoutRoute
