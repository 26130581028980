import React, { useState, useEffect, useLayoutEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import ProfileIcon from '../../assets/images/profile-icon.png'
import { AiOutlineEdit } from 'react-icons/ai'
import { toast } from "react-toastify";
import { GetProfile } from "../../services/users.service";
import { constants } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from 'react-router-dom';

const initialData = {
    id: '',
    full_name: '',
    email: '',
    phone: '',
    image: constants.USERIMAGENOTFOUND,
}

function ViewProfile() {
    const history = useHistory();
    const [data, setData] = useState(initialData);

    useEffect(() => {
        getProfile();
    }, [])

    useLayoutEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.status) {
                if (result.data?.profile) {
                    let dt = result.data?.profile;
                    if (dt) {
                        await setData(
                            {
                                id: dt.id,
                                full_name: dt.full_name,
                                email: dt.email,
                                phone: dt.phone,
                                image: dt?.profile_picture?.mediumImage || constants.USERIMAGENOTFOUND,
                            }
                        )
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">View Profile</h3>
            <hr />
            <div className={"profileCard"}>
                <div className={"profileCardHeader"}>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div className={"profileImg mx-auto text-center"}>
                                <img onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.USERIMAGENOTFOUND;
                                }} src={data?.image} alt={"Profile Image"} className={"img-fluid"} />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                            <div className={"profileDetails"}>
                                <h4>{data?.full_name}</h4>
                                <p><b>Email:</b> {data?.email}</p>
                                <p><b>Phone:</b> {data?.phone}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <NavLink className={"floating-link"} to={{ pathname: "/edit-profile", state: data }}>
                    <AiOutlineEdit />
                </NavLink>
            </div>
        </div>
    )
}

export default ViewProfile
