import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FaChartPie } from "react-icons/fa";
import DashboardStat from '../../../components/DashboardStat'
import { GetDashboard, GetDashboardSummary } from "../../../services/admin/dashboard.service"
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom"
import { constants } from "../../../utils/constants";
import { EmptyLocalStorage } from "../../../services/auth/auth.service";
import { useHistory } from 'react-router-dom';
import Profile01 from '../../../assets/images/profile.jpg'
import { ChevronRight } from "../../../components/SvgIcons";

function Admin({ RoleId }) {
    const history = useHistory();
    const [stats, setStats] = useState('');

    const [dashboardStat, setDashboardStat] = useState([]);
    useEffect(() => {
        const getDashboardSummary = async () => {
            await GetDashboardSummary().then((data) => {
                if (data.status) {
                    setDashboardStat(data.data);
                }
            }).catch((error) => {
                         if (error.response.status == 401) {
                             EmptyLocalStorage()
                             history.push('/');
                         } else {
                             return toast.error(error.response.data.message.replace(/_/g, ' '));
                         }
                     })
        }
        getDashboardSummary()
    }, [])

    // useEffect(async () => {
    //     let params = {role_id: RoleId}
    //     await GetDashboard(params).then((data) => {
    //         if (data.status) {
    //             setStats({
    //                 orders_count: data.data.orders_count,
    //                 confirmed_orders_count: data.data.confirmed_orders_count,
    //                 cancelled_orders_count: data.data.cancelled_orders_count,
    //                 paid_orders_count: data.data.paid_orders_count,
    //                 shipped_orders_count: data.data.shipped_orders_count,
    //                 completed_orders_count: data.data.completed_orders_count,
    //                 reviewed_orders_count: data.data.reviewed_orders_count,
    //                 returned_orders_count: data.data.returned_orders_count,
    //                 products_count: data.data.products_count,
    //                 csa_agents_count: data.data.csa_agents_count,
    //                 vendors_count: data.data.vendors_count,
    //                 ads_count: data.data.ads_count,
    //             })
    //         } else {
    //             toast.error(data.message.replace(/_/g, ' '));
    //         }
    //     }).catch((error) => {
    //         if (error.response.status == 401) {
    //             EmptyLocalStorage()
    //             history.push('/');
    //         } else {
    //             return toast.error(error.response.data.message.replace(/_/g, ' '));
    //         }
    //     })
    // }, [])

    return (
        <div className='dashboard'>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    {/* <h1>Dashboard</h1> */}
                </Col>
                {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className={"timelineControls"}>
                        <p>Interval</p>
                        <Dropdown className={"intervalDropdown"}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Current month
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">This week</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Today</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Last month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </Col> */}
            </Row>
            <Row className={"mb-4"}>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/manage-users"} className={"linkStats"}>
                        <DashboardStat title={"Total Users"} countMain={dashboardStat[0] ? dashboardStat[0].totalUsers : 0} />
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/program-library"} className={"linkStats"}>
                        <DashboardStat title={"Total Programs"} countMain={dashboardStat[0] ? dashboardStat[0].totalPrograms : 0} />
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/exercises"} className={"linkStats"}>
                        <DashboardStat title={"Exercises"} countMain={dashboardStat[0] ? dashboardStat[0].totalExercise : 0} />
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/tests"} className={"linkStats"}>
                        <DashboardStat title={"Tests"} countMain={dashboardStat[0] ? dashboardStat[0].totalTests : 0} />
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/equipments"} className={"linkStats"}>
                        <DashboardStat title={"Equipment"} countMain={dashboardStat[0] ? dashboardStat[0].totalEquipments : 0} />
                    </NavLink>
                </Col>
                {/* <Col xs={12} sm={12} md={6} lg={4} xl={4} className={"mb-4"}>
                    <NavLink to={"/payment"} className={"linkStats"}>
                        <DashboardStat title={"Payments"} countMain={dashboardStat[0]? dashboardStat[0].totalUsers : 0}/>
                    </NavLink>
                </Col> */}
            </Row>
        </div>
    )
}

export default Admin
