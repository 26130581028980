import React, { useEffect, useRef, useState } from "react";
import {
    Form,
    Row,
    Col
} from "react-bootstrap";
import { TrashIcon } from "../../components/SvgIcons";
import { FiSettings } from "react-icons/fi"
import { AiFillEye } from "react-icons/ai"
import ThemeModal from "../../components/ThemeModal";
import russianTwist from "../../assets/images/russian-twist-1.jpg";
import obliques from "../../assets/images/obliques-m.PNG";
import { constants, genders } from "../../utils/constants";
import VideoPlayer from "./VideoPlayer";
import { Link } from "react-router-dom";

export default function DayWiseExercise({
    dragged,
    onDragEnterFunc,
    onDragOverFunc,
    onDropFunc,
    setWeightFunc,
    removeExerciseVideo,
    dayExerciseData,
    setFeelItOverlayFunc,
    setTextOverlayPosFunc,
    setFeelImageShowFunc,
    setTextOverlayShowFunc,
    swapArray,
    swapDragged,
    isRestComponent,
    setIsRestComponent,
}) {
    const [data, setData] = useState([null, null, null, null, null, null, null, null, null,null,null,null]);
    const [index, setIndex] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [detailModalShow, setDetailModalShow] = useState(false);
    const [feelImageShow, setFeelImageShow] = useState(false);
    const [position, setPosition] = useState(null);
    const [overlayTextShow, setOverlayTextShow] = useState(false);
    const [overlayPos, setOverlayPos] = useState(null);
    const [content, setContent] = useState(null);
    const dayWiseContainerRef = useRef();

    useEffect(() => {
        if (isRestComponent && isRestComponent == true) {
            setIsRestComponent(false)
            if (dayWiseContainerRef.current) {
                dayWiseContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [isRestComponent])


    useEffect(() => {
        if (dayExerciseData && dayExerciseData.length > 0) {
            setData(dayExerciseData)
        } else {
            setData([])
        }
    }, [dayExerciseData])

    const CheckPosImage = (feelPos) => {
        if (feelPos === "TR") {
            return "top-right";
        }
        else if (feelPos === "TL") {
            return "top-left";
        }
        else if (feelPos === "BL") {
            return "bottom-left";
        }
        else if (feelPos === "BR") {
            return "bottom-right";
        }
        else {
            return "";
        }
    }

    const CheckPosText = (overlayPos) => {
        // if (overlayPos === "RO") {
        //     return "top-right";
        // }
        // else if (overlayPos === "LO") {
        //     return "top-left";
        // }
        // else if (overlayPos === "CE") {
        //     return "center";
        // }
        // else {
        //     return "";
        // }
        if (overlayPos === "TR") {
            return "top-right";
        }
        else if (overlayPos === "TL") {
            return "top-left";
        }
        else if (overlayPos === "BL") {
            return "bottom-left";
        }
        else if (overlayPos === "BR") {
            return "bottom-right";
        }
        else {
            return "";
        }
    }

    const setFeelItPositionFunc = (n, imageShow) => {
        setPosition(n)
        setFeelItOverlayFunc({
            'index': index,
            'position': n,
            'show': imageShow
        })
    }

    const setTextPositionFunc = (n, textShow) => {
        setOverlayPos(n)
        setTextOverlayPosFunc({
            'index': index,
            'position': n,
            'show': textShow
        })
    }

    const setFeelImageShowFunction = (val) => {
        setFeelImageShow(val)
        setFeelImageShowFunc({
            'index': index,
            'show': val
        })
    }

    const setTextPositionShowFunction = (val) => {
        setOverlayTextShow(val)
        setTextOverlayShowFunc({
            'index': index,
            'show': val
        })
    }


    const TruncatedText = ({ text, limit }) => {
        const [truncatedText, setTruncatedText] = useState(text);

        useEffect(() => {
            if (text) {
                const words = text.trim().split(' ');
                if (words.length > limit) {
                    setTruncatedText(words.slice(0, limit).join(' ') + '...');
                } else {
                    setTruncatedText(text);
                }
            }
        }, [text, limit]);

        return <div>{truncatedText}</div>;
    };



    const ImgPlacement = ({ content }) => {
        const [data, setData] = useState([]);
        const [imageShow, setImageShow] = useState(false);
        const [textShow, setTextShow] = useState(false);
        useEffect(() => {
            if (content && content != null) {
                setData(content)
                setImageShow(content.feel_it_show)
                setTextShow(content.text_show)
            } else {
                setData([])
            }
        }, [content])

        const setFeelImageShowFunc = (val) => {
            setImageShow(val)
            setFeelImageShowFunction(val)
        }

        const setTextPositionShowFunc = (val) => {
            setTextShow(val)
            setTextPositionShowFunction(val)
        }

        return (
            <div className={"modalFeelItHere"}>
                <div className={"image-view-container"}>
                    <img className={`${data && data.position == 2 ? 'img-fluid parentImg flip-video' : 'img-fluid parentImg'}`} crossOrigin="anonymous" src={data && data.exerciseLevel ? data.exerciseLevel.video_cover : russianTwist} onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = constants.IMAGENOTFOUND;
                    }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                    {
                        data && data.feel_it_show && <img className={`img-fluid feel-it-here ${CheckPosImage(data && data.feel_it_position ? data.feel_it_position : '')}`} crossOrigin="anonymous" src={data && data.exerciseLevel && data.exerciseLevel.body_part ? data.exerciseLevel.body_part.male_image : obliques} onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = constants.IMAGENOTFOUND;
                        }} alt={"Feel IT"} title={"Feel It Here Image"} />
                    }
                    {/* {
                        data && data.text_show && data.text_position === "BO" ? <>
                            <p className={"text-placement top-left"}>{`${checkOrientationText(data && data.position ? data.position : '')}`}</p> <p className={"text-placement top-right"}>{`${checkOrientationText(data && data.position ? data.position : '')}`}</p>
                        </> : ""
                    } */}
                    {
                        data && data.text_show && data.text_position !== "BO" ? <p className={`text-placement ${CheckPosText(data.text_position)}`}>{`${checkOrientationText(data && data.position ? data.position : '')}`}</p> : ""
                    }
                    {/*<p className={"text-placement top-right"}>right side only</p>*/}
                </div>
                <div className={"mt-3"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Check
                                label="Enable Feel It here Image"
                                name="feelItHere"
                                checked={imageShow}
                                type={"checkbox"}
                                id={`feelItHere`}
                                onChange={() => setFeelImageShowFunc(!imageShow)}
                            />
                            <Form.Group className={`mb-3 ${imageShow || (data && data.feel_it_show) ? "d-block" : "d-none"}`}>
                                <Form.Label className='d-block mt-3'>Feel it here image Position:</Form.Label>
                                <Form.Select className='formselect exercise-dropdown' id="position" aria-label="Feel it here position"
                                    onChange={(e) => {
                                        setFeelItPositionFunc(e.target.value, imageShow);
                                    }}>
                                    <option disabled selected value=''>Select Position</option>
                                    <option value="TL" selected={(data && data.feel_it_position == 'TL') ? true : false}>Top Left</option>
                                    <option value="TR" selected={(data && data.feel_it_position == 'TR') ? true : false}>Top Right </option>
                                    <option value="BR" selected={(data && data.feel_it_position == 'BR') ? true : false}>Bottom Right</option>
                                    <option value="BL" selected={(data && data.feel_it_position == 'BL') ? true : false}>Bottom Left</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Check
                                label="Enable Text Overlay"
                                name="overlayText"
                                checked={textShow}
                                type={"checkbox"}
                                id={`overlayText`}
                                onChange={() => setTextPositionShowFunc(!textShow)}
                            />
                            <Form.Group className={`mb-3 ${textShow || (data && data.text_show) ? "d-block" : "d-none"}`} controlId="overlayTextPosition">
                                <Form.Label className='d-block mt-3'>Overlay Text Position:</Form.Label>
                                <Form.Select defaultValue={overlayPos} className='formselect exercise-dropdown' aria-label="Feel it here position"
                                    onChange={(e) => {
                                        setTextPositionFunc(e.target.value, textShow);
                                    }}>
                                    <option disabled selected value=''>Select Position</option>
                                    <option value="TL" selected={(data && data.text_position == 'TL') ? true : false}>Top Left</option>
                                    <option value="TR" selected={(data && data.text_position == 'TR') ? true : false}>Top Right </option>
                                    <option value="BR" selected={(data && data.text_position == 'BR') ? true : false}>Bottom Right</option>
                                    <option value="BL" selected={(data && data.text_position == 'BL') ? true : false}>Bottom Left</option>

                                    {/* <option value="RO" selected={(data && data.text_position == 'RO') ? true : false}>Right Side Only</option>
                                    <option value="LO" selected={(data && data.text_position == 'LO') ? true : false}>Left Side Only</option>
                                    <option value="BO" selected={(data && data.text_position == 'BO') ? true : false}>Both Sides</option>
                                    <option value="CE" selected={(data && data.text_position == 'CE') ? true : false}>Center</option> */}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    const ExerciseDetail = ({ content }) => {
        const [data, setData] = useState([]);
        const [level1Data, setLevel1Data] = useState([]);
        const [level2Data, setLevel2Data] = useState([]);
        const [level3Data, setLevel3Data] = useState([]);

        useEffect(() => {
            if (content && content != null) {
                setData(content.exerciseLevels ? content.exerciseLevels : content.exercise.exerciseLevels)
            } else {
                setData([])
            }
        }, [content])

        useEffect(() => {
            if (data && data != null && data.length > 0) {
                let level1 = []
                let level2 = []
                let level3 = []
                data.forEach((element) => {
                    if (element.level_id === 1) {
                        level1.push(element)
                    } else if (element.level_id === 2) {
                        level2.push(element)
                    } else if (element.level_id === 3) {
                        level3.push(element)
                    }
                })
                setLevel1Data(level1)
                setLevel2Data(level2)
                setLevel3Data(level3)
            } else {
                setLevel1Data([])
                setLevel2Data([])
                setLevel3Data([])
            }
        }, [data])
        return (
            <div className={"modalFeelItHere"}>
                <div className={"mt-3"}>
                    <Row>
                        {
                            level1Data &&
                            level1Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin="anonymous" src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className={"mt-3"}>
                        {
                            level2Data &&
                            level2Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin="anonymous" src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Row className={"mt-3"}>
                        {
                            level3Data &&
                            level3Data.map((exercise, index) => (
                                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={index}>
                                    <div className="detail-card border-round">
                                        <div className="heading">
                                            {exercise.equipment_exercise_type == 0 ? 'Level ' + exercise.level_id : 'Level ' + exercise.level_id + ' Equipment ' + exercise.equipment_exercise_type} {exercise.gender == genders.MALE ? 'M' : 'F'}
                                        </div>
                                        <img className={"img-fluid detail-img"} crossOrigin="anonymous" src={exercise.video_cover ? exercise.video_cover : russianTwist} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} alt={"Exercise Cover"} title={"Exercise Cover Image"} />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        )
    }

    const setModalShowFunc = (n, content) => {
        setContent(content)
        setIndex(n)
        setModalShow(true)
    }

    const clearModalShowFunc = () => {
        setOverlayTextShow(false)
        setFeelImageShow(false)
        setContent(null)
        setFeelImageShow(false)
        setPosition(null)
        setOverlayTextShow(false);
        setOverlayPos(null)
        setModalShow(false)
        setDetailModalShow(false)
    }

    const detailModalShowFunc = (n, content) => {
        setContent(content)
        setDetailModalShow(true)
    }

    const detailModalHideFunc = () => {
        setContent(null)
        setDetailModalShow(false)
    }

    const checkOrientationText = (n) => {
        if (n == 1) {
            return "Left Side Only";
        }
        else if (n == 2) {
            return "Right Side Only";
        }
        // else if (n == 3) {
        //     return "Both Sides";
        // } 
        else {
            return "";
        }
    }

    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [draggedItem, setDraggedItem] = useState(null);
    // const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

    const handleDragStart = (e, index, data) => {
        setDraggedItem(data)
        setDraggedItemIndex(index);
        swapDragged(data)
    };

    const handleDragEnd = () => {
        setDraggedItemIndex(null);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
    };

    const handleDropOver = (e, index, data) => {
        e.preventDefault();
        let tempSource = draggedItem
        let tempDestination = data
        tempSource.exercise_no = index + 1
        tempDestination.exercise_no = draggedItemIndex + 1
        let swap = []
        swap.tempSource = tempSource
        swap.indexSource = draggedItemIndex
        swap.tempDestination = tempDestination
        swap.indexDestination = index
        swapArray(swap)
        setDraggedItem(null)
        setDraggedItemIndex(null);
    };

    const handleDelete = (index) => {
        removeExerciseVideo(index)
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };


    return (
        <>
            <Row ref={dayWiseContainerRef}>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 01</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[0] !== undefined && data[0] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 0, data[0])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 0)}
                                                    onDrop={(e) => handleDropOver(e, 0, data[0])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(1, data[0])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[0]} OrientationVideoFlip={data[0] && data[0].position ? data[0].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(0)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[0]?.exerciseLevel && data[0]?.exerciseLevel.exercise ? data[0]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[0]?.exerciseLevel && data[0]?.exerciseLevel.exercise ? data[0]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[0]?.exerciseLevel && data[0]?.exerciseLevel.exercise ? data[0]?.exerciseLevel.exercise.name : ''} limit={5} />


                                                            </Link>
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(1, data[0])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_1"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-1'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-1'}
                            data-exercise_count={"excercise_1"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[0] !== undefined && data[0] !== null && data[0].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[0] !== undefined && data[0] !== null && data[0].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[0] !== undefined && data[0] !== null && data[0].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[0] !== undefined && data[0] !== null && data[0].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[0] !== undefined && data[0] !== null && data[0].position == 4) ? true : false}>Center</option>
                        </Form.Select>

                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 02</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[1] !== undefined && data[1] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 1, data[1])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 1)}
                                                    onDrop={(e) => handleDropOver(e, 1, data[1])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(2, data[1])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[1]} OrientationVideoFlip={data[1] && data[1].position ? data[1].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(1)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[1]?.exerciseLevel && data[1]?.exerciseLevel.exercise ? data[1]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[1]?.exerciseLevel && data[1]?.exerciseLevel.exercise ? data[1]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[1]?.exerciseLevel && data[1]?.exerciseLevel.exercise ? data[1]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(2, data[1])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_2"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-2'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-2'}
                            data-exercise_count={"excercise_2"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[1] !== undefined && data[1] !== null && data[1].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[1] !== undefined && data[1] !== null && data[1].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[1] !== undefined && data[1] !== null && data[1].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[1] !== undefined && data[1] !== null && data[1].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[1] !== undefined && data[1] !== null && data[1].position == 4) ? true : false}>Center</option>
                        </Form.Select>

                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 03</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[2] !== undefined && data[2] !== null ?
                                                <div className="VideoInput"
                                                    draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 2, data[2])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 2)}
                                                    onDrop={(e) => handleDropOver(e, 2, data[2])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(3, data[2])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[2]} OrientationVideoFlip={data[2] && data[2].position ? data[2].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(2)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[2]?.exerciseLevel && data[2]?.exerciseLevel.exercise ? data[2]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[2]?.exerciseLevel && data[2]?.exerciseLevel.exercise ? data[2]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[2]?.exerciseLevel && data[2]?.exerciseLevel.exercise ? data[2]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(3, data[2])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_3"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-3'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-3'}
                            data-exercise_count={"excercise_3"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[2] !== undefined && data[2] !== null && data[2].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[2] !== undefined && data[2] !== null && data[2].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[2] !== undefined && data[2] !== null && data[2].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[2] !== undefined && data[2] !== null && data[2].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[2] !== undefined && data[2] !== null && data[2].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 04</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[3] !== undefined && data[3] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 3, data[3])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 3)}
                                                    onDrop={(e) => handleDropOver(e, 3, data[3])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(4, data[3])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[3]} OrientationVideoFlip={data[3] && data[3].position ? data[3].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(3)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[3]?.exerciseLevel && data[3]?.exerciseLevel.exercise ? data[3]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[3]?.exerciseLevel && data[3]?.exerciseLevel.exercise ? data[3]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[3]?.exerciseLevel && data[3]?.exerciseLevel.exercise ? data[3]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(4, data[3])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_4"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-4'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-4'}
                            data-exercise_count={"excercise_4"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[3] !== undefined && data[3] !== null && data[3].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[3] !== undefined && data[3] !== null && data[3].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[3] !== undefined && data[3] !== null && data[3].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[3] !== undefined && data[3] !== null && data[3].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[3] !== undefined && data[3] !== null && data[3].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 05</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[4] !== undefined && data[4] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 4, data[4])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 4)}
                                                    onDrop={(e) => handleDropOver(e, 4, data[4])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(5, data[4])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[4]} OrientationVideoFlip={data[4] && data[4].position ? data[4].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(4)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[4]?.exerciseLevel && data[4]?.exerciseLevel.exercise ? data[4]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[4]?.exerciseLevel && data[4]?.exerciseLevel.exercise ? data[4]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[4]?.exerciseLevel && data[4]?.exerciseLevel.exercise ? data[4]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(5, data[4])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_5"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-5'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-5'}
                            data-exercise_count={"excercise_5"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[4] !== undefined && data[4] !== null && data[4].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[4] !== undefined && data[4] !== null && data[4].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[4] !== undefined && data[4] !== null && data[4].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[4] !== undefined && data[4] !== null && data[4].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[4] !== undefined && data[4] !== null && data[4].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 06</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[5] !== undefined && data[5] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 5, data[5])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 5)}
                                                    onDrop={(e) => handleDropOver(e, 5, data[5])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(6, data[5])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[5]} OrientationVideoFlip={data[5] && data[5].position ? data[5].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(5)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[5]?.exerciseLevel && data[5]?.exerciseLevel.exercise ? data[5]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[5]?.exerciseLevel && data[5]?.exerciseLevel.exercise ? data[5]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[5]?.exerciseLevel && data[5]?.exerciseLevel.exercise ? data[5]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(6, data[5])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_6"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-6'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-6'}
                            data-exercise_count={"excercise_6"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[5] !== undefined && data[5] !== null && data[5].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[5] !== undefined && data[5] !== null && data[5].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[5] !== undefined && data[5] !== null && data[5].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[5] !== undefined && data[5] !== null && data[5].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[5] !== undefined && data[5] !== null && data[5].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 07</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[6] !== undefined && data[6] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 6, data[6])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 6)}
                                                    onDrop={(e) => handleDropOver(e, 6, data[6])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(7, data[6])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[6]} OrientationVideoFlip={data[6] && data[6].position ? data[6].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(6)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[6]?.exerciseLevel && data[6]?.exerciseLevel.exercise ? data[6]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[6]?.exerciseLevel && data[6]?.exerciseLevel.exercise ? data[6]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[6]?.exerciseLevel && data[6]?.exerciseLevel.exercise ? data[6]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(7, data[6])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_7"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-7'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-7'}
                            data-exercise_count={"excercise_7"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[6] !== undefined && data[6] !== null && data[6].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[6] !== undefined && data[6] !== null && data[6].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[6] !== undefined && data[6] !== null && data[6].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[6] !== undefined && data[6] !== null && data[6].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[6] !== undefined && data[6] !== null && data[6].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 08</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[7] !== undefined && data[7] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 7, data[7])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 7)}
                                                    onDrop={(e) => handleDropOver(e, 7, data[7])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(8, data[7])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[7]} OrientationVideoFlip={data[7] && data[7].position ? data[7].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(7)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[7]?.exerciseLevel && data[7]?.exerciseLevel.exercise ? data[7]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[7]?.exerciseLevel && data[7]?.exerciseLevel.exercise ? data[7]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[7]?.exerciseLevel && data[7]?.exerciseLevel.exercise ? data[7]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(8, data[7])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_8"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-8'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-8'}
                            data-exercise_count={"excercise_8"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[7] !== undefined && data[7] !== null && data[7].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[7] !== undefined && data[7] !== null && data[7].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[7] !== undefined && data[7] !== null && data[7].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[7] !== undefined && data[7] !== null && data[7].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[7] !== undefined && data[7] !== null && data[7].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 09</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[8] !== undefined && data[8] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 8, data[8])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 8)}
                                                    onDrop={(e) => handleDropOver(e, 8, data[8])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(9, data[8])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[8]} OrientationVideoFlip={data[8] && data[8].position ? data[8].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(8)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[8]?.exerciseLevel && data[8]?.exerciseLevel.exercise ? data[8]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[8]?.exerciseLevel && data[8]?.exerciseLevel.exercise ? data[8]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[8]?.exerciseLevel && data[8]?.exerciseLevel.exercise ? data[8]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(9, data[8])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_9"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-9'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-9'}
                            data-exercise_count={"excercise_9"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[8] !== undefined && data[8] !== null && data[8].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[8] !== undefined && data[8] !== null && data[8].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[8] !== undefined && data[8] !== null && data[8].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[8] !== undefined && data[8] !== null && data[8].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[8] !== undefined && data[8] !== null && data[8].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 10</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[9] !== undefined && data[9] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 9, data[9])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 9)}
                                                    onDrop={(e) => handleDropOver(e, 9, data[9])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(10, data[9])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[9]} OrientationVideoFlip={data[9] && data[9].position ? data[9].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(9)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[9]?.exerciseLevel && data[9]?.exerciseLevel.exercise ? data[9]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[9]?.exerciseLevel && data[9]?.exerciseLevel.exercise ? data[9]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[9]?.exerciseLevel && data[9]?.exerciseLevel.exercise ? data[9]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(10, data[9])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_10"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-10'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-10'}
                            data-exercise_count={"excercise_10"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[9] !== undefined && data[9] !== null && data[9].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[9] !== undefined && data[9] !== null && data[9].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[9] !== undefined && data[9] !== null && data[9].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[9] !== undefined && data[9] !== null && data[9].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[9] !== undefined && data[9] !== null && data[9].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 11</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[10] !== undefined && data[10] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 10, data[10])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 10)}
                                                    onDrop={(e) => handleDropOver(e, 10, data[10])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(11, data[10])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[10]} OrientationVideoFlip={data[10] && data[10].position ? data[10].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(10)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[10]?.exerciseLevel && data[10]?.exerciseLevel.exercise ? data[10]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[10]?.exerciseLevel && data[10]?.exerciseLevel.exercise ? data[10]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[10]?.exerciseLevel && data[10]?.exerciseLevel.exercise ? data[10]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(11, data[10])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_11"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-11'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-11'}
                            data-exercise_count={"excercise_11"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[10] !== undefined && data[10] !== null && data[10].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[10] !== undefined && data[10] !== null && data[10].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[10] !== undefined && data[10] !== null && data[10].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[10] !== undefined && data[10] !== null && data[10].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[10] !== undefined && data[10] !== null && data[10].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form.Group className="mb-3 pe-1 ps-1"  >
                        <Form.Label className="d-block m-0">Exercise 12</Form.Label>
                        <section className="main-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="drag">
                                        <div className="wrapper">
                                            {data && data[11] !== undefined && data[11] !== null ?
                                                <div className="VideoInput" draggable="true"
                                                    onDragStart={(e) => handleDragStart(e, 11, data[11])}
                                                    onDragEnd={handleDragEnd}
                                                    onDragOver={(e) => handleDragOver(e, 11)}
                                                    onDrop={(e) => handleDropOver(e, 11, data[11])}
                                                >
                                                    <div className={"vidContainer"}>
                                                        <span className={"vidDetailBtn"} onClick={() => detailModalShowFunc(12, data[11])}>
                                                            <AiFillEye />
                                                        </span>
                                                        <VideoPlayer videoData={data[11]} OrientationVideoFlip={data[11] && data[11].position ? data[11].position : ''} />
                                                        <span className={"vidDeleteBtn"} onClick={() => handleDelete(11)}>
                                                            <TrashIcon />
                                                        </span>
                                                    </div>
                                                    <div className="vid-info-container">
                                                        <div className="vid-info">
                                                            <Link to={{
                                                                pathname: `/edit-exercise-steps/${data[11]?.exerciseLevel && data[11]?.exerciseLevel.exercise ? data[11]?.exerciseLevel.exercise.id : ''}`,
                                                                state: { exercise: data[11]?.exerciseLevel && data[11]?.exerciseLevel.exercise ? data[11]?.exerciseLevel.exercise : undefined }
                                                            }} target="_blank">
                                                                <TruncatedText text={data[11]?.exerciseLevel && data[11]?.exerciseLevel.exercise ? data[11]?.exerciseLevel.exercise.name : ''} limit={5} />
                                                            </Link>
                                                            {/*<a href="#!">{data[11]?.exerciseLevel && data[11]?.exerciseLevel.exercise ? data[11]?.exerciseLevel.exercise.name : ''}</a>*/}
                                                            {/* <p>Added by Author</p> */}
                                                        </div>
                                                        <button className="setting" onClick={() => setModalShowFunc(12, data[11])}><FiSettings /></button>
                                                    </div>
                                                </div> :
                                                <ul className="dropzone"
                                                    data-exercise_count={"excercise_12"}
                                                    onDragEnter={(e) => onDragEnterFunc(e)}
                                                    onDragOver={(e) => onDragOverFunc(e)}
                                                    onDrop={(e) => onDropFunc(e)}
                                                    id={'Exercise-12'}></ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Form.Select
                            className="formselect program-dropdown"
                            aria-label="Weight"
                            id={'Weight-12'}
                            data-exercise_count={"excercise_12"}
                            onChange={(e) => setWeightFunc(e)}
                        >
                            <option value="" selected={(data && data[11] !== undefined && data[11] !== null && data[11].position == null) ? true : false}>Select Orientation</option>
                            <option value="1" selected={(data && data[11] !== undefined && data[11] !== null && data[11].position == 1) ? true : false}>Left Side Only</option>
                            <option value="2" selected={(data && data[11] !== undefined && data[11] !== null && data[11].position == 2) ? true : false}>Right Side Only</option>
                            <option value="3" selected={(data && data[11] !== undefined && data[11] !== null && data[11].position == 3) ? true : false}>Both Sides</option>
                            <option value="4" selected={(data && data[11] !== undefined && data[11] !== null && data[11].position == 4) ? true : false}>Center</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <ThemeModal
                title={content && content.exerciseLevel && content.exerciseLevel.exercise ? content.exerciseLevel.exercise.name : ''}
                content={<ImgPlacement content={content} />} size={"md"}
                show={modalShow}
                onHide={() => {
                    clearModalShowFunc()
                }}
            />
            <ThemeModal
                title={content && content.exerciseLevel && content.exerciseLevel.exercise ? content.exerciseLevel.exercise.name : ''}
                content={<ExerciseDetail content={content} />} size={"lg"}
                show={detailModalShow}
                onHide={() => {
                    detailModalHideFunc()
                }}
            />
        </>
    );
}