import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row } from "react-bootstrap";
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import {constants, cross_origin, customStyles, STATUS} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import {Link, NavLink, useHistory} from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { DeleteMusclesAnatomy, GetMusclesAnatomy } from "../../services/muscles_anatomy_library.service";


function MusclesAnatomy(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getMusclesAnatomyFunction();
    }, [keyword])

    // useEffect(async () => {
    //     await getEquipmentsFunction();
    // }, [])
    function htmlToPlainText(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    const getMusclesAnatomyFunction = async () => {
        setLoading(true)
        await GetMusclesAnatomy(keyword, page, perPage, 0).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            name: dt?.name,
                            title: dt?.title,
                            image: dt?.image,
                            description:htmlToPlainText(dt?.description),
                            status: STATUS[dt?.status],

                        });
                    })
                    setRows(rowData);
                    setLoading(false);

                }
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }


    const onEdit = (id) => {
        history.push('/edit-muscles-anatomy/' + id);

    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteMusclesAnatomy(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getMusclesAnatomyFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const musclesAnatomyActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveTitleSort = (rowA, rowB) => {
        const a = rowA.title.toLowerCase();
        const b = rowB.title.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '50px',
            // grow: false,
            sortable: true,
            selector: rowData => rowData.id
        },
        {
            id: 'image',
            name: 'Image',
            maxWidth: '60px',
            selector: rowData => rowData.oid,
            format: rowData => <img src={rowData.image}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.IMAGENOTFOUND;
                }}
                className={"img-table img-thumbnail"} alt={"Icon"} crossOrigin={cross_origin ? "anonymous" : ''} />
        },
        {
            id: 'title',
            name: 'Title',
            maxWidth: '160px',
            sortable: true,
            sortFunction: caseInsensitiveTitleSort,
            selector: rowData => rowData.title,
        },
        {
            id: 'description',
            name: 'Description',
            maxWidth: '180px',
            // sortable: true,
            selector: rowData => rowData.description,
        },
        {
            field: 'action',
            name: 'Action',
            center: true,
            cell: rowData => musclesAnatomyActions(rowData.id),
        },
    ];


    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Muscles Library</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/add-new-muscles-anatomy"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default MusclesAnatomy;