import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { constants, customStyles, STATUS, STATUS_VALUE } from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import imgTemp from "../../assets/images/step01.png"
import { useForm } from "react-hook-form";
import { CreateEquipment } from "../../services/equipments.service";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { CreateSport, DeleteSport, GetSportById, GetSports } from "../../services/sports.service";
import {
    CreateCategory,
    DeleteCategory,
    GetCategories,
    GetCategoryById,
    UpdateCategory
} from "../../services/categories.service";
import Select from "react-select";



function SubCategory() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [subCategoryId, setSubCategoryId] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [parentCate, setParentCate] = useState();
    const [formData, setFormData] = useState({});
    const [selectCategory, setSelectCategory] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);

    const [loader, setLoader] = useState(false);

    const { register, watch, reset, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm({
        mode: "onBlur",
    });



    useEffect(async () => {
        await getCategoryFunction();
        await getParentCateListing();
    }, [keyword])

    // useEffect(async () => {
    //     await getCategoryFunction();
    //     await getParentCateListing();
    // }, [])

    const getCategoryFunction = async () => {
        setLoading(true)
        await GetCategories(keyword, page, perPage, 0, '', '', '').then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            name: dt?.name,
                            parent_name: dt?.parent?.name || '-',
                            parent_id: dt?.parent_id ? dt?.parent?.name : '-',
                        });
                    })
                    setRows(rowData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }


    const getParentCateListing = async () => {
        await GetCategories('', '', '', 0, 1, STATUS_VALUE.ACTIVE, '', 'asc', 'name').then((result) => {
            if (result.status) {
                if (result.data) {
                    const data = []
                    result.data.map((dt) => {
                        data.push({
                            value: dt?.id,
                            label: dt?.name,
                            parent_id: dt?.parent_id ? dt?.parent_id : '-',
                        });
                    })

                    setParentCate(data)
                }
            }
        })

    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteCategory(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getCategoryFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }
    const onEdit = (id) => {
        setSubCategoryId(id)
        setShowEditModal(true)
        GetCategoryById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setFormData(result.data)
                    setValue("name", result.data.name)
                    setValue("parent_id", result.data?.parent_id)
                    setSelectedCategoryId(result.data?.parent_id)
                    setSelectedCategoryName(result.data?.parent?.name)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.message.replace(/_/g, ' '));
        })
    }

    const contactActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveParentCategoryNameSort = (rowA, rowB) => {
        const a = rowA.parent_name.toLowerCase();
        const b = rowB.parent_name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveParentSort = (rowA, rowB) => {
        const a = rowA.parent_id.toLowerCase();
        const b = rowB.parent_id.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '50px',
            grow: false,
            sortable: true,
            selector: rowData => rowData.id
        },
        // {
        //     id: 'subCategory',
        //     name: 'Your Body Type',
        //     sortFunction: caseInsensitiveParentCategoryNameSort,
        //     sortable: true,
        //     selector: rowData => rowData.parent_name ?? '-',
        // },
        {
            id: 'category',
            name: 'Sub Category',
            sortFunction: caseInsensitiveNameSort,
            sortable: true,
            selector: rowData => rowData.name,
        },
        {
            field: 'action',
            name: 'Action',
            width: '80px',
            center: true,
            style: {
                paddingRight: '10px',
            },
            cell: rowData => contactActions(rowData.id),
        },
    ];

    const EditModal = (props) => {
        const [checked, setChecked] = useState(false);
        const [loader, setLoader] = useState(false);
        const [selectCategory, setSelectCategory] = useState(null);

        const selectedCategoryEdit = () => {
            let setSelectedCategory = []
            setSelectedCategory.push({ value: props.selectedCategoryId, label: props.selectedCategoryName })
            setSelectCategory(setSelectedCategory)
        }

        useEffect(() => {
            selectedCategoryEdit()
        }, [])

        const onEditSubmit = async data => {

            if (selectCategory) {
                clearErrors('parent_id')
            } else {
                setError('parent_id', { message: "Parent name is required" })
                return false
            }

            setLoader(true)
            await UpdateCategory(subCategoryId, {
                name: data.name,
                parent_id: selectCategory?.value
            }).then(async (data) => {
                setLoader(false)
                if (data.status) {
                    toast.success(data.message);
                    setShowEditModal(false)
                    getCategoryFunction()
                    getParentCateListing();
                    reset({
                        name: '',
                        parent_id: ''
                    })
                } else {
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        };
        // console.log('EditModal', props.selectedCategoryId)

        const onCategoryChange = (item) => {
            setSelectCategory(item)
            if (selectCategory) {
                clearErrors('parent_id')
            }
        }

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onEditSubmit)}>
                    {/*<Form.Check*/}
                    {/*    className={"mb-3"}*/}
                    {/*    inline*/}
                    {/*    label="Edit Parent Category"*/}
                    {/*    name="group1"*/}
                    {/*    type="checkbox"*/}
                    {/*    id="inline-checkbox-1"*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    {/*{checked ?  <Form.Group className={"mb-3 "} id={'parent-category'} controlId="bodyType">*/}
                    {/*        <Form.Label>Parent Category</Form.Label>*/}
                    {/*        <Form.Control type={"text"} placeholder={"Parent Category"} />*/}
                    {/*    </Form.Group> :*/}
                    {/*    <>*/}
                    {/*{ selectedCategoryId && (*/}
                    {/*    <Form.Group className={"mb-3 sub-category"} controlId="bodyType">*/}
                    {/*        <Form.Label>Parent Category*</Form.Label>*/}
                    {/*        /!* <Form.Select className='form-select exercise-dropdown' aria-label="parent Category"*/}
                    {/*            {...register("parent_id", {*/}
                    {/*                required: {*/}
                    {/*                    value: "required",*/}
                    {/*                    message: "Parent Name is required"*/}
                    {/*                },*/}
                    {/*                onChange: (e) => onCategoryChange(e),*/}
                    {/*            }*/}
                    {/*            )}>*/}
                    {/*            {parentCate && parentCate.map((data, index) => (*/}
                    {/*                <option value={data.id} key={index} >{data.name}</option>*/}
                    {/*            ))}*/}
                    {/*        </Form.Select> *!/*/}
                    {/*        <Select*/}
                    {/*            {...register("parent_id"*/}
                    {/*            )}*/}
                    {/*            onChange={onCategoryChange}*/}
                    {/*            options={parentCate}*/}
                    {/*            className="basic-multi-select"*/}
                    {/*            classNamePrefix="select"*/}
                    {/*            value={selectCategory ? selectCategory : ''}*/}
                    {/*        />*/}
                    {/*        {errors.parent_id && <Form.Text*/}
                    {/*            className=" validationText hasError">{errors.parent_id.message}</Form.Text>}*/}
                    {/*    </Form.Group>*/}
                    {/*)}*/}

                    <Form.Group className={"mb-3 sub-sub-category"} controlId="bodyType">
                        <Form.Label>Sub Category*</Form.Label>
                        <Form.Control {...register('name', {
                            required: {
                                value: "required",
                                message: "Sub Category is required"
                            },

                        })} type={"text"} placeholder={"Sub Category"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">{errors.name.message}</Form.Text>}
                    </Form.Group>
                    {/*</>}*/}
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"save"} />}
                </Form>
            </div>
        )
    };

    useEffect(() => {
        reset({
            name: '',
            parent_id: ''
        })
    }, [showAddModal,showEditModal])

    const AddModal = () => {
        const [checked, setChecked] = useState(false);
        const [loader, setLoader] = useState(false);
        const [selectCategory, setSelectCategory] = useState(null);
        const { register, watch, reset, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm({
            mode: "onBlur",
        });

        const onSubmitCategory = async data => {
            if (!checked) {
                if (selectCategory) {
                    clearErrors('parent_id')
                } else {
                    setError('parent_id', { message: "Parent name is required" })
                    return false
                }
            }


            setLoader(true)
            await CreateCategory({
                name: data.name,
                parent_id: selectCategory?.value ?? null,
            }).then(async (data) => {
                setLoader(false)
                if (data.status) {
                    toast.success(data.message);
                    setShowAddModal(false)
                    // getCategoryFunction()
                    // getParentCateListing();
                    reset({
                        name: '',
                        parent_id: ''
                    })
                    getCategoryFunction();
                    getParentCateListing();
                } else {
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        };

        const handleChange = () => {
            setChecked(!checked);
            // reset({
            //     name:'',
            //     parent_id:''
            // })
        };

        const onCategoryChange = (item) => {
            setSelectCategory(item)
            if (selectCategory) {
                clearErrors('parent_id')
            }
        }

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onSubmitCategory)}>
                    {/*<Form.Check*/}
                    {/*    value={checked}*/}
                    {/*    className={"mb-3"}*/}
                    {/*    inline*/}
                    {/*    label="Add Parent Category"*/}
                    {/*    name="group1"*/}
                    {/*    type="checkbox"*/}
                    {/*    id="inline-checkbox-1"*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    {checked ? <Form.Group className={"mb-3 "} id={'parent-category'} controlId="bodyType">
                        <Form.Label>Parent Category*</Form.Label>
                        <Form.Control  {...register("name", {
                            required: {
                                value: "required",
                                message: "Parent Name is required "
                            },
                        }
                        )} type={"text"} placeholder={"Parent Category"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">Parent Name is required</Form.Text>}

                    </Form.Group> :
                        <>
                            {/*<Form.Group className={"mb-3 sub-category"} controlId="bodyType">*/}
                            {/*    <Form.Label>Parent Category*</Form.Label>*/}
                            {/*    /!* <Form.Select className='form-select exercise-dropdown' aria-label="parent Category"*/}
                            {/*        {...register("parent_id", {*/}
                            {/*            required: {*/}
                            {/*                value: "required",*/}
                            {/*                message: "Parent Name is required"*/}
                            {/*            },*/}
                            {/*            onChange: (e) => onCategoryChange(e),*/}
                            {/*        }*/}
                            {/*        )} >*/}
                            {/*        <option value="" selected={true} disabled={true}>Select Parent Category</option>*/}

                            {/*        {parentCate && parentCate.map((data, index) => (*/}
                            {/*            <option value={data.id} key={index} >{data.name}</option>*/}
                            {/*        ))}*/}



                            {/*    </Form.Select> *!/*/}
                            {/*    <Select*/}
                            {/*        {...register("parent_id"*/}
                            {/*        )}*/}
                            {/*        onChange={onCategoryChange}*/}
                            {/*        options={parentCate}*/}
                            {/*        value={selectCategory}*/}
                            {/*        className="basic-multi-select"*/}
                            {/*        classNamePrefix="select"*/}
                            {/*    />*/}
                            {/*    {errors.parent_id && <Form.Text*/}
                            {/*        className="mt-1  validationText hasError">{errors.parent_id.message}</Form.Text>}*/}
                            {/*</Form.Group>*/}
                            <Form.Group className={"mb-3 sub-category"} controlId="bodyType">
                                <Form.Label>Sub Category*</Form.Label>
                                <Form.Control {...register('name', {
                                    required: {
                                        value: "required",
                                        message: "Sub Category is required "
                                    },

                                })} type={"text"} placeholder={"Sub Category"} />
                                {errors.name && <Form.Text
                                    className="mt-1  validationText hasError">Sub Category is required</Form.Text>}
                            </Form.Group>
                        </>}



                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"Add New Sub Category"} />
                    }
                </Form>
            </div>
        )
    };

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Sub Categories</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setShowAddModal(true)}>
                                    <AddIconOutline /> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    // data={rows}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />

                <ThemeModal title={"Add New SubCategory"} show={showAddModal} onHide={() => setShowAddModal(false)} size={"md"} content={<AddModal />} className={"sub-cat-modal"} />
                <ThemeModal title={"Edit Category"} show={showEditModal} onHide={() => setShowEditModal(false)} size={"md"} content={<EditModal selectedCategoryId={selectedCategoryId} selectedCategoryName={selectedCategoryName} />} className={"sub-cat-modal"} />
            </div>
        </div>
    )
}

export default SubCategory
