import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row, Form } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import { constants, customStyles, GENDERS, IS_VERIFIED } from "../../utils/constants";
import { DeleteUser, GetUsers } from "../../services/users.service";
import DataTable from 'react-data-table-component';
import Export from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import moment from "moment";
import { ageCalculate } from "../../utils/helpers";
import { useForm } from "react-hook-form";
import { GetSports } from '../../services/sports.service'
import { GetPrograms } from '../../services/programs.service'

function Users() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [exportRows, setExportRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [verified, setVerified] = useState('');

    const [sports, setSports] = useState([]);
    const [programs, setPrograms] = useState([]);

    const [sport, setSport] = useState('');
    const [program, setProgram] = useState('');

    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(exportRows[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    const getSports = async () => {
        await GetSports(null, null, null, 0, 10, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setSports(result.data);
            }
        })
    }

    const getPrograms = async () => {
        await GetPrograms(null, null, null, null, 0, 'asc', 'name').then((result) => {
            if (result.status && result.data) {
                setPrograms(result.data);
            }
        })
    }

    const getUsers = async () => {
        setLoading(true)
        await GetUsers(keyword, constants.ROLES.ROLE_USER, page, perPage, verified, sport, program, 0).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt, index) => {
                        let indexNum = ++index
                        rowData.push({
                            id: dt.id,
                            index: indexNum,
                            full_name: dt.name || '-',
                            email: dt.email || '-',
                            phone: dt.phone || '-',
                            gender: GENDERS[dt?.userDetail?.gender] || '-',
                            age: dt?.userDetail?.dob ? ageCalculate(dt?.userDetail?.dob) : '-',
                            sports: dt.userSportsCSV ? dt.userSportsCSV : '-',
                            programs: dt.userProgramCSV ? dt.userProgramCSV : '-',
                            is_verified: IS_VERIFIED[dt?.is_verified],
                            created_at: dt.created_at,
                            start_date: dt.start_date,
                        });

                        exportData.push({
                            // no: indexNum,
                            name: dt.name || '-',
                            email: dt.email || '-',
                            // phone: dt.phone || '-',
                            gender: GENDERS[dt?.userDetail?.gender] || '-',
                            age: dt?.userDetail?.dob ? ageCalculate(dt?.userDetail?.dob) : '-',
                            sports: dt.userSportsCSV ? dt.userSportsCSV : '-',
                            programs: dt.userProgramCSV ? dt.userProgramCSV : '-',
                            is_verified: IS_VERIFIED[dt?.is_verified],
                            // created_at: dt.created_at,
                            start_date: dt.start_date,
                        });
                    })
                    setRows(rowData);
                    setExportRows(exportData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    useEffect(() => {
        getSports()
        getPrograms()
    }, [])

    useEffect(async () => {
        getUsers();
    }, [keyword, verified, sport, program])

    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteUser(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getUsers()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message.replace(/_/g, ' '));
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/manage-users/edit-user/' + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push('/manage-users/view-user/' + data.id, {
            data: data
        });
    }
    const onReportView = (data) => {
        history.push('/survey-report/' + data.id, {
            data: data
        });
    }
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = 'users-export.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onReportView(data)}>Survey Report</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {

        const a = rowA.full_name.toLowerCase();
        const b = rowB.full_name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveEmailSort = (rowA, rowB) => {

        const a = rowA.email.toLowerCase();
        const b = rowB.email.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveSportsSort = (rowA, rowB) => {

        const a = rowA.sports.toLowerCase();
        const b = rowB.sports.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveProgramsSort = (rowA, rowB) => {

        const a = rowA.programs.toLowerCase();
        const b = rowB.programs.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };


    const insensitiveDateSort = (rowA, rowB) => {
        const a = new Date(rowA.start_date).getTime();
        const b = new Date(rowB.start_date).getTime();
        return a - b;
    };

    const caseAgeSort = (a, b) => {
        const ageA = a.age === '-' ? Number.MAX_VALUE : parseInt(a.age);
        const ageB = b.age === '-' ? Number.MAX_VALUE : parseInt(b.age);

        if (ageA < ageB) {
            return -1;
        }
        if (ageA > ageB) {
            return 1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'full_name',
            name: 'Name',
            maxWidth: '160px',
            sortable: true,
            sortField: 'name',
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.full_name,
        },
        {
            id: 'email',
            name: 'Email',
            minWidth: '180px',
            sortable: true,
            sortFunction: caseInsensitiveEmailSort,
            sortField: 'email',
            selector: rowData => rowData.email,

        },
        {
            id: 'gender',
            name: 'Gender',
            maxWidth: '80px',
            minWidth: '80px',
            grow: false,
            center: true,
            sortable: true,
            sortField: 'gender',
            selector: rowData => rowData.gender,
        },
        {
            id: 'age',
            name: 'Age',
            maxWidth: '80px',
            minWidth: '60px',
            sortable: true,
            sortFunction: caseAgeSort,
            center: true,
            selector: rowData => rowData.age,
        },
        {
            id: 'sports',
            name: 'sports',
            maxWidth: '220px',
            sortable: true,
            sortFunction: caseInsensitiveSportsSort,
            selector: rowData => rowData.sports,
        },
        {
            id: 'programs',
            name: 'programs',
            maxWidth: '220px',
            sortable: true,
            sortFunction: caseInsensitiveProgramsSort,
            selector: rowData => rowData.programs,
        },
        {
            id: 'verify',
            name: 'Is Verified',
            sortable: true,
            sortField: 'is_verified',
            selector: rowData => rowData.is_verified,
        },
        {
            id: 'createdAt',
            name: 'Start Date',
            sortable: true,
            selector: rowData => rowData.start_date,
            sortFunction: insensitiveDateSort,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Users</h1>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={{ span: 2, offset: 2 }} lg={{ span: 2, offset: 2 }} xl={{ span: 2, offset: 2 }}>
                        <Button className={"btn btn-green-theme h40 w-100"} onClick={() => downloadCSV(exportRows)}>Export CSV</Button>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/manage-users/add-user"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Form.Group className={"mb-3"} controlId="verified">
                                    <Form.Select className='formselect filter' aria-label="verified" onChange={e => setVerified(e.target.value)}>
                                        <option key='blankChoice' hidden value>Is Verified</option>
                                        <option value=''>All</option>
                                        <option value="0">Not Verified</option>
                                        <option value="1">Verified</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Form.Group className={"mb-3"} controlId="sport">
                                    <Form.Select className='formselect filter' aria-label="sport" onChange={e => setSport(e.target.value)}>
                                        <option key='blankChoice' hidden value>Sports</option>
                                        <option value=''>All</option>
                                        {
                                            sports &&
                                            sports.map((sport, index) => (
                                                <option value={sport.id}>{sport.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Form.Group className={"mb-3"} controlId="program">
                                    <Form.Select className='formselect filter' aria-label="program" onChange={e => setProgram(e.target.value)}>
                                        <option key='blankChoice' hidden value>Programs</option>
                                        <option value=''>All</option>
                                        {
                                            programs &&
                                            programs.map((program, index) => (
                                                <option value={program.id}>{program.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    // paginationServer
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    // onSort={handleSort}
                    // sortServer
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'}
                    />}
                />
            </div>
        </div>
    )
}

export default Users
