import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CreateEquipment } from "../../services/equipments.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadImageToS3, uploadToS3 } from "../../utils/helpers";
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { CreateTestimonials } from "../../services/testimonials.service";
import {cross_origin, GENDERS} from "../../utils/constants";
import VideoInput from "../exercise/VideoInput";
import { GetTestsById } from "../../services/tests.service";


function ViewTest(props) {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);

    const { handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const getTestsById = async (id) => {
        await GetTestsById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setData({
                        id: result.data.id,
                        title: result.data.title,
                        note: result.data.note,
                        video: result.data.video,
                        gender: result.data.gender,
                        feedback_option: result.data.testFeedbackOption.length > 0 ? result.data.testFeedbackOption : [],
                        educational_videos: result.data.testEducationalVideos.length > 0 ? result.data.testEducationalVideos : []
                    });
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/tests');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    useEffect(async () => {
        let id = props.match.params.id;
        await getTestsById(id);
    }, []);


    const onSubmit = async data => {
        setLoader(true)
        history.push('/tests');
    };

    return (
        <div className="ViewTest">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">View Test</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-divider">
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group controlId="video">
                                        <Form.Label className='d-block'>Video</Form.Label>
                                        <video
                                            className="VideoInput_video"
                                            controls
                                            src={data.video}
                                            style={{ width: 150, height: 150 }}
                                            crossOrigin={cross_origin ? "anonymous" : null}
                                        />

                                    </Form.Group>
                                </Col>


                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group className="mb-3" controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <p>{data.title}</p>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="gender">
                                        <Form.Label>Gender</Form.Label>
                                        <p>{GENDERS[data.gender]}</p>
                                    </Form.Group>
                                </Col>

                                <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group controlId="note">
                                        <Form.Label>Note</Form.Label>
                                        <p>{data.note}</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-divider">
                            <h3 className="test-heading">Educational Videos</h3>
                            <hr />
                            <Row>
                                {
                                    data.educational_videos &&
                                    data.educational_videos.map((eduElement, eduindex) => (
                                        <div key={eduindex}>
                                            {eduElement.educational_video &&
                                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                                    <Form.Group controlId="video">
                                                        <Form.Label className='d-block'>Educational Video</Form.Label>
                                                        <video
                                                            className="VideoInput_video"
                                                            controls
                                                            style={{ width: 150, height: 150 }}
                                                            src={eduElement.educational_video}
                                                            crossOrigin={cross_origin ? "anonymous" : null}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            }

                                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Form.Group controlId="note">
                                                    <Form.Label>Note</Form.Label>
                                                    <p>{eduElement.note}</p>
                                                </Form.Group>
                                            </Col>
                                        </div>
                                    ))}

                            </Row>

                        </div>
                        <div className="card-divider">
                            <h3 className="test-heading">Feedback Options</h3>
                            <hr />
                            <Row>
                                {
                                    data.feedback_option
                                    &&
                                    data.feedback_option.map((feedbackOption, optionindex) => (
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} key={optionindex}>
                                            <Form.Group className="mb-3" controlId="option" >
                                                <ul>
                                                    <li>
                                                        <p>{feedbackOption.option}</p>
                                                    </li>
                                                </ul>
                                            </Form.Group>
                                        </Col>
                                    ))
                                }
                            </Row>



                        </div>
                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Exit"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default ViewTest;