import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';

export const UpdateExercises = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.exercises, id, data, config);
    return response;
}


export const GetExercises = async (keyword = '', page = '', perPage = '', is_paginate = 1, orderByValue, orderByColumn) => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }

    params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.exercises, params);
    return response;
}


export const GetProgramExercises = async (keyword = '', bodyType = '', exerciseType = '', equipment = '', muscle = '', gender = '', orderByValue, orderByColumn) => {
    let params = {};

    if (bodyType) {
        params['body_type'] = bodyType;
    }

    if (exerciseType) {
        params['exercise_type'] = exerciseType;
    }

    if (equipment) {
        params['equipment'] = equipment;
    }

    if (muscle) {
        params['muscle'] = muscle;
    }

    if (gender) {
        params['gender'] = gender;
    }

    if (keyword) {
        params['exercise_name'] = keyword;
    }

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }

    const response = await GET(apiUrl.program_exercises, params);
    return response;
}

export const GetExerciseById = async (id) => {
    let response = '';
    response = await GET(apiUrl.exercises + '/' + id);
    return response;
}

export const GetExerciseSummary = async (id, level) => {
    let params = {};
    if (level) {
        params['level_id'] = level;
    }
    let response = '';
    response = await GET(apiUrl.exerciseSummary + '/' + id, params);
    return response;
}

export const CreateExercise = async (data) => {
    const response = await POST(apiUrl.exercises, data);
    return response;
}

export const CreateExerciseSteps = async (data) => {
    const response = await POST(apiUrl.exercise_steps, data);
    return response;
}

export const UpdateExerciseSteps = async (id, data) => {
    const response = await PUT(apiUrl.exerciseLevelUpdate, id, data);
    return response;
}

export const UpdateExercise = async (id, data) => {
    const response = await PUT(apiUrl.exercises, id, data);
    return response;
}

export const DeleteExercise = async (id) => {
    const response = await DELETE(apiUrl.exercises, id);
    return response;
}

export const DuplicateExercise = async (id, data) => {
    const response = await POST(apiUrl.duplicateExercise + '/' + id, data);
    return response;
}

export const GetExerciseLevelByIds = async (level_id, exercise_id, equipment_type) => {
    let response = '';
    response = await GET(apiUrl.exerciseLevelDetail + '/' + level_id + '/' + exercise_id + '/' + equipment_type);
    return response;
}