import React, {useEffect, useState} from 'react';
import {GetUserById, GetUsers} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {constants, GENDERS,customStyles, IS_VERIFIED, weight_unit} from "../../utils/constants";
import { useHistory } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import {ageCalculate} from "../../utils/helpers";
import {GetStatusCheckIn,GetTestFeedback,GetTestSkip,GetExerciseSkip,GetStatusCheckOut} from "../../services/report.service";
import BarLoader from "react-spinners/BarLoader";
import DataTable from 'react-data-table-component';
import moment from "moment";

function ReportSurvey(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [day, setDay] = useState(1);
    const [startDate, setStartDate] = useState({ format: "mm/dd/yyyy" });
    const [endDate, setEndDate] = useState({ format: "mm/dd/yyyy" });
    const [programId, setProgramId] = useState('');
    const [statusCheckIn, setStatusCheckIn] = useState([]);
    const [testFeedback, setTestFeedback] = useState([]);
    const [testSkip, setTestSkip] = useState([]);
    const [exerciseSkip, setExerciseSkip] = useState([]);
    const [statusCheckOut, setStatusCheckOut] = useState([]);
    const [defaultImage, setDefaultImage] = useState(constants.DUMMYPROFILE);
    const [activeTab, setActiveTab] = useState('tab1');
    const [selectedDay, setSelectedDay] = useState('1');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const currentDate = new Date().toISOString().slice(0, 10);
    const [exportCheckinData, setExportCheckinData] = useState();
    const [exportTestFeedbackData, setExportTestFeedbackData] = useState();
    const [exportTestSkipData, setExportTestSkipData] = useState();
    const [exportExerciseSkipData, setExportExerciseSkipData] = useState();
    const [exportCheckoutData, setExportCheckoutData] = useState();
    const { register, watch, setError, clearErrors, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getUserById(id);
        await getStatusCheckIn(id);
        await getTestFeedback(id);
        await getTestSkip(id);
        await getExerciseSkip(id);
        await getStatusCheckOut(id);
    }, [selectedDay,startDate,endDate]);


    const getUserById = async (id) => {
        await GetUserById(id).then((result) => {
            if (result?.status) {
                if (result?.data) {
                    let data=[];
                    data.push(result?.data)
                    if(data) {
                        setData(data);
                    }
                    if(result?.data?.userActivePrograms[0]?.program_id){
                    setProgramId(result?.data?.userActivePrograms[0]?.program_id);
                    }
                    if(result?.data?.userActivePrograms[0]?.program.days_per_week){
                        setDay(result?.data?.userActivePrograms[0]?.program.days_per_week)
                    }
                    if(result?.data?.userDetail?.image){
                         setDefaultImage(result?.data?.userDetail?.image)
                    }

                }
            } else {
                return toast.error(result?.message);
            }
        }).catch((error) => {
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const handleSelectChange = (event) => {
        setSelectedDay(event.target.value);
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array,fileName) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = `${fileName}.csv`;
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const getStatusCheckIn = async (id) => {
        setLoading(true)
        await GetStatusCheckIn(null, page, perPage, 1, id, programId, startDate,endDate,null).then((result) => {
            if (result?.status) {
                if (result?.data?.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result?.data?.data?.length);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            program_name: dt.userProgramDay.program.name || '-',
                            program_day: dt.userProgramDay.programDay.name || '-',
                            week: dt.userProgramDay.week || '-',
                            circuit: dt.userProgramDay.circuit || '-',
                            question_1:"How sore are you from previous workout?",
                            question_2:"If sore,is it a good sore or painfull sore?",
                            feedback: dt.feedback || '-',
                            answer_2:  (dt.good_sore ==10 ? "Good" : "Painful") || '-',
                            answer_1: (dt.rating + "/10") || '-',
                            good_sore: dt.good_sore || '-',
                            pain_full_sore: dt.pain_full_sore || '-',
                            created_ago: dt.created_ago,
                        });

                        exportData.push({
                            user_name: dt.user?.name  || '-',
                            user_email: dt?.user?.email || '-',
                            user_phone: dt?.user?.phone || '-',
                            // user_age:  data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-',
                            // user_gender: data[0]?.userDetail?.gender === 10 ? "Male" : "Female" || '-',
                            user_start_date:dt?.user?.start_date || '-',
                            program_name: dt.userProgramDay.program.name || '-',
                            program_day: dt.userProgramDay.programDay.name || '-',
                            week: dt.userProgramDay.week || '-',
                            circuit: dt.userProgramDay.circuit || '-',
                            question_1:"How sore are you from previous workout?",
                            question_2:"If sore,is it a good sore or painfull sore?",
                            feedback: dt.feedback || '-',
                            answer_2:  (dt.good_sore ==10 ? "Good" : "Painful") || '-',
                            answer_1: (dt.rating + "/10") || '-',
                            good_sore: dt.good_sore || '-',
                            pain_full_sore: dt.pain_full_sore || '-',
                            created_ago: dt.created_ago,
                        });

                    })

                    setExportCheckinData(exportData)
                    setLoading(false)
                    setStatusCheckIn(rowData);

                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const getTestFeedback = async (id) => {
        setLoading(true)
        await GetTestFeedback(null, page, perPage, 1, id, programId,startDate,endDate, selectedDay).then((result) => {
            if (result?.status) {
                if (result.data.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result?.data?.data?.length);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            test_name: dt.test?.title || '-',
                            testFeedbackOption: dt.reason_text || '-',
                            note: dt?.note || '-',
                            created_ago: dt.created_ago,
                        });
                        exportData.push({
                            user_name: dt.user?.name  || '-',
                            user_email: dt?.user?.email || '-',
                            user_phone: dt?.user?.phone || '-',
                            // user_age:  data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-',
                            // user_gender: data[0]?.userDetail?.gender === 10 ? "Male" : "Female" || '-',
                            user_start_date:dt?.user?.start_date || '-',
                            test_name: dt.test?.title || '-',
                            testFeedbackOption: dt.reason_text  || '-',
                            // reason_text: dt.reason_text || '-',
                            note: dt?.note || '-',
                        });
                        console.log(data[0],"here in map ")

                    })

                    setExportTestFeedbackData(exportData)
                    setTestFeedback(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const getTestSkip = async (id) => {
        setLoading(true)
        await GetTestSkip(null, page, perPage, 1, id, programId,startDate,endDate, selectedDay).then((result) => {
            if (result?.status) {
                if (result?.data?.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result?.data?.length);
                    result?.data?.data?.map((dt, index) => {
                        rowData.push({
                            id: dt?.id,
                            test_name: dt?.test?.title || '-',
                            program_name: dt?.program?.name || '-',
                            program_test_name: dt?.program_test || '-',
                            skip_till: moment(dt?.skip_till)?.format('MM-DD-YYYY') || '-',
                            skipQuestionReason: dt.reason_text || '-',
                            note: dt?.note || '-',
                            created_ago: dt?.created_ago,
                        });
                        exportData.push({
                            user_name: dt.user?.name  || '-',
                            user_email: dt?.user?.email || '-',
                            user_phone: dt?.user?.phone || '-',
                            // user_age:  data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-',
                            // user_gender: data[0]?.userDetail?.gender === 10 ? "Male" : "Female" || '-',
                            user_start_date:dt?.user?.start_date || '-',
                            test_name: dt?.test?.title || '-',
                            program_name: dt?.program?.name || '-',
                            // program_test_name: dt?.program_test || '-',
                            skip_till: moment(dt?.skip_till)?.format('MM-DD-YYYY') || '-',
                            skipQuestionReason: dt.reason_text || '-',
                            note: dt?.note || '-',
                            created_ago: dt?.created_ago,
                        });

                    })
                    setExportTestSkipData(exportData)
                    setTestSkip(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const getExerciseSkip = async (id) => {
        setLoading(true)
        await GetExerciseSkip(null, page, perPage, 1, id, programId,startDate,endDate, selectedDay).then((result) => {
            if (result?.status) {
                if (result?.data?.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result?.data?.length);
                    result?.data?.data?.map((dt, index) => {
                        rowData.push({
                            id: dt?.id,
                            program_name: dt?.userProgramDay?.program?.name || '-',
                            program_day: dt?.userProgramDay?.programDay?.name || '-',
                            exercise_name: dt?.userProgramDayExercise?.exerciseLevel?.exercise.name || '-',
                            exercise_level: dt?.userProgramDayExercise?.exerciseLevel?.level.name || '-',
                            circuit: dt?.userProgramDay?.circuit || '-',
                            week: dt?.userProgramDay?.week || '-',
                            reason: dt.reason_text || '-',
                            note: dt?.note || '-',
                            created_ago: dt?.created_ago,
                        });

                        exportData.push({
                            user_name: dt.user?.name  || '-',
                            user_email: dt?.user?.email || '-',
                            user_phone: dt?.user?.phone || '-',
                            // user_age:  data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-',
                            // user_gender: data[0]?.userDetail?.gender === 10 ? "Male" : "Female" || '-',
                            user_start_date:dt?.user?.start_date || '-',
                            program_name: dt?.userProgramDay?.program?.name || '-',
                            program_day: dt?.userProgramDay?.programDay?.name || '-',
                            exercise_name: dt?.userProgramDayExercise?.exerciseLevel.exercise.name || '-',
                            exercise_level: dt?.userProgramDayExercise?.exerciseLevel.level.name || '-',
                            circuit: dt?.userProgramDay?.circuit || '-',
                            week: dt?.userProgramDay?.week || '-',
                            reason: dt.reason_text || '-',
                            note: dt?.note || '-',
                        });

                    })
                    setExportExerciseSkipData(exportData)
                    setExerciseSkip(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const getStatusCheckOut = async (id) => {
        setLoading(true)
        await GetStatusCheckOut(null, page, perPage, 1, id, programId,startDate,endDate, selectedDay).then((result) => {
            if (result?.status) {
                if (result?.data?.data) {
                    const rowData = [];
                    const exportData = [];
                    setPage(1)
                    setTotalRows(result?.data?.length);
                    result?.data?.data?.map((dt, index) => {
                        const program_day = dt?.userProgramDay?.program?.programDays.find(
                            (item) => item.id === dt.userProgramDay.program_day_id
                        );
                        rowData.push({
                            id: dt?.id,
                            program_day: program_day.name || '-',
                            user_program_name: dt?.userProgramDay.program.name || '-',
                            question_1:"How challenging was the workout?",
                            question_2:"Could you feel the correct muscles engage?",
                            feedback: dt?.feedback || '-',
                            answer_1: (dt?.challenging_workout + '/10') || '-',
                            answer_2: (dt?.muscles_engage + '/10') || '-',
                            circuit: dt?.userProgramDay.circuit || '-',
                            week: dt?.userProgramDay.week || '-',
                            created_ago: dt?.created_ago,
                            start_date: dt?.start_date,
                        });
                        exportData.push({

                            user_name: dt.user?.name  || '-',
                            user_email: dt?.user?.email || '-',
                            user_phone: dt?.user?.phone || '-',
                            // user_age:  data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-',
                            // user_gender: data[0]?.userDetail?.gender === 10 ? "Male" : "Female" || '-',
                            user_start_date:dt?.user?.start_date || '-',
                            user_program_name: dt?.userProgramDay.program.name || '-',
                            program_day: program_day.name || '-',
                            question_1:"How challenging was the workout?",
                            question_2:"Could you feel the correct muscles engage?",
                            feedback: dt?.feedback || '-',
                            answer_1: (dt?.challenging_workout + '/10') || '-',
                            answer_2: (dt?.muscles_engage + '/10') || '-',
                            circuit: dt?.userProgramDay.circuit || '-',
                            week: dt?.userProgramDay.week || '-',
                            created_ago: dt.created_ago,
                            start_date: dt.start_date,
                        });

                    })
                    setExportCheckoutData(exportData)
                    setStatusCheckOut(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message.replace(/_/g, ' '));
            }
        })
    }

    const StatusCheckIn = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'program_name',
            name: 'Program Name',
            maxWidth: '300px',
            minWidth: '200px',
            center: true,
            selector: rowData => rowData.program_name,
        },
        {
            id: 'program_day',
            name: 'Program Day',
            maxWidth: '300px',
            minWidth: '200px',
            center: true,
            selector: rowData => rowData.program_day,
        },
        {
            id: 'question_1',
            name: 'Question No. 1',
            maxWidth: '300px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.question_1,
        },
        {
            id: 'answer_1',
            name: 'Answer No. 1',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.answer_1,
        },
        {
            id: 'question_2',
            name: 'Question No. 2',
            maxWidth: '300px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.question_2,
        },
        {
            id: 'answer_2',
            name: 'Answer No. 2',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.answer_2,
        },
        {
            id: 'feedback',
            name: 'Feedback',
            maxWidth: '500px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.feedback,
        },
        {
            id: 'week',
            name: 'Week',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.week,
        },
        {
            id: 'circuit',
            name: 'Circuit',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.circuit,
        },
        {
            id: 'created_ago',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_ago,
        },

    ];

    const TestFeedback = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'test_name',
            name: 'Test Title',
            maxWidth: '400px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.test_name,
        },
        {
            id: 'testFeedbackOption',
            name: 'Selected Option',
            width: '500px',
            wrap:true,
            allowOverflow:true,
            // minWidth: '300px',
            sortable: true, // Set the maximum width for this column
            selector: rowData => rowData.testFeedbackOption,
        },
        {
            id: 'note',
            name: 'Note',
            minWidth: '350px',
            maxWidth: '500px',
            selector: rowData => rowData.note,
        },
        {
            id: 'created_ago',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_ago,
        },

    ];

    const TestSkip = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'program_name',
            name: 'Program Name',
            maxWidth: '400px',
            minWidth: '300px',
            center: true,
            selector: rowData => rowData.program_name,
        },
        {
            id: 'test_name',
            name: 'Test Title',
            maxWidth: '400px',
            minWidth: '300px',
            center: true,
            sortable: true,
            selector: rowData => rowData.test_name,
        },
        {
            id: 'skipQuestionReason',
            name: 'Skip Question Reason',
            maxWidth: '400px',
            minWidth: '300px',
            wrap:true,
            allowOverflow:true,
            center: true,
            sortable: true,
            selector: rowData => rowData.skipQuestionReason,
        },
        {
            id: 'skip_till',
            name: 'Skip Till',
            maxWidth: '400px',
            minWidth: '300px',
            center: true,
            selector: rowData => rowData.skip_till,
        },
        {
            id: 'note',
            name: 'Note',
            minWidth: '350px',
            maxWidth: '500px',
            selector: rowData => rowData.note,
        },
        {
            id: 'created_ago',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_ago,
        },

    ];
    const ExerciseSkip = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'program_name',
            name: 'Program Name',
            maxWidth: '300px',
            minWidth: '200px',
            center: true,
            selector: rowData => rowData.program_name,
        },
        {
            id: 'program_day',
            name: 'Program Day',
            maxWidth: '200px',
            minWidth: '100px',
            center: true,
            selector: rowData => rowData.program_day,
        },
        {
            id: 'exercise_name',
            name: 'Exercise Name',
            maxWidth: '400px',
            minWidth: '300px',
            center: true,
            selector: rowData => rowData.exercise_name,
        },
        {
            id: 'exercise_level',
            name: 'Exercise Level',
            maxWidth: '400px',
            minWidth: '300px',
            center: true,
            sortable: true,
            selector: rowData => rowData.exercise_level,
        },
        {
            id: 'week',
            name: 'Week',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.week,
        },
        {
            id: 'circuit',
            name: 'Circuit',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.circuit,
        },
        {
            id: 'reason',
            name: 'Skip Question Reason',
            maxWidth: '400px',
            minWidth: '300px',
            wrap:true,
            allowOverflow:true,
            center: true,
            sortable: true,
            selector: rowData => rowData.reason,
        },
        {
            id: 'note',
            name: 'Note',
            minWidth: '350px',
            maxWidth: '500px',
            selector: rowData => rowData.note,
        },
        {
            id: 'created_ago',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_ago,
        },

    ];
    const StatusCheckOut = [
        {
            id: 'id',
            name: 'Id',
            maxWidth: '100px',
            center: true,
            sortable: true,
            selector: rowData => rowData.id,
        },
        {
            id: 'program_name',
            name: 'Program Name',
            maxWidth: '300px',
            minWidth: '200px',
            center: true,
            selector: rowData => rowData.user_program_name,
        },
        {
            id: 'program_day',
            name: 'Program Day',
            maxWidth: '300px',
            minWidth: '200px',
            center: true,
            selector: rowData => rowData.program_day,
        },
        {
            id: 'question_1',
            name: 'Question No. 1',
            maxWidth: '300px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.question_1,
        },
        {
            id: 'answer_1',
            name: 'Answer No. 1',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.answer_1,
        },
        {
            id: 'question_2',
            name: 'Question No. 2',
            maxWidth: '300px',
            minWidth: '300px',
            sortable: true,
            selector: rowData => rowData.question_2,
        },
        {
            id: 'answer_2',
            name: 'Answer No. 2',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.answer_2,
        },
        {
            id: 'feedback',
            name: 'Feedback',
            maxWidth: '500px',
            minWidth: '500px',
            sortable: true,
            selector: rowData => rowData.feedback,
        },
        {
            id: 'week',
            name: 'Week',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.week,
        },
        {
            id: 'circuit',
            name: 'Circuit',
            minWidth: '150px',
            center: true,
            selector: rowData => rowData.circuit,
        },
        {
            id: 'created_ago',
            name: 'Submitted At',
            center: true,
            selector: rowData => rowData.created_ago,
        },

    ];

    const onStartDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
            setStartDate(date.toLocaleDateString())
    }
    const onEndDateChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setEndDate(date.toLocaleDateString())
    }
    return (
        <div className="survey-report">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Survey Report - {data?.name}</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                    <ul className={"list-inline list-variations mb-0"}>
                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }} src={defaultImage} width="50%" height="50%" crossOrigin="anonymous" alt='Not Found' />
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ul className={'View-list'}>
                        {console.log(data[0])}
                        <li >
                            <span className={'view-list-title'}>User Name: </span>{ data[0]?.name || '-' }
                        </li>
                        <li >
                            <span className={'view-list-title'}>Email: </span>{data[0]?.email || '-'}
                        </li>
                        <li >
                            <span className={'view-list-title'}>Phone: </span>{data[0]?.phone || '-'}
                        </li>
                        <li >
                            <span className={'view-list-title'}>Age: </span>{ data[0]?.userDetail?.dob ? ageCalculate(data[0]?.userDetail?.dob) : '-'}
                        </li>
                        <li >
                            <span className={'view-list-title'}>Gender: </span>{(data[0]?.userDetail?.gender === 10 ? "Male" : "Female") || '-'}
                        </li>
                        <li >
                            <span className={'view-list-title'}>Start Date: </span>{data[0]?.start_date || '-'}
                        </li>
                        <li >
                            <span className={'view-list-title'}>Active Program Name: </span>
                            {data[0]?.userProgramCSV || '-'}
                        </li>
                        {/*<li >*/}
                        {/*    <Form.Group className="mb-3 custom-width">*/}
                        {/*        <div className={'view-list-title mb-1'}>Filter Program Day :</div>*/}
                        {/*        <Form.Select*/}
                        {/*            placeholder="Select Program Day"*/}
                        {/*            id={"program_day"}*/}
                        {/*            onChange={handleSelectChange}*/}
                        {/*            {...register("program_day"*/}
                        {/*            )}>*/}
                        {/*            {[...Array(day)].map((_, i) => (*/}
                        {/*                <option key={i} value={i + 1}>*/}
                        {/*                    Day {i + 1}*/}
                        {/*                </option>*/}
                        {/*            ))}*/}
                        {/*        </Form.Select>*/}
                        {/*    </Form.Group>*/}
                        {/*</li>*/}
                        <li >
                            <span className={'view-list-title'}>Filter Date : </span>
                            <Form.Group className="mb-3 custom-width">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    placeholder="Enter Start Date"
                                    id={"formSignUpAge"}
                                    value={startDate.date}
                                    max={currentDate}
                                    maxLength={3}
                                    {...register('start_date', {
                                        onChange: (e) => onStartDateChange(e),
                                    })} type="date"

                                />
                            </Form.Group>
                            <Form.Group className="mb-3 custom-width">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    placeholder="Enter End Date"
                                    id={"formSignUpAge"}
                                    value={endDate.date}
                                    max={currentDate}
                                    maxLength={3}
                                    {...register('end_date', {
                                        onChange: (e) => onEndDateChange(e),
                                    })} type="date"

                                />
                            </Form.Group>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="tab1">Status Check In</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab2">Test Feedback</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab3">Test Skip</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab4">Exercise Skip</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab5">Status Check Out</Nav.Link>
                            </Nav.Item>

                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="tab1">
                               <div className="csv-wrapper">
                                   <h4>Status Check In</h4>
                                    <Button className={"btn btn-green-theme h40 btn-csv"} onClick={() => downloadCSV(exportCheckinData,'UserStatusCheckInSurvey')}>Export CSV</Button>
                               </div>
                                <div className="theme-dataGrid products-dataGrid">
                                    <DataTable
                                        pagination
                                        columns={StatusCheckIn}
                                        data={statusCheckIn}
                                        // actions={actionsMemo}
                                        // data={data}
                                        progressPending={loading}
                                        customStyles={customStyles}
                                        selectableRows={false}
                                        striped
                                        highlightOnHover

                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        onChangePage={(page) => {
                                            setPage(page);
                                        }}
                                        onChangeRowsPerPage={(currentRowsPerPage) => {
                                            setPerPage(currentRowsPerPage)
                                        }}
                                        progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                                                                      height={'4'} width={'100%'} />}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2">
                                <div className="csv-wrapper">
                                    <h4>Test Feedback</h4>
                                    <Button className={"btn btn-green-theme h40 btn-csv"} onClick={() => downloadCSV(exportTestFeedbackData,'UserTestFeedbackSurvey')}>Export CSV</Button>
                                </div>

                                <div className="theme-dataGrid products-dataGrid">
                                    <DataTable
                                        pagination
                                        columns={TestFeedback}
                                        data={testFeedback}
                                        // actions={actionsMemo}
                                        // data={data}
                                        progressPending={loading}
                                        customStyles={customStyles}
                                        selectableRows={false}
                                        striped
                                        highlightOnHover

                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        onChangePage={(page) => {
                                            setPage(page);
                                        }}
                                        onChangeRowsPerPage={(currentRowsPerPage) => {
                                            setPerPage(currentRowsPerPage)
                                        }}
                                        progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                                                                      height={'4'} width={'100%'} />}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab3">
                                <div className="csv-wrapper">
                                    <h4>Test Skip</h4>
                                    <Button className={"btn btn-green-theme h40 btn-csv"} onClick={() => downloadCSV(exportTestSkipData,'UserTestSkipSurvey')}>Export CSV</Button>
                                </div>

                                <div className="theme-dataGrid products-dataGrid">
                                    <DataTable
                                        pagination
                                        columns={TestSkip}
                                        data={testSkip}
                                        // actions={actionsMemo}
                                        // data={data}
                                        progressPending={loading}
                                        customStyles={customStyles}
                                        selectableRows={false}
                                        striped
                                        highlightOnHover

                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        onChangePage={(page) => {
                                            setPage(page);
                                        }}
                                        onChangeRowsPerPage={(currentRowsPerPage) => {
                                            setPerPage(currentRowsPerPage)
                                        }}
                                        progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                                                                      height={'4'} width={'100%'} />}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab4">
                                <div className="csv-wrapper">
                                    <h4>Exercise Skip</h4>
                                    <Button className={"btn btn-green-theme h40 btn-csv"} onClick={() => downloadCSV(exportExerciseSkipData,'UserExerciseSkipSurvey')}>Export CSV</Button>
                                </div>

                                <div className="theme-dataGrid products-dataGrid">
                                    <DataTable
                                        pagination
                                        columns={ExerciseSkip}
                                        data={exerciseSkip}
                                        // actions={actionsMemo}
                                        // data={data}
                                        progressPending={loading}
                                        customStyles={customStyles}
                                        selectableRows={false}
                                        striped
                                        highlightOnHover

                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        onChangePage={(page) => {
                                            setPage(page);
                                        }}
                                        onChangeRowsPerPage={(currentRowsPerPage) => {
                                            setPerPage(currentRowsPerPage)
                                        }}
                                        progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                                                                      height={'4'} width={'100%'} />}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab5">
                                <div className="csv-wrapper">
                                    <h4>Status Check Out</h4>
                                    <Button className={"btn btn-green-theme h40 btn-csv"} onClick={() => downloadCSV(exportCheckoutData,'UserStatusCheckOutSurvey')}>Export CSV</Button>
                                </div>

                                <div className="theme-dataGrid products-dataGrid">
                                    <DataTable
                                        pagination
                                        columns={StatusCheckOut}
                                        data={statusCheckOut}
                                        // actions={actionsMemo}
                                        // data={data}
                                        progressPending={loading}
                                        customStyles={customStyles}
                                        selectableRows={false}
                                        striped
                                        highlightOnHover

                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={perPage}
                                        onChangePage={(page) => {
                                            setPage(page);
                                        }}
                                        onChangeRowsPerPage={(currentRowsPerPage) => {
                                            setPerPage(currentRowsPerPage)
                                        }}
                                        progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                                                                      height={'4'} width={'100%'} />}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </div>
    );
}

export default ReportSurvey;