import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import { Col, Row } from "react-bootstrap";
import Summary from "./Summary";
import EditLevelDetails from "./EditLevelDetails";
import { useEffect } from "react";
import { useState } from "react";
import ThemeModal from "../../components/ThemeModal";
import { useHistory, useLocation } from "react-router-dom";

const steps = [
    "Level One",
    "Equipment 1",
    "Equipment 2",
    "Equipment 3",
    "Level Two",
    "Equipment 1",
    "Equipment 2",
    "Equipment 3",
    "Level Three",
    "Equipment 1",
    "Equipment 2",
    "Equipment 3",
    "Summary",
];
export default function EditExerciseSteps(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const [disableButton, setDisableButton] = useState(false);
    const [changed, setChanged] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [handleNextStep, setHandleNextStep] = useState(null);
    const [nextStep, setNextStep] = useState(null);
    const history = useHistory();

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        if (activeStep !== 0) {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setSkipped(newSkipped);
        }
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        if (changed === true) {
            setNextStep(step)
            modalShowFunc(step)
        } else {
            setActiveStep(step);
        }
    };

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, [activeStep])

    const modalShowFunc = (step) => {
        setHandleNextStep(step)
        setModalShow(true)
    }

    const modalHideFunc = () => {
        // setHandleNextStep(null)
        setModalShow(false)
    }

    const discardChanges = () => {
        setModalShow(false)
        setChanged(false)
        setActiveStep(handleNextStep);
    }

    const saveChanges = () => {
        setModalShow(false)
        setChanged(false)
        document.getElementById("formUpdateExerciseSubmitBtn").click()
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changed) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        const unblock = history.block((location) => {
            if (changed) {
                setModalShow(true); // Show the custom modal
                return false; // Prevent the location change
            }
            return true; // Allow the location change
        });

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            unblock(); // Release the block when the component unmounts
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [history, changed]);

    const SaveChanges = ({ content }) => {
        return (
            <div className={"modalFeelItHere"}>
                <div className={"mt-3"}>
                    <Row>
                        <Col md={6} className={'text-start'}>
                            <Button className='back-btn' color="inherit" type={"button"} disabled={disableButton} sx={{ mr: 1 }} onClick={() => discardChanges()}>
                                No
                            </Button>
                        </Col>
                        <Col md={{ span: 2, offset: 4 }} className={'text-end'}>
                            <Button className='next-btn' type={"submit"} disabled={disableButton} onClick={() => saveChanges()}>
                                Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Row className="mb-5">
                    <h2 className="exercise-compilation mb-4 mt-2 text-center">
                        Exercise Compilation
                    </h2>
                </Row>
                <Stepper activeStep={activeStep} nonLinear>
                    {steps.map((label, key) => (
                        <Step key={key}>
                            <StepButton
                                color="inherit"
                                className={
                                    key === 0 || key === 4 || key === 8 || key === 12
                                        ? "levelClass"
                                        : ""
                                }
                                onClick={handleStep(key)}
                                style={{}}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {/* This is content inside the pages */}
                {activeStep === 0 ? (
                    <EditLevelDetails
                        level="Level 1 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={1}
                        equipment={0}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 1 ? (
                    <EditLevelDetails
                        level="Equipment 1 - level 1 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={1}
                        equipment={1}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 2 ? (
                    <EditLevelDetails
                        level="Equipment 2 - level 1 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={1}
                        equipment={2}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 3 ? (
                    <EditLevelDetails
                        level="Equipment 3 - level 1 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={1}
                        equipment={3}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 4 ? (
                    <EditLevelDetails
                        level="Level 2 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={2}
                        equipment={0}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 5 ? (
                    <EditLevelDetails
                        level="Equipment 1 - level 2 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={2}
                        equipment={1}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 6 ? (
                    <EditLevelDetails
                        level="Equipment 2 - level 2 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={2}
                        equipment={2}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 7 ? (
                    <EditLevelDetails
                        level="Equipment 3 - level 2 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={2}
                        equipment={3}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 8 ? (
                    <EditLevelDetails
                        level="Level 3 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={3}
                        equipment={0}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 9 ? (
                    <EditLevelDetails
                        level="Equipment 1 - level 3 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={3}
                        equipment={1}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 10 ? (
                    <EditLevelDetails
                        level="Equipment 2 - level 3 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={3}
                        equipment={2}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : activeStep === 11 ? (
                    <EditLevelDetails
                        level="Equipment 3 - level 3 Details"
                        handleNext={handleNext}
                        handleBack={handleBack}
                        levelNum={3}
                        equipment={3}
                        exerciseId={props.match.params.id}
                        handleStep={setActiveStep} nextStep={nextStep} setNextStepFunc={setNextStep} activeStep={activeStep} changed={setChanged}
                    />
                ) : (
                    <Summary level="Summary" changed={setChanged} exerciseId={props.match.params.id} />
                )}

                {/* Buttons of next and finish and back */}
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : null
                    // (
                    //     <React.Fragment>
                    //         <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    //             <Button
                    //                 className="back-btn"
                    //                 color="inherit"
                    //                 disabled={activeStep === 0}
                    //                 onClick={handleBack}
                    //                 sx={{ mr: 1 }}
                    //             >
                    //                 Back
                    //             </Button>
                    //             <Button
                    //                 className="back-btn draft-btn"
                    //                 color="inherit"
                    //                 sx={{ mr: 1 }}
                    //             >
                    //                 Save as draft
                    //             </Button>
                    //             <Box sx={{ flex: "1 1 auto" }} />
                    //             {isStepOptional(activeStep) && (
                    //                 <Button
                    //                     className="d-none"
                    //                     color="inherit"
                    //                     onClick={handleSkip}
                    //                     sx={{ mr: 1 }}
                    //                 >
                    //                     Skip
                    //                 </Button>
                    //             )}
                    //
                    //             {/*<Button className="next-btn" type={"Submit"} onClick={validateform}>*/}
                    //             {/*    {activeStep === steps.length - 1 ? "Finish" : "Next"}*/}
                    //             {/*</Button>*/}
                    //         </Box>
                    //     </React.Fragment>
                    // )
                }
                {/* Buttons of next and finish and back */}
            </Box>
            <ThemeModal
                title={'Save Changes?'}
                content={<SaveChanges />} size={"md"}
                show={modalShow}
                onHide={() => {
                    modalHideFunc()
                }}
            />
        </>

    );
}
