import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//auth layout
import AuthLayoutRoute from "./layouts/AuthLayout";
//auth views
import Signin from "./views/auth/Signin";
import SignUp from "./views/auth/SignUp";
import ForgotPassword from "./views/auth/ForgotPassword";
import VerifyCode from "./views/auth/VerifyCode";
import ResetPassword from "./views/auth/ResetPassword";
//dashboard layout
import DashboardLayoutRoute from "./layouts/DashboardLayout";
//dashboard views
import Dashboard from "./views/dashboard/Dashboard";
//dashboard views / Users
import Users from "./views/users/Users";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
// dasboard Video
import AddNewVideo from "./views/video/AddNewVideo";
// dashboard quipment
import AddNewEquipment from "./views/equipment/AddNewEquipment"
// dashboard exercise
import AddExercise from "./views/exercise/AddExercise"
import EditExercise from "./views/exercise/EditExercise"
// dashboard program
import AddProgram from "./views/program/AddProgram";
import ProgramLibrary from "./views/program/ProgramLibrary";
//notifications
import AllNotifications from "./views/notification/AllNotifications";
// dashboard settings
import ChangePassword from "./views/settings/ChangePassword";
//Policy routes
import Pages from "./views/policy/Pages";
//profile management
import ViewProfile from "./views/profile/ViewProfile";
import EditProfile from "./views/profile/EditProfile";
//error layout
import ErrorComponent from './views/error/ErrorComponent'
// Feel it here management
import FeelItHereManagement from './views/feelithere/FeelItHereManagement';
import AddFeel from './views/feelithere/AddFeel'
import EditFeelItHere from './views/feelithere/EditFeelItHere'
//pages
import PageList from "./views/pages/PageList"
import PageAdd from "./views/pages/PageAdd";
import PageEdit from "./views/pages/PageEdit";

import Payments from "./views/payments/Payments";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import VideoList from "./views/video/VideoList";
import EquipmentList from "./views/equipment/EquipmentList";
import EditEquipment from "./views/equipment/EditEquipment";
import VideoTemp from "./views/exercise/VideoTemp";
import ListExercise from "./views/exercise/ListExercise";
import ContactRequests from "./views/contact/ContactRequests";
import Instructions from "./views/app-instructions/Instructions";
import BodyTypes from "./views/body-type/BodyTypes";
import Muscles from "./views/muscle/Muscles";
import ExerciseType from "./views/exercise-type/ExerciseType";
import SubCategory from "./views/sub-category/SubCategory";
import Sports from "./views/sports/sports";
import Occupation from "./views/occupation/Occupation";
import Payment from "./views/payments/Payment";
import AddNewExercise from "./views/exercise/AddNewExercise";
import AddNewProgramForm from "./views/program/AddNewProgramForm";
import InjuryProgram from "./views/injury-program/InjuryProgram";
import EditExerciseSteps from "./views/exercise/EditExerciseSteps";
import Testimonials from "./views/testimonials/Testimonials";
import AddNewTestimonials from "./views/testimonials/AddNewTestimonials";
import EditTestimonials from "./views/testimonials/EditTestimonials";
import EditProgramForm from "./views/program/EditProgramForm";
import AddTest from "./views/test/AddTest";
import ListTest from "./views/test/ListTest";
import EditTest from "./views/test/EditTest";
import ViewTest from "./views/test/ViewTest";
import SkipQuestion from "./views/skip-question-reason/SkipQuestion";
import ViewUser from "./views/users/ViewUser";
import AptVideos from "./views/apt-videos/AptVideos";
import Faqs from "./views/faqs/Faqs";
import ReportSurvey from "./views/report/ReportSurvey";
import PainPoints from "./views/pain-point-library/PainPoints";
import AddNewPainPoint from "./views/pain-point-library/AddNewPainPoint";
import EditPainPoint from "./views/pain-point-library/EditPainPoint";
import MusclesAnatomy from "./views/muscles-anatomy-library/MusclesAnatomy";
import AddNewMusclesAnatomy from "./views/muscles-anatomy-library/AddNewMusclesAnatomy";
import EditMusclesAnatomy from "./views/muscles-anatomy-library/EditMusclesAnatomy";



// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
    injectStyle();
}

function App() {
    return (
        <Router>
            <Switch>
                {/**
                 *
                 *  Define all the page routes here
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/dashboard`} component={Dashboard} />

                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users`} component={Users} />
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/add-user`} component={AddNewUser} />
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/edit-user/:id`}
                    component={EditUser} />
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-users/view-user/:id`}
                    component={ViewUser} />
                <DashboardLayoutRoute exact isAuth={true} path={`/survey-report/:id`} component={ReportSurvey} />
                {/* video management */}
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-video/`}
                    component={AddNewVideo} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-video/:id`}
                    component={AddNewVideo} />
                <DashboardLayoutRoute exact isAuth={true} path={`/videos`}
                    component={VideoList} />

                {/* exercise management */}
                <DashboardLayoutRoute exact isAuth={true} path={`/exercises/`}
                    component={ListExercise} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-exercise/`}
                    component={AddNewExercise} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-exercise-steps/`}
                    component={AddExercise} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-exercise/:id`}
                    component={EditExercise} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-exercise-steps/:id`}
                    component={EditExerciseSteps} />

                <DashboardLayoutRoute exact isAuth={true} path={`/add-exercise-temp/`}
                    component={VideoTemp} />

                {/* program management */}
                <DashboardLayoutRoute exact isAuth={true} path={`/manage-program/`}
                    component={AddProgram} />
                <DashboardLayoutRoute exact isAuth={true} path={`/program-library/`}
                    component={ProgramLibrary} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-program/`}
                    component={AddNewProgramForm} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-program/:id`}
                    component={EditProgramForm} />

                {/* equipment management */}
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-equipment/`}
                    component={AddNewEquipment} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-equipment/:id`}
                    component={EditEquipment} />
                <DashboardLayoutRoute exact isAuth={true} path={`/equipments`}
                    component={EquipmentList} />

                <DashboardLayoutRoute exact isAuth={true} path={`/notifications`} component={AllNotifications} />

                {/* <DashboardLayoutRoute exact isAuth={true} path={`/payments-management`} component={Payments} /> */}

                {/* feel-it-here management */}
                <DashboardLayoutRoute exact isAuth={true} path={`/feel-it-here`}
                    component={FeelItHereManagement} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-feel-it-here/:id`}
                    component={EditFeelItHere} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-feel-it-here`}
                    component={AddFeel} />

                {/**
                 *
                 *  Contact Us Management
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/contact-us`} component={ContactRequests} />

                {/**
                 *
                 *  App Instructions Management - to add new images to popup
                 *
                 */}
                {/* <DashboardLayoutRoute exact isAuth={true} path={`/payment`} component={Payment} /> */}
                <DashboardLayoutRoute exact isAuth={true} path={`/app-instructions`} component={Instructions} />
                <DashboardLayoutRoute exact isAuth={true} path={`/apt-videos`} component={AptVideos} />
                <DashboardLayoutRoute exact isAuth={true} path={`/faqs`} component={Faqs} />
                <DashboardLayoutRoute exact isAuth={true} path={`/occupation`} component={Occupation} />

                {/**
                 *
                 *  Cruds for Body type, Exercise type, Muscles and Subcategory
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/body-types`} component={BodyTypes} />
                <DashboardLayoutRoute exact isAuth={true} path={`/muscles`} component={Muscles} />
                <DashboardLayoutRoute exact isAuth={true} path={`/exercise-types`} component={ExerciseType} />
                <DashboardLayoutRoute exact isAuth={true} path={`/category`} component={SubCategory} />
                <DashboardLayoutRoute exact isAuth={true} path={`/sports`} component={Sports} />
                <DashboardLayoutRoute exact isAuth={true} path={`/injury-program`} component={InjuryProgram} />
                <DashboardLayoutRoute exact isAuth={true} path={`/testimonials`} component={Testimonials} />
                
                {/** PAIN POINT LIBRARY */}
                <DashboardLayoutRoute exact isAuth={true} path={`/pain-point-library`} component={PainPoints} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-pain-point`} component={AddNewPainPoint} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-pain-point/:id`} component={EditPainPoint} />

                {/** MUSCLES ANATOMY LIBRARY */}
                <DashboardLayoutRoute exact isAuth={true} path={`/muscles-anatomy-library`} component={MusclesAnatomy} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-muscles-anatomy`} component={AddNewMusclesAnatomy} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-muscles-anatomy/:id`} component={EditMusclesAnatomy} />

                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-testimonials`} component={AddNewTestimonials} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-testimonials/:id`} component={EditTestimonials} />
                <DashboardLayoutRoute exact isAuth={true} path={`/add-new-tests`} component={AddTest} />
                <DashboardLayoutRoute exact isAuth={true} path={`/tests`} component={ListTest} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-tests/:id`} component={EditTest} />
                <DashboardLayoutRoute exact isAuth={true} path={`/view-test/:id`} component={ViewTest} />
                <DashboardLayoutRoute exact isAuth={true} path={`/skip-question-reason`} component={SkipQuestion} />
                {/**
                 *
                 *  pages Management
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/page-list`} component={PageList} />
                <DashboardLayoutRoute exact isAuth={true} path={`/page-edit/:id`} component={PageEdit} />
                <DashboardLayoutRoute exact isAuth={true} path={`/page-add`} component={PageAdd} />


                {/**
                 *
                 *  Terms and conditions pages
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/page/:slug`} component={Pages} />

                {/**
                 *
                 *  Profile Views
                 *
                 */}
                <DashboardLayoutRoute exact isAuth={true} path={`/profile`} component={ViewProfile} />
                <DashboardLayoutRoute exact isAuth={true} path={`/edit-profile`} component={EditProfile} />
                <DashboardLayoutRoute exact isAuth={true} path={`/change-password`} component={ChangePassword} />


                {/**
                 *
                 *  Authentication routes
                 *
                 */}
                <AuthLayoutRoute exact isAuth={true} path="/sign-up" component={SignUp} />
                <AuthLayoutRoute exact isAuth={true} path="/forgot-password" component={ForgotPassword} />
                <AuthLayoutRoute exact isAuth={true} path="/verify-code" component={VerifyCode} />
                <AuthLayoutRoute exact isAuth={true} path="/reset-password" component={ResetPassword} />
                <AuthLayoutRoute exact isAuth={true} path="/" component={Signin} />

                {/**
                 *
                 *  Error routes
                 *
                 */}
                <Route path={"*"}>
                    <ErrorComponent type={"404"} message={"The page you are looking for is not found"} />
                </Route>


            </Switch>
            <ToastContainer hideProgressBar autoClose={2000} />
        </Router>
    );
}

export default App;
