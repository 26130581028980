import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetPainPoints = async (keyword = '', page = '', perPage = '', is_paginate = 1) => {
    let params = {};

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    params['is_paginate'] = is_paginate;

    const response = await GET(apiUrl.pain_points_library, params);
    return response;
}

export const CreatePainPoints = async (data) => {
    const response = await POST(apiUrl.pain_points_library, data);
    return response;
}

export const DeletePainPoints = async (id) => {
    const response = await DELETE(apiUrl.pain_points_library, id);
    return response;
}

export const GetPainPointsById = async (id) => {
    const response = await GET(apiUrl.pain_points_library + '/' + id);
    return response;
}

export const UpdatePainPoints = async (id, data) => {
    const response = await PUT(apiUrl.pain_points_library, id, data);
    return response;
}