import React, { useRef, useState, useParams, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { CreateEquipment, GetEquipmentById, UpdateEquipment } from "../../services/equipments.service";
import { FadeLoader } from "react-spinners"
import {cross_origin, GENDERS} from "../../utils/constants";
import { UploadImageToS3, uploadToS3 } from '../../utils/helpers';

function EditEquipment(props) {
    let history = useHistory();
    const equipment_id = props.match.params.id;
    const [modalShow, setModalShow] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [row, setRow] = useState(null);
    const [weightKg, setWeightKg] = useState(0);
    const [weightLbs, setWeightLbs] = useState(0);
    const [refererDomain, setRefererDomain] = useState('');
    const [referalLinkImage, setReferalLinkImage] = useState('');
    const [isUpload, setIsUpload] = useState(false)
    const [image, setImage] = useState('')
    const [correctUrl, setcorrectUrl] = useState(false)
    const { register, watch, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const selectedMedia = (fileName, file) => {
        clearErrors(fileName);
        setFormData({ ...formData, ...{ [fileName]: file } })
        setModalShow(false)
    }
    useEffect(() => {
        GetEquipmentById(equipment_id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setRow(result.data)
                    setFormData(result.data)
                    setValue("title", result.data.title)
                    setValue("image", result.data.image)
                    setValue("weight_kg", result.data.weight_kg)
                    setValue("weight_lb", result.data.weight_lb)
                    setValue("referral_link", result.data.referral_link)
                    setValue("referral_link_image", result.data.referral_link_image)
                    setValue("description", result.data.description)
                    setValue("note", result.data.note)
                    // setReferalLinkImage(result.data.referral_link_image)
                    setImage(result.data.referral_link_image)
                    if (result.data.referral_link_image) {
                        setIsUpload(true)
                    }
                    setWeightKg(result.data.weight_kg)
                    setWeightLbs(result.data.weight_lb)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }, [])

    const onSubmit = async data => {
        setLoader(true)
        // if (!formData.image) {
        //     setError('image', {message: 'Image is required'});
        //     setLoader(false)
        //     return false;
        // }
        data.image = formData.image
        // if (!correctUrl) {
        //     setError('referral_link', { message: 'Please enter a valid URL' });
        //     setLoader(false)
        //     return false;
        // }
        if (typeof referalLinkImage === "object") {
            let s3Data = await uploadToS3(referalLinkImage.type, referalLinkImage.name, referalLinkImage, "equipment")
            if (!s3Data) {
                toast.error("Uploading failed");
                setLoader(false)
                return false;
            }
            data.referral_link_image = s3Data
        }
        else {
            data.referral_link_image = image
        }

        if (data.referral_link_image instanceof FileList && data.referral_link_image.length <= 0) {
            data.referral_link_image = image
        }
        data.weight_kg = weightKg
        data.weight_lb = weightLbs

        await UpdateEquipment(equipment_id, data).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/equipments')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const getBrand = (url) => {
        const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        const correctUrl = pattern.test(url);
        if (correctUrl) {
            setcorrectUrl(true)
        }

        let domain = (new URL(url))
        let host = domain.hostname.replace('www.', '');
        if (host.includes("amazon")) {
            setRefererDomain('amazon')
        } else if (host.includes("ebay")) {
            setRefererDomain('ebay')
        }
    }

    const handleWeightKg = event => {
        const kg = event.target.value;
        const lbs = kg * 2.20462262185;
        setWeightKg(kg);
        setWeightLbs(lbs.toFixed(2));

    };

    const handleWeightLbs = event => {
        const lbs = event.target.value;
        const kg = lbs / 2.20462262185;

        setWeightLbs(lbs);
        setWeightKg(kg.toFixed(2));

    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setReferalLinkImage(fileInput[0])
        // setImage(fileUrl)
        setIsUpload(true)
        // let uploadImage = await UploadImageToS3(fileInput[0]);
        // if (uploadImage) {
        //     if (!uploadImage.success) {
        //         toast.error(uploadImage.message);
        //         return false;
        //     }
        //     setReferalLinkImage(uploadImage.data)
        // } else {
        //     toast.error('Can\'t Upload Image');
        //     return false;
        // }
    }

    return (
        <div className="AddNewEquipment">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Equipment</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}> <Form.Group className="mb-3"
                                controlId="full_name">
                                <Form.Label>Equipment Image</Form.Label>
                                <button type='button'
                                    className='btn btn-user-theme'
                                    onClick={() => {
                                        setFileName("image")
                                        setModalShow(true)
                                    }}
                                >
                                    Upload Equipment Image
                                </button>
                                {errors.image && <Form.Text
                                    className="text-muted validationText hasError">{errors.image.message}</Form.Text>}
                                {formData.image ?
                                    <img src={formData.image} className={"img-table img-thumbnail"}
                                        crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                            </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="euipment_title">
                                    <Form.Label>Equipment Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Equipment Name"
                                        id={"formSignUpvideo"}
                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Equipment Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="weightKgF">
                                    <Form.Label className='d-block'>Weight (kg)</Form.Label>
                                    <Form.Control
                                        placeholder="Weight (kg)"
                                        value={weightKg}

                                        {...register('weight_kg', {
                                            onChange: (e) => handleWeightKg(e),
                                            required: {
                                                value: "required",
                                                message: "Weight is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="number"
                                    />
                                    {errors.weight_kg && <Form.Text
                                        className="text-muted validationText hasError">{errors.weight_kg.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="weightLbsF">
                                    <Form.Label className='d-block'>Weight (lbs)</Form.Label>
                                    <Form.Control
                                        placeholder="Weight (lbs)"
                                        value={weightLbs}


                                        {...register('weight_lb', {
                                            onChange: (e) => handleWeightLbs(e),
                                            required: {
                                                value: "required",
                                                message: "Weight is required"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="number"
                                    />
                                    {errors.weight_lb && <Form.Text
                                        className="text-muted validationText hasError">{errors.weight_lb.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="referalLink">
                                    <Form.Label className='d-block'>Referal
                                        Link <b>({refererDomain ? refererDomain : ''})</b></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Referal Link"

                                        {...register('referral_link', {
                                            onChange: (e) => {
                                                getBrand(e.target.value)
                                            },
                                        })} type="text"
                                    />
                                    {errors.referral_link && <Form.Text
                                        className="text-muted validationText hasError">{errors.referral_link.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Referral Link Image</Form.Label>
                                    <input
                                        {...register('referral_link_image')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        id='referral_link_image'

                                    />
                                    {errors.referral_link_image && <Form.Text
                                        className="text-muted validationText hasError">{errors.referral_link_image.message}</Form.Text>}
                                    {isUpload ?
                                        <img src={image} className={"img-table img-thumbnail"} crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                </Form.Group>
                            </Col>

                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="equipmentdescription">
                                    <Form.Label>Equipment Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} {...register('description')} />
                                </Form.Group>
                            </Col>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="comments">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control as="textarea" rows={3} {...register('note')} />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Update Equipment"} />}
                            </Col>
                        </Row>

                    </Form>

                </Col>
            </Row>
            <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} />}
                size={"xl"}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }} />
        </div>
    )
}

export default EditEquipment
