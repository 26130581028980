import { TrashIcon } from "../../components/SvgIcons"
import {cross_origin} from "../../utils/constants";

function VideoContainer({ width, height, bodyTypeVideos, removeVideoFunc }) {
    return (
        <div>
            {bodyTypeVideos?.length > 0 &&
                bodyTypeVideos.map((item, key) => (
                <div className={"vidContainer"}>
                    <video
                    className="VideoInput_video"
                    controls
                    src={item.video}
                    crossOrigin={cross_origin ? "anonymous" : null}
                    />
                    <span
                    className={"vidDeleteBtn"}
                    onClick={() => removeVideoFunc(key)}
                    >
                    <TrashIcon />
                    </span>
                </div>
                ))
            }
        </div>
    );
}

export default VideoContainer