import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import {NavLink, useHistory} from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import {constants, cross_origin, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import S3Browser from "../../components/S3Browser";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { CreateAptVideos, DeleteAptVideos, GetAptVideos, UpdateAptVideos } from '../../services/apt-videos.service'


function AptVideos() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');

    const [fileName, setFileName] = useState(null);
    const [formData, setFormData] = useState({});

    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [selectedData, setSelectedData] = useState(null)

    const { register, watch, reset, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const selectedMedia = (fileName, file) => {
        clearErrors(fileName);
        setFormData({ ...formData, ...{ [fileName]: file } })
        setModalShow(false)
    }

    const onDelete = (rowData) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this video entry?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeleteAptVideos(rowData?.id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getAptVideos();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const contactActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => {
                    setShowViewModal(true)
                    setSelectedData(data)
                }}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => {
                    setShowEditModal(true)
                    setSelectedData(data)
                }}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            sortable: true,
            name: 'id',
            maxWidth: '33%',
            selector: rowData => rowData.id,
        },
        {
            id: 'video',
            name: 'video',
            maxWidth: '33%',
            selector: rowData => (
                <video crossOrigin={cross_origin ? "anonymous" : null} style={{ width: '150px', height: '100px' }} className={"img-table img-thumbnail mt-2"}>
                    <source src={rowData?.video} type="video/mp4" />
                </video>
            ),
        },
        {
            maxWidth: '33%',
            field: 'action',
            name: 'Action',
            cell: rowData => contactActions(rowData),
        },
    ];

    const ViewModal = (props) => {
        return (
            <div className={"ViewModalContact"}>
                <video crossOrigin={cross_origin ? "anonymous" : null} style={{ width: '100%', height: '210px' }} className={"img-table img-thumbnail mt-2"} controls>
                    <source src={selectedData?.video} type="video/mp4" />
                </video>
            </div>
        )
    }

    const onSubmit = async data => {
        if (!formData.video) {
            setError('video', { message: 'Video is required' });
            return false;
        }
        data.video = formData.video
        setLoader(true)
        await CreateAptVideos(data).then(async (data) => {
            setShowAddModalFunc(false)
            setLoader(false)
            getAptVideos()
            if (data.status) {
                toast.success(data.message);
                setFormData({})
                reset()
                history.push('/apt-videos')
            } else {
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    }

    const EditModal = (props) => {
        const { register, watch, reset, handleSubmit, setValue, setError, clearErrors, formState: { errors }
        } = useForm({
            mode: "onBlur",
        });
        const [video, setVideo] = useState(null)

        const onSubmit = async data => {
            console.log(selectedData?.video,"video")
            if (!selectedData?.video) {
                setError('video', { message: 'Video is required' });
                return false;
            }else{
                clearErrors('video')
            }
            if(formData?.video){
                data.video = formData.video
            }else{
                data.video = selectedData?.video
            }

            setLoader(true)
            await UpdateAptVideos(selectedData?.id, data).then(async (data) => {
                setShowEditModal(false)
                setLoader(false)
                setVideo(null)
                if (data.status) {
                    reset()
                    setTimeout(() => {
                        getAptVideos()
                    }, 1000)
                    toast.success(data.message);
                    history.push('/apt-videos')
                } else {
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        }

        useEffect(() => {
            setVideo(selectedData?.video)
        }, [])

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="full_name">
                        <Form.Label className={"w-100 d-block"}>ED Video</Form.Label>
                        <button type='button'
                            className='btn btn-user-theme'
                            onClick={() => {
                                setFileName("video")
                                setModalShow(true)
                            }}
                        >
                            Upload ED Video
                        </button>
                        {errors.video && <Form.Text
                            className="validationText hasError">{errors.video.message}</Form.Text>}
                        {formData.video ?
                            <video crossOrigin={cross_origin ? "anonymous" : null} style={{ width: '100%', height: '210px', border: '1px solid rgba(2,2,2,0.2)', borderRadius: '5px' }} className={"img-table img-thumbnail mt-2"} controls>
                                <source src={formData?.video} type="video/mp4" />
                            </video>
                            :
                            <video crossOrigin={cross_origin ? "anonymous" : null} style={{ width: '100%', height: '210px', border: '1px solid rgba(2,2,2,0.2)', borderRadius: '5px' }} className={"img-table img-thumbnail mt-2"} controls>
                                <source src={video} type="video/mp4" />
                            </video>
                        }
                    </Form.Group>
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"Update ED Video"} />}
                </Form>
            </div>
        )
    }

    const AddModal = () => {
        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="full_name">
                        <Form.Label className={"w-100 d-block"}>ED Video</Form.Label>
                        <button type='button'
                            className='btn btn-user-theme'
                            onClick={(e) => {
                                setFileName("video")
                                setModalShow(true)
                            }}
                        >
                            Upload ED Video
                        </button>
                        {errors.video && <Form.Text
                            className=" validationText hasError">{errors.video.message}</Form.Text>}
                        {formData.video &&
                            <video crossOrigin={cross_origin ? "anonymous" : null} style={{ width: '100%', height: '210px', border: '1px solid rgba(2,2,2,0.2)', borderRadius: '5px' }} className={"img-table img-thumbnail mt-2"} controls>
                                <source src={formData?.video} type="video/mp4" />
                            </video>
                        }
                    </Form.Group>
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"Add ED Video"} />}
                </Form>
            </div>
        )
    }

    const getAptVideos = async () => {
        try {
            let params = {
                page,
                perPage,
                keyword,
                order: 'asc',
                ["order-column"]: "id",
                is_paginate: 0,
            }

            setLoading(true)
            const res = await GetAptVideos(params)
            const data = res?.data?.map((val) => ({
                id: val?.id,
                video: val?.video
            }))
            setRows(data)
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
            toast.error(e.response.data.message)
        }
    }

    const setShowAddModalFunc = (data) => {
        clearErrors('video')
        setFormData({})
        setShowAddModal(data)
    }

    useEffect(() => {
        setRows([])
        getAptVideos()
    }, [page, perPage, keyword])

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">ED Videos</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        {/*<InputGroup className="mb-3 search-group">*/}
                        {/*    <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>*/}
                        {/*    <FormControl*/}
                        {/*        placeholder="Search"*/}
                        {/*        aria-label="Search"*/}
                        {/*        aria-describedby="Search"*/}
                        {/*        maxLength={"255"}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</InputGroup>*/}
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={`btn mb-3 btn-green-theme h40 w-100`} onClick={() => setShowAddModalFunc(true)}>
                                    <AddIconOutline /> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />

                <ThemeModal title={"Add ED Video"} show={showAddModal} onHide={() => setShowAddModalFunc(false)} size={"md"} content={<AddModal />} />
                <ThemeModal title={"ED Video"} show={showViewModal} onHide={() => setShowViewModal(false)} size={"md"} content={<ViewModal />} />
                <ThemeModal title={"Edit ED Video"} show={showEditModal} onHide={() => setShowEditModal(false)} size={"md"} content={<EditModal />} />
                <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} />}
                    size={"xl"}
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false)
                    }} />
            </div>
        </div>
    )
}

export default AptVideos
