import {constants, apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';
import {GetAuthUser, GetUserRole} from "../services/auth/auth.service"

const user = GetAuthUser();
let role = GetUserRole();

export const GetProducts = async (keyword = '', category = '', subcategory = '', page = '', perPage= '', getUser, getRole) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if (keyword) {
        params['keyword'] = keyword;
    }

    if (category) {
        params['category_id'] = category;
    }

    if (subcategory) {
        params['sub_category_id'] = subcategory;
    }

    if (getRole == constants.ROLES.ROLE_VENDOR) {
        params['store_id'] = getUser.store.id;
    }

    const response = await GET(apiUrl.products, params);

    return response;
}

export const GetProductsById = async (id) => {
    let response = '';
    if (role == constants.ROLES.ROLE_VENDOR) {
        response = await GET(apiUrl.products + '/' + id, {vendor_id: user.id});
    } else {
        response = await GET(apiUrl.products + '/' + id);
    }
    return response;
}


export const CreateProduct = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await POST(apiUrl.products, data, config);
    return response;
}

export const UpdateProduct = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.products, id, data, config);
    return response;
}

export const DeleteProduct = async (id) => {
    const response = await DELETE(apiUrl.products, id);
    return response;
}

