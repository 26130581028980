/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, FilterIcon, MenuDots, SearchIcon } from "../../components/SvgIcons"
import MaterialTable from "material-table"
import { DeleteProduct, GetProducts } from "../../services/products.service";
import { toast } from "react-toastify";
import {constants, cross_origin, customStyles, GENDERS, IS_VERIFIED, STATUS} from "../../utils/constants";
import { GetCategories } from "../../services/categories.service";
import { GetSubCategories } from "../../services/sub_categories.service";
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { GetAuthUser, GetUserRole } from "../../services/auth/auth.service";
import { DeleteEquipment, GetEquipments } from "../../services/equipments.service";
import imgTemp from "../../assets/images/calf-stretcher.jpg"

function EquipmentList() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getEquipmentsFunction();
    }, [keyword])

    // useEffect(async () => {
    //     await getEquipmentsFunction();
    // }, [])

    const getEquipmentsFunction = async () => {
        setLoading(true)
        await GetEquipments(keyword, page, perPage, 0).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            title: dt?.title,
                            image: dt?.image,
                            weight_kg: dt?.weight_kg,
                            weight_lb: dt?.weight_lb,
                            referral_link: dt?.referral_link,
                            description: dt?.description,
                            note: dt?.note,
                            status: STATUS[dt?.status],
                        });
                    })
                    setRows(rowData);
                    setLoading(false);

                }
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }


    const onEdit = (id) => {
        history.push('/edit-equipment/' + id);

    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteEquipment(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getEquipmentsFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const equipmentActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.title.toLowerCase();
        const b = rowB.title.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'image',
            name: 'Equipment',
            maxWidth: '60px',
            mminWidth: '60px',
            selector: rowData => rowData.oid,
            format: rowData => <img src={rowData.image ? rowData.image : constants.IMAGENOTFOUND}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.IMAGENOTFOUND;
                }}
                className={"img-table img-thumbnail"} alt={"Image"} crossOrigin={cross_origin ? "anonymous" : null} />
        },
        {
            id: 'title',
            name: 'Equipment Title',
            maxWidth: '130px',
            minWidth: '130px',
            sortable: true,
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.title,
        },
        {
            id: 'weight',
            name: 'weight (KG)',
            minWidth: '150px',
            maxWidth: '150px',
            center: true,
            sortable: true,
            selector: rowData => rowData.weight_kg,
        },
        {
            id: 'weightlbs',
            name: 'weight (Lbs.)',
            minWidth: '150px',
            maxWidth: '150px',
            center: true,
            sortable: true,
            selector: rowData => rowData.weight_lb,
        },
        {
            id: 'description',
            name: 'Description',
            center: true,
            maxWidth: '180px',
            minWidth: '180px',
            // sortable: true,
            selector: rowData => rowData.description,
        },
        {
            id: 'note',
            name: 'Comments',
            maxWidth: '180px',
            minWidth: '180px',
            center: true,
            // sortable: true,
            selector: rowData => rowData.note,
        },
        {
            id: 'referalLink',
            maxWidth: '180px',
            minWidth: '180px',
            name: 'Referal Link',
            cell: rowData => <a rel="noreferrer" href={rowData.referral_link} target={'_blank'}>{rowData.referral_link}</a>,
        },
        {
            field: 'status',
            name: 'Status',
            maxWidth: '80px',
            minWidth: '80px',
            center: true,
            sortable: true,
            cell: rowData => rowData.status,

        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => equipmentActions(rowData.id),
        },
    ];

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Equipment</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/add-new-equipment"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default EquipmentList
