import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { uploadToS3 } from "../../utils/helpers";
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { CreateTestimonials } from "../../services/testimonials.service";
import {cross_origin} from "../../utils/constants";


function AddNewTestimonials(props) {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [isUpload, setIsUpload] = useState(false)
    const [image, setImage] = useState('')
    const [file, setFIle] = useState(null)
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {
        setLoader(true)
        if (!file) {
            setLoader(false)
            setError('image', { message: 'Image is required' });
            return false;
        }
        let s3Data = await uploadToS3(file.type, file.name, file, "testimonials")
        if (!s3Data) {
            setLoader(false)
            toast.error("Uploading failed");
            return false;
        }
        data.image = s3Data

        await CreateTestimonials(data).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/testimonials')
            } else {
                setLoader(false)
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    const uploadFile = async (fileInput) => {
        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFIle(fileInput[0])
        setIsUpload(true)
        /*let uploadImage = await UploadImageToS3(fileInput[0]);
        if (uploadImage) {
            if (!uploadImage.success) {
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            toast.error('Can\'t Upload Image');
            return false;
        }*/
    }

    return (
        <div className="AddNewTestimonials">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add New Testimonials</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Image*</Form.Label>
                                    <input
                                        {...register('image')}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        // className='btn btn-user-theme'
                                        id='image'

                                    />
                                    {errors.image && <Form.Text
                                        className="text-muted validationText hasError">{errors.image.message}</Form.Text>}
                                    {isUpload ?
                                        <img src={image} className={"img-table img-thumbnail"}
                                            crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Name"

                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="designation">
                                    <Form.Label>Designation*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Designation"

                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Designation is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 30,
                                                message: "Max length is 30"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Description*</Form.Label>
                                    <Form.Control as="textarea" rows={4} {...register('description', {
                                        required: {
                                            value: "required",
                                            message: "Description is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Min length is 3"
                                        },
                                        maxLength: {
                                            value: 450,
                                            message: "Max length is 450"
                                        },
                                    })} />
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"#EC1246"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Create Testimonials"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddNewTestimonials;