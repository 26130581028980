import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { GetBodyPartById, UpdateBodyPart } from "../../services/body_parts";
import {cross_origin} from "../../utils/constants";

function EditFeelItHere(props) {
    let history = useHistory();
    const body_part_id = props.match.params.id;
    const [data, setData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [row, setRow] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [formData, setFormData] = useState({});

    const { register, watch, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const selectedMedia = (fileName, file) => {
        clearErrors(fileName);
        setFormData({ ...formData, ...{ [fileName]: file } })
        setModalShow(false)
    }

    useEffect(() => {
        GetBodyPartById(body_part_id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setRow(result.data)
                    setFormData(result.data)
                    setValue("name", result.data.name)
                    setValue("reference_number", result.data.reference_number)
                    setValue("male_image", result.data.male_image)
                    setValue("female_image", result.data.female_image)
                    setValue("status", result.data.status)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }, [])

    const onSubmit = async data => {
        if (!formData.male_image) {
            setError('male_image', { message: 'Male image is required' });
            return false;
        }
        if (!formData.female_image) {
            setError('female_image', { message: 'Female image is required' });
            return false;
        }
        data.male_image = formData.male_image
        data.female_image = formData.female_image
        setDisableButton(true)
        await UpdateBodyPart(body_part_id, data).then(async (data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/feel-it-here');
            } else {
                setDisableButton(false)
                toast.error(data.message.replace(/_/g, ' '));
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                return toast.error(error.response.data.message.replace(/_/g, ' '));
            }
        })
    };

    return (
        <div className="AddNewFeel">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit New Feel It Here</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="reference_number">
                                    <Form.Label>Reference No.</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Reference No."
                                        id={"formSignUpvideo"}
                                        {...register('reference_number', {
                                            required: {
                                                value: "required",
                                                message: "Reference No is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.reference_number && <Form.Text
                                        className="text-muted validationText hasError">{errors.reference_number.message}</Form.Text>}

                                </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name of Muscle*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Name of Muscle"
                                        id={"formSignUpvideo"}
                                        {...register('name', {
                                            required: {
                                                value: "required",
                                                message: "Muscle Name is required"
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z0-9_ ]*$/,
                                                message: "This field must contain only letters"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.name && <Form.Text
                                        className="text-muted validationText hasError">{errors.name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="male_image">
                                    <Form.Label className="d-block" id="male_image">Male Image</Form.Label>
                                    {/*<FeelithereImg buttontext="Upload Male Image"/>*/}

                                    <button type='button'
                                        className='btn btn-user-theme'
                                        onClick={() => {
                                            setFileName("male_image")
                                            setModalShow(true)
                                        }}
                                    >
                                        Upload Male Image
                                    </button>
                                    {/*{errors.male_image && <Form.Text*/}
                                    {/*    className="text-muted validationText hasError">{errors.male_image.message}</Form.Text>}*/}
                                    {formData.male_image ?
                                        <img src={formData.male_image} className={"img-table img-thumbnail"} crossOrigin={cross_origin ? "anonymous" : null} /> : ""}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label className="d-block">Female Image</Form.Label>
                                    {/*<FeelithereImg buttontext="Upload Female Image"/>*/}

                                    <button type='button'
                                        className='btn btn-user-theme'
                                        onClick={() => {
                                            setFileName("female_image")
                                            setModalShow(true)
                                        }}
                                    >
                                        Upload Female Image
                                    </button>

                                    {/*{errors.female_image && <Form.Text*/}
                                    {/*    className="text-muted validationText hasError">{errors.female_image.message}</Form.Text>}*/}
                                    {formData.female_image ?
                                        <img src={formData.female_image} className={"img-table img-thumbnail"} crossOrigin={cross_origin ? "anonymous" : null} /> : ""}

                                </Form.Group>
                            </Col>



                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Label className='d-block'>Select Status</Form.Label>
                                <Form.Select className='formselect' aria-label="status" {...register('status', {
                                    required: {
                                        value: "required",
                                        message: "Status is required"
                                    }
                                })}>
                                    <option value="10">Active</option>
                                    <option value="20">Inactive</option>
                                </Form.Select>
                                {errors.status && <Form.Text
                                    className="text-muted validationText hasError">{errors.weight.message}</Form.Text>}
                            </Col>
                        </Row>



                        <Row>
                            <Col className='mt-3' xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                    disabled={disableButton}
                                    value={"Submit"} />
                            </Col>
                        </Row>

                    </Form>

                </Col>
            </Row>

            <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} />}
                size={"xl"}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }} />
        </div>
    )
}

export default EditFeelItHere
