import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { constants, customStyles, STATUS } from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import imgTemp from "../../assets/images/step01.png"
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
    CreateOccupation,
    DeleteOccupation,
    GetOccupationById,
    GetOccupations,
    UpdateOccupation
} from "../../services/occupations.service";

function Occupation() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [occupationId, setId] = useState(0);
    const [formData, setFormData] = useState({});
    const [status, setStatus] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [editloader, setEditLoader] = useState(false);

    const alphanumericPattern = /^[a-zA-Z0-9\s]+$/;
    const { register, reset, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    useEffect(async () => {
        await getOccupationsFunction();
    }, [keyword, status])

    const getOccupationsFunction = async () => {
        setLoading(true)
        await GetOccupations(keyword, page, perPage, 0, status).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt?.id,
                            name: dt?.name,
                            status: STATUS[dt?.status]
                        });
                    })
                    setRows(rowData);
                }
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }
    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this occupation?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            // Delete Product API
            if (willShip) {
                await DeleteOccupation(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getOccupationsFunction();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }
    const onEdit = (id) => {
        reset({
            name: '',
        })
        clearErrors('name');
        setShowEditModal(true)
        setId(id)
        GetOccupationById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setFormData(result.data)
                    setValue("name", result.data.name)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }

    const contactActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'occupation',
            name: 'Occupation',
            sortable: true,
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.name,
        },
        {
            field: 'action',
            name: 'Action',
            width: '80px',
            center: true,
            style: {
                paddingRight: '10px',
            },
            cell: rowData => contactActions(rowData.id),
        },
    ];

    const EditModal = (id) => {


        const onEditSubmit = async data => {
             setEditLoader(true)
            console.log( editloader,"before")
             UpdateOccupation(occupationId, {
                name: data.name,
            }).then(async (data) => {
                setEditLoader(false)
                console.log( editloader,"after")
                if (data.status) {
                    toast.success(data.message);
                    setShowEditModal(false)
                    getOccupationsFunction();
                    reset({
                        name: '',
                    })
                } else {
                    setEditLoader(false)
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        };
        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onEditSubmit)}>
                    <Form.Group className={"mb-3"} controlId="bodyType">
                        <Form.Label>Occupation Name*</Form.Label>
                        <Form.Control {...register("name", {
                            required: {
                                value: "required",
                                message: "Occupation Name is required "
                            },
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                maxLength: {
                                    value: 80,
                                    message: "Max length is 80"
                                },
                                pattern: {
                                    value: alphanumericPattern,
                                    message: 'Occupation Name must be alphanumeric only',
                                },
                        }
                        )} type={"text"} placeholder={"Occupation Name"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">{errors.name.message}</Form.Text>}
                    </Form.Group>
                    {console.log(editloader,"component level")}
                    {editloader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"submit"} />}
                </Form>
            </div>
        )
    };

    const AddModal = () => {
        const [loader, setLoader] = useState(false);
        const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
            mode: "onBlur",
        });

        const onSubmit = async data => {
            setLoader(true)
            await CreateOccupation(data).then(async (data) => {
                setLoader(false)
                if (data.status) {
                    setShowAddModal(false)
                    toast.success(data.message);
                    reset({
                        name: '',
                    })
                    getOccupationsFunction()

                } else {
                    toast.error(data.message.replace(/_/g, ' '));
                }
            }).catch((error) => {
                setLoader(false)
                if (error.response.status == 401) {
                    EmptyLocalStorage()
                    history.push('/');
                } else {
                    return toast.error(error.response.data.message.replace(/_/g, ' '));
                }
            })
        };

        return (
            <div className={"ViewModalContact"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className={"mb-3"} controlId="bodyType">
                        <Form.Label>Occupation Name*</Form.Label>
                        <Form.Control {...register("name", {
                            required: {
                                value: "required",
                                message: "Occupation Name is required "
                            },
                                minLength: {
                                    value: 2,
                                    message: "Min length is 2"
                                },
                                maxLength: {
                                    value: 80,
                                    message: "Max length is 80"
                                },
                                pattern: {
                                    value: alphanumericPattern,
                                    message: 'Occupation Name must be alphanumeric only',
                                },
                        }
                        )} type={"text"} placeholder={"Occupation Name"} />
                        {errors.name && <Form.Text
                            className=" validationText hasError">{errors.name.message}</Form.Text>}
                    </Form.Group>
                    {loader ? <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                    </div> :
                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                            value={"Add New Occupation"} />}
                </Form>
            </div>
        )
    };

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Occupation</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setShowAddModal(true)}>
                                    <AddIconOutline /> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#EC1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />

                <ThemeModal title={"Add New Occupation"} show={showAddModal} onHide={() => setShowAddModal(false)} size={"md"} content={<AddModal />} />
                <ThemeModal title={"Edit Occupation"} show={showEditModal} onHide={() => setShowEditModal(false)} size={"md"} content={<EditModal />} />
            </div>
        </div>
    )
}

export default Occupation
