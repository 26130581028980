import React, { useState } from 'react';
import { NavLink ,useRouteMatch} from 'react-router-dom';
import Button from "react-bootstrap/Button";

export const Submenu = ({ items ,name ,icon}) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const match = useRouteMatch({
        path: items.map((item) => item.path),
        exact: true,
    });

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <li className={`nav-item dropdown ${isSubMenuOpen ? 'show' : ''}`}>
            <span

                className={`nav-link custom-toggle dropdown-toggle ${match ? 'active' : ''}`}
                onClick={toggleSubMenu}
                role="button"
            >
                {icon}
                {name}
            </span>
            <ul className={`dropdown-menu custom-dropdown ${isSubMenuOpen ? 'show' : ''}`}>
                {items.map((item) => (
                    <li key={item.path}>
                        <NavLink
                            to={item.path}
                            className="dropdown-item"
                            activeClassName="active"
                        >
                            {item.icon}
                            {item.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </li>
    );
};