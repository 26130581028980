import React, {useEffect, useState} from 'react'
import {constants} from "../utils/constants";
import {GetUserRole} from "../services/auth/auth.service"
import {Admin} from "./SideMenu/index"

function SideBar() {

    return (
        <div className="d-flex flex-column flex-shrink-0 p-2  sideBar">
            <Admin />
        </div>
    )
}

export default SideBar
