import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetTests = async (keyword = '', gender = '', page = '', perPage = '', is_paginate = 1, orderByValue, orderByColumn) => {
    let params = {};

    if (keyword || gender) {
        params['keyword'] = keyword;
        params['gender'] = gender;
    }

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    params['is_paginate'] = is_paginate;

    if (orderByValue) {
        params['order'] = orderByValue;
    }

    if (orderByColumn) {
        params['order-column'] = orderByColumn;
    }
    const response = await GET(apiUrl.tests, params);
    return response;
}

export const CreateTests = async (data) => {
    const response = await POST(apiUrl.tests, data);
    return response;
}

export const DeleteTests = async (id) => {
    const response = await DELETE(apiUrl.tests, id);
    return response;
}

export const GetTestsById = async (id) => {
    const response = await GET(apiUrl.tests + '/' + id);
    return response;
}

export const UpdateTests = async (id, data) => {
    const response = await PUT(apiUrl.tests, id, data);
    return response;
}