import React, { useEffect, useState } from "react";
import '../../assets/css/imprint-styles.css'
import { TrashIcon } from "../../components/SvgIcons";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import {cross_origin} from "../../utils/constants";

function VideoInput({ width, height, videoUrl, preVideoUrl, resetImageViewer, onCloseRemoveData, onCloseDataIndex, levelKey }) {
  // const { width, height } = props;

  const inputRef = React.useRef();
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [source, setSource] = React.useState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
    videoUrl(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  useEffect(() => {
    clearInput()
  }, [])

  useEffect(() => {
    if (resetImageViewer === true) {
      clearInput()
    }
    if (preVideoUrl !== undefined && preVideoUrl !== '' && preVideoUrl !== null) {
      videoUrl(preVideoUrl)
      setSource(preVideoUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetImageViewer, preVideoUrl, levelKey])

  const clearInput = () => {
    if (source !== undefined && source !== '' && source !== null) {
      onCloseRemoveData(onCloseDataIndex)
    }
    setSource(null);
    videoUrl(null)
  }

  const selectedMedia = (fileName, file) => {
    setFormData({ ...formData, ...{ [fileName]: file } })
    setSource(file)
    videoUrl(file)
    setModalShow(false)
  }

  return (
    <div className="VideoInput">
      {/*<input*/}
      {/*  ref={inputRef}*/}
      {/*  className="VideoInput_input mb-2"*/}
      {/*  type="file"*/}
      {/*  onChange={handleFileChange}*/}
      {/*  accept=".mov,.mp4"*/}
      {/*/>*/}
      <button type='button'
        className='btn btn-user-theme mb-2'
        onClick={() => {
          setFileName("image")
          setModalShow(true)
        }}
      >
        Upload Video
      </button>

      {source && (
        <div className={"vidContainer"}>
          <video
            className="VideoInput_video"
            controls
            src={source}
            crossOrigin={cross_origin ? "anonymous" : null}
          />
          <span className={"vidDeleteBtn"} onClick={() => clearInput()}>
            <TrashIcon />
          </span>
        </div>
      )}
      <ThemeModal title={"S3 Browser"} content={<S3Browser fileName={fileName} selectedMedia={selectedMedia} fileType={'videos'} />}
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }} />
    </div>
  );
}


export default VideoInput