import { apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE } from './api.service.wrapper';

export const GetFaqs = async (params) => {
    const response = await GET(apiUrl.faqs, params);
    return response;
}

export const CreateFaqs = async (data) => {
    const response = await POST(apiUrl.faqs, data);
    return response;
}

export const UpdateFaqs = async (id, data) => {
    const response = await PUT(apiUrl.faqs, id, data);
    return response;
}

export const DeleteFaqs = async (id) => {
    const response = await DELETE(apiUrl.faqs, id);
    return response;
}