import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { constants, customStyles } from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { DeleteExercise, GetExercises, DuplicateExercise } from "../../services/exercises.service";
import { FaCheck, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

function ListExercise(props) {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [rows, setRows] = useState();
    // const [filterCategory, setFilterCategory] = useState('');
    // const [filterSubCategory, setFilterSubCategory] = useState('');
    // const [category, setCategory] = useState('');
    // const [subCategory, setSubCategory] = useState('');
    const [keyword, setKeyword] = useState('');
    const [orderByValue, setOrderByValue] = useState('asc');
    const [orderByColumn, setOrderByColumn] = useState('name');

    useEffect(async () => {
        await getExercises();
    }, [keyword, orderByValue, orderByColumn])

    const getExercises = async () => {
        setLoading(true)
        await GetExercises(keyword, page, perPage, 0, orderByValue, orderByColumn).then((result) => {
            if (result.status) {
                if (result.data) {
                    const rowData = [];
                    setPage(1)
                    setTotalRows(result.data.length);
                    result.data.map((dt) => {
                        rowData.push({
                            id: dt.id,
                            name: dt.name,
                            exerciseType: dt.exerciseTypesCSV ? dt.exerciseTypesCSV : '-',
                            bodytype: dt.exerciseBodyTypesCSV ? dt.exerciseBodyTypesCSV : '-',
                            muscle: dt.exerciseMusclesCSV ? dt.exerciseMusclesCSV : '-',
                            category: dt.exerciseCategoriesCSV ? dt.exerciseCategoriesCSV : '-',
                            equipments: dt.exerciseEquipmentUsed ? dt.exerciseEquipmentUsed : '-',
                            isComplete: dt.is_complete ? dt.is_complete == 1 ? 'Complete' : 'Incomplete' : 'Incomplete',
                            // isDraft: dt.is_draft?<FaCheck/>:<FaTimes/>,
                        });
                    })
                    setRows(rowData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                // toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            // toast.error(error.response.data.message.replace(/_/g, ' '));
        })
    }

    const onEdit = (data) => {
        // console.log(data)
        history.push('/edit-exercise/' + data.id, JSON.stringify({
            data: data
        }));
    }

    const onView = (id) => {
        history.push('/manage-program/');
    }

    const onDelete = (data) => {
        // console.log(data.id, "id")
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Exercise?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteExercise(data.id).then((result) => {

                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getExercises();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, ' '));
                })
            }
        });
    }

    const onDuplicate = (exercise) => {
        swal({
            title: 'Are you sure?',
            text: 'You want to duplicate this exercise?',
            icon: 'warning',
            content: {
              element: 'input',
              attributes: {
                placeholder: 'Enter exercise name',
                className: 'form-control',
                required: true,
              },
            },
            buttons: {
                close: {
                  text: 'Cancel',
                  value: {button:'cancel'},
                },
                confirm: {
                  text: 'Confirm'
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false
        }).then(async (willShip) => {
            if (willShip?.button !== 'cancel') {
                if(willShip){
                    const data = {
                        name: willShip
                    }
                    await DuplicateExercise(exercise.id, data).then((result) => {
                        if (result.status) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getExercises()
                        } else {
                            toast.error(result.message);
                        }
                    }).catch((error) => {
                        toast.error(error.response.data.message.replace(/_/g, ' '));
                    })
                }
                else{
                    swal('Warning', 'Exercise name is required!', 'warning');
                }
            }
            
        });
    }

    const redirectToSummary = (data) => {
        let id = data.id;
        history.push({
            pathname: '/edit-exercise-steps/' + id,
            state: {
                exercise: data,
                redirectToSummary: true
            },
        });
    }

    const exerciseActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => redirectToSummary(data)}>Summary</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDuplicate(data)}>Duplicate</Dropdown.Item>
            </DropdownButton>
        )
    }

    const caseInsensitiveNameSort = (rowA, rowB) => {
        const a = rowA.name.toLowerCase();
        const b = rowB.name.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveCategorySort = (rowA, rowB) => {

        const a = rowA.category.toLowerCase();
        const b = rowB.category.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveBodyTypeSort = (rowA, rowB) => {
        const a = rowA.bodytype.toLowerCase();
        const b = rowB.bodytype.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveExerciseTypeSort = (rowA, rowB) => {

        const a = rowA.exerciseType.toLowerCase();
        const b = rowB.exerciseType.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveMuscleSort = (rowA, rowB) => {

        const a = rowA.muscle.toLowerCase();
        const b = rowB.muscle.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const caseInsensitiveEquipmentSort = (rowA, rowB) => {

        const a = rowA.equipments.toLowerCase();
        const b = rowB.equipments.toLowerCase();
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const columns = [
        {
            id: 'exerciseName',
            name: 'Exercise Name',
            sortable: true,
            sortField: 'name',
            sortFunction: caseInsensitiveNameSort,
            selector: rowData => rowData.name,
        },
        {
            id: 'exerciseType',
            name: 'Exercise Type',
            sortable: true,
            sortFunction: caseInsensitiveExerciseTypeSort,
            center: true,
            selector: rowData => rowData.exerciseType,
        },
        {
            id: 'bodytype',
            name: 'Body Type',
            sortable: true,
            sortFunction: caseInsensitiveBodyTypeSort,
            selector: rowData => rowData.bodytype,
        },
        {
            id: 'subcategory',
            name: 'Sub Category',
            sortable: true,
            sortFunction: caseInsensitiveCategorySort,
            selector: rowData => rowData.category,
        },
        {
            id: 'muscle',
            name: 'muscle',
            sortable: true,
            sortFunction: caseInsensitiveMuscleSort,
            selector: rowData => rowData.muscle,
        },
        {
            id: 'equipment',
            name: 'Equipment',
            sortable: true,
            sortFunction: caseInsensitiveEquipmentSort,
            selector: rowData => rowData.equipments,
        },
        {
            id: 'isComplete',
            name: 'Complete',
            sortable: true,
            selector: rowData => rowData.isComplete,
        },
        {
            field: 'action',
            name: 'Action',
            width: '80px',
            center: true,
            cell: rowData => exerciseActions(rowData),
        },
    ];

    const handleSort = async (column, sortDirection) => {
        setOrderByValue(sortDirection)
        setOrderByColumn(column.sortField)
    };

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Exercise Management</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/add-exercise"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline /> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    // paginationServer
                    paginationRowsPerPageOptions={[20, 40, 60, 80, 100, 150, 200, 250, 300]}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#ec1246'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                // onSort={handleSort}
                // sortServer
                />
            </div>
        </div>
    )
}

export default ListExercise